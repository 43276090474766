@import "./Components/Header/scss/header";
@import "./Screens/homepage/scss/features";
@import "./Screens/homepage/scss/homepage";
@import "./Screens/homepage/scss/categories";
@import "./Components/ShowCard/scss/showCard";
@import "./Components/Footer/scss/footer";
@import "./Screens/ShowDetailsScreen/scss/detailsScreen";
@import "./Screens/Accounts/Login/scss/login";
@import "./Screens/Accounts/Register/scss/register";
@import "./Components/CommonPage/scss/commonPage";
@import "./Screens/PaymentStatus/PaymentFailed/scss/paymentFailed";
@import "./Screens/PaymentStatus/PaymentSuccess/scss/paymentSuccess";
@import "./Screens/Accounts/ForgotPassword/scss/forgotPassword";
@import "./Screens/Accounts/ForgotPassword/scss/ResetPassword";
@import "./Components/Header/scss/headerAccounts";
@import "./Components/Modals/scss/OtpModal";
@import "./Components/Modals/scss/continueWatching";
@import "./Components/Modals/scss/currentPasswordModal";
@import "./Components/Modals/scss/watchWithoutAds";
@import "./Components/Modals/scss/logoutAllModal";
@import "./Components/Modals/scss/cancelSubscriptionModal";
@import "./Screens/LoginRequest/scss/loginRequest";
@import "./Components/Modals/scss/playNextEpisode";
@import "./Screens/Accounts/AccountSettings/scss/accountSetting";
@import "./Screens/Accounts/AccountSettings/scss/BillingDetails";
@import "./Screens/Accounts/AccountSettings/scss/streamingActivity";
@import "./Screens/homepage/scss/live";
@import "./Components/ShowsRow/scss/showsRow";
@import "./Screens/Subscription/scss/subscription";
@import "./Screens/Subscription/scss/SubscriptionTwo";
@import "./Screens/Subscription/scss/payment";
@import "./Screens/Search/scss/search";
@import "./Screens/MyList/scss/myList";
@import "./Screens/PrivacyPolicy/scss/privacyPolicy";
@import "./Screens/TermsAndConditions/scss/termsAndConditions";
@import "./Screens/ContactPages/scss/contactUs";
@import "./Screens/ContactPages/scss/contactSupport";
@import "./Screens/About/scss/about";
@import "./Components/Loading/scss/loading";
@import "./Components/NewsCard/NewsCard";
@import "./Screens/NewsSection/scss/LatestNews";
@import "./Screens/NewsSection/scss/newsDetails";
@import "./Screens/Blogs/scss/blogs";
@import "./Screens/LandingScreen/scss/landingScreen";
@import "./Screens/NotFound/scss/nowFound";
@import "./Components/PlayerShowCard/scss/PlayerShowCard";
@import "./Components/CategoriesShowCard/scss/CategoriesShowCard";
@import "./Components/Modals/scss/trailerModal";
@import "./Components/Loading/scss/smallLoading";
@import "./Screens/VideoPlayer/scss/videoPlayer";
@import "./Screens/LinkTvApp/linkTvApp";
@import "./Components/Modals/scss/podcastModal";
@import "./Components/Modals/scss/ratingsAndReview";
@import "./Components/Modals/scss/howItWorks";
@import "./Components/Modals/scss/scheduleDetailsModal";
@import "./Screens/LiveChannels/scss/liveChannels";
@import "./Components/Modals/scss/languageModal";
@import "./Components/OpenApp/scss/openApp";
@import "./Screens/AffliatePage/scss/affliatePage";
@import "./Screens/LivePlayer/scss/livePlayer";
@import "./Components/Modals/scss/loginModal";
@import "./Components/MagazineCard/scss/magazineCard";
@import "./Routes/scss/layoutsRoutes";
@import "./Screens/EventDetails/scss/eventDetailsScreen";
@import "./Components/Modals/scss/noScheduleAvailable";
@import "./Screens/VideoPlayer/Components/scss/autoplay";
@import './Components/Modals/scss/socialLoginModal';
@import "./Screens/About/scss/_jwmAbout";
@import "./Components/Modals/scss/notificationModal";
@import './Screens/Shorts/scss/shorts';
@import './Screens/Shorts/scss/shortsCard';
@import './Screens/VideoPlayer/Components/scss/commentReply';
@import './Screens/Shorts/ShortsComments/scss/commentRepliesShorts';
:root{
  --grey-color:#9699a3;
  --text-hover:#000;
  --bg-color:#ffffff;
  --secondary-bg-color:#eaeaea;
  --modal-bg-color: #141414;
  --overlay:rgba(16,20,31,.64);
  --white-color:#fff;
  --border-color:#eaeaea;
  --text-color:rgb(102, 102, 102);
  --success-color:#4BB543;
  --error-color:#E72A31;
  --yellow-color:#f8d406;
  --black-color:#000;
  --button-hover:rgb(0, 20, 137);
  --button-color:rgb(0, 20, 137);
  --heading-color:#323232;
  --modal-bg-color:#ffffff;
  --sub-bg-color:#26262d;
  --sub-heading-color:rgb(253, 201, 59);
  --default-bg-color:#10141f;
  --default-border-color:rgba(150,153,163,.16);
  --default-text-hover-color:rgb(193, 191, 191);
  --disabled-btn-color:rgb(112, 125, 201);
  --gradient-left-color:#01c599;
  --gradient-right-color:#0388cc;
}
.wrapper{
    width: 100%;
    margin: 0 auto;
    @media all and (max-width:980px){
      width: 100%;
    }
}
.swiper-button-next.swiper-button-disabled {
  display: none !important;
}
.swiper-button-prev.swiper-button-disabled {
 opacity: 1 !important;
}
.swiper-button-next{
top: 22px !important;
  &::after{
      font-size: 25px !important;
      color: var(--white-color);
      
  }
}
.swiper-button-prev{
top: 22px !important;

  &::after{
    font-size: 25px !important;
    color: var(--white-color);
    position: absolute;
    left: 118px;
    
}
}

span.error{
	font-size: 14px;
    color: #E72A31;
    display: block;
    margin-top: 10px;
	font-weight: normal !important;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
