@charset "UTF-8";
div.header-container {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: var(--bg-color);
  height: 50px;
  display: flex;
  align-items: center;
  transition: 0.4s ease-out;
  border-bottom: 1px solid var(--border-color);
}
div.header-container div.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99%;
}
div.header-container div.wrapper div.leftContainer {
  display: flex;
  align-items: center;
  width: 36%;
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.leftContainer {
    width: 70%;
  }
}
@media all and (max-width: 980px) {
  div.header-container div.wrapper div.leftContainer {
    width: 49%;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.leftContainer {
    width: 26%;
  }
}
div.header-container div.wrapper div.leftContainer div.inputContainer {
  padding: 12px 15px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  transition: 0.2s ease-in-out;
  margin-inline-end: 30px;
  cursor: pointer;
  border-inline-start: 1px solid var(--border-color);
  margin-inline-start: 10px;
  position: relative;
}
div.header-container div.wrapper div.leftContainer div.inputContainer.showInput {
  background-color: #f6f6f6;
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.leftContainer div.inputContainer.showInput {
    width: 70%;
  }
  div.header-container div.wrapper div.leftContainer div.inputContainer.showInput input {
    display: block;
  }
}
@media all and (max-width: 980px) {
  div.header-container div.wrapper div.leftContainer div.inputContainer {
    width: 83%;
    justify-content: center;
    padding: 10px 8px;
  }
  div.header-container div.wrapper div.leftContainer div.inputContainer.showInput {
    width: 57%;
    justify-content: space-between;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.leftContainer div.inputContainer {
    display: none;
  }
}
div.header-container div.wrapper div.leftContainer div.inputContainer:hover {
  border-color: #c5c5c8;
}
div.header-container div.wrapper div.leftContainer div.inputContainer svg {
  width: 18px !important;
  height: 18px !important;
  color: var(--grey-color) !important;
  margin-inline-end: 6px;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer {
  position: absolute;
  top: 47px;
  z-index: 9;
  background-color: var(--bg-color);
  padding: 15px;
  width: 100%;
  inset-inline-start: 0;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer {
    display: none;
  }
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer h6.heading {
  font-size: 12px;
  color: var(--grey-color);
  margin-bottom: 10px;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer h6.heading span.count {
  font-weight: normal;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection {
  margin-bottom: 20px;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection h6.heading {
  font-size: 12px;
  color: var(--text-color);
  margin-bottom: 10px;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection h6.heading span.count {
  font-weight: normal;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list {
  display: flex;
  margin-bottom: 3px;
  cursor: pointer;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list div.listContainer {
  display: flex;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list div.listContainer div.left {
  margin-inline-end: 10px;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list div.listContainer div.left div.imageContainer {
  width: 50px;
  height: 50px;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list div.listContainer div.left div.imageContainer img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list div.listContainer div.right h1.title {
  font-size: 14px;
  color: var(--text-color);
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer ul.lists div.categorySection li.list div.listContainer div.right span.director {
  font-size: 12px;
  color: var(--grey-color);
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer div.seeAll {
  text-align: center;
  border-top: 1px solid #eaeaea;
  padding-top: 10px;
  margin-top: 20px;
  cursor: pointer;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchSuggestionsContainer div.seeAll button {
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
  font-weight: 700;
  cursor: pointer;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.closeIcon {
  position: absolute;
  inset-inline-end: 5px;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.closeIcon svg {
  width: 16px !important;
  height: 16px !important;
}
div.header-container div.wrapper div.leftContainer div.inputContainer div.searchLoading {
  position: absolute;
  inset-inline-end: 30px;
  bottom: 30px;
}
div.header-container div.wrapper div.leftContainer div.inputContainer input {
  border: none;
  outline: none;
  width: 85%;
  margin-inline-end: auto;
  background-color: transparent;
  font-size: 13px;
  color: var(--grey-color);
  caret-color: var(--grey-color);
}
div.header-container div.wrapper div.leftContainer div.inputContainer input::-moz-placeholder {
  color: #cac9c8;
  font-size: 13px;
  -moz-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
div.header-container div.wrapper div.leftContainer div.inputContainer input::placeholder {
  color: #cac9c8;
  font-size: 13px;
  transition: 0.3s ease-in-out;
}
div.header-container div.wrapper div.leftContainer div.logoContainer h1 {
  width: 120px;
  max-height: 28px;
  display: flex;
  align-items: center;
}
@media all and (max-width: 480px) {
  div.header-container div.wrapper div.leftContainer div.logoContainer h1 {
    width: 91px;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.leftContainer div.logoContainer h1 {
    width: 80px;
  }
}
div.header-container div.wrapper div.leftContainer div.logoContainer h1 img {
  display: block;
  width: 100%;
  height: 100%;
  aspect-ratio: 3;
  -o-object-fit: contain;
     object-fit: contain;
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.leftContainer ul.hide.hide {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 50%;
  transition: 0.2s ease-in-out;
  position: relative;
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.rightContainer {
    width: 40%;
  }
}
@media all and (max-width: 1200px) {
  div.header-container div.wrapper div.rightContainer {
    width: 40%;
  }
}
@media all and (max-width: 768px) {
  div.header-container div.wrapper div.rightContainer {
    width: 80%;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer {
    width: 80%;
  }
}
div.header-container div.wrapper div.rightContainer div.languageDropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  color: white;
  padding: 13px 0;
  padding-inline-end: 10px;
}
@media (max-width: 768px) {
  div.header-container div.wrapper div.rightContainer div.languageDropdown {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer div.languageDropdown:hover {
  color: gray;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.language {
  font-size: 14px;
  font-weight: bold;
  transition: color 0.3s ease, font-weight 0.3s ease;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions {
  position: absolute;
  top: 100%;
  inset-inline-start: 0;
  min-width: 210px;
  background-color: var(--bg-color);
  padding-top: 15px;
  padding-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions::before {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList {
  display: flex;
  flex-direction: column;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption {
  font-size: 16px;
  padding: 8px 12px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true] {
  color: var(--gradient-left-color);
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true]:after {
  content: "✔";
  margin-inline-start: auto;
}
div.header-container div.wrapper div.rightContainer div.languageDropdown div.languageOptions div.optionsList div.languageOption:hover {
  color: var(--gradient-left-color);
}
div.header-container div.wrapper div.rightContainer div.language {
  cursor: pointer;
}
@media all and (max-width: 480px) {
  div.header-container div.wrapper div.rightContainer div.language {
    display: none;
  }
}
div.header-container div.wrapper div.rightContainer div.language i {
  font-size: 14px;
  font-weight: 900;
  transition: 0.3s ease-in-out;
  color: var(--text-color);
  margin-inline-end: 6px;
}
@media (max-width: 480px) {
  div.header-container div.wrapper div.rightContainer div.language i {
    font-size: 12px;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer div.language i {
    font-size: 10px;
  }
}
div.header-container div.wrapper div.rightContainer div.language span {
  font-size: 14px;
  font-weight: 900;
  transition: 0.3s ease-in-out;
  color: var(--text-color);
  text-transform: uppercase;
}
@media (max-width: 480px) {
  div.header-container div.wrapper div.rightContainer div.language span {
    font-size: 12px;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer div.language span {
    font-size: 10px;
  }
}
div.header-container div.wrapper div.rightContainer div.liveButtonContainer {
  position: relative;
  align-items: center;
}
div.header-container div.wrapper div.rightContainer div.liveButtonContainer ul.liveButton {
  background-color: #f88d0c;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  text-transform: uppercase;
  margin-inline-end: 20px;
  letter-spacing: 1px;
  animation: blink 0.5s infinite;
  cursor: pointer;
  text-align: center;
  display: block;
  margin: 0 auto;
}
div.header-container div.wrapper div.rightContainer div.liveButtonContainer ul.liveButton li {
  color: white !important;
  font-size: 14px;
  font-weight: 900;
}
@media (max-width: 480px) {
  div.header-container div.wrapper div.rightContainer div.liveButtonContainer ul.liveButton li {
    font-size: 12px;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer div.liveButtonContainer ul.liveButton li {
    font-size: 10px;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
div.header-container div.wrapper div.rightContainer ul.browse {
  border-inline-end: 1px solid var(--border-color);
  padding: 13px 0;
}
div.header-container div.wrapper div.rightContainer ul.browse li {
  cursor: pointer;
  padding: 0 30px;
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer ul.browse li {
    padding: 0 10px;
  }
}
div.header-container div.wrapper div.rightContainer ul.browse li span {
  font-size: 14px;
  font-weight: 900;
  transition: 0.3s ease-in-out;
  color: var(--text-color);
}
@media (max-width: 480px) {
  div.header-container div.wrapper div.rightContainer ul.browse li span {
    font-size: 12px;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer ul.browse li span {
    font-size: 10px;
  }
}
div.header-container div.wrapper div.rightContainer ul.browse li:hover span {
  color: var(--text-hover);
}
div.header-container div.wrapper div.rightContainer ul.browse li.active span {
  color: var(--text-color);
}
div.header-container div.wrapper div.rightContainer ul.browse li.no-active span {
  color: var(--text-color);
}
div.header-container div.wrapper div.rightContainer ul.with-bg {
  border-inline-end: 1px solid var(--grey-color);
}
div.header-container div.wrapper div.rightContainer ul.with-bg li:hover span {
  color: gray;
}
div.header-container div.wrapper div.rightContainer h4 {
  color: var(--text-color);
  font-size: 14px;
  margin-inline-start: 10px;
}
@media (max-width: 480px) {
  div.header-container div.wrapper div.rightContainer h4 {
    font-size: 12px;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer h4 {
    font-size: 10px;
  }
}
div.header-container div.wrapper div.rightContainer div.buttonContainer {
  margin-inline-start: 30px;
}
@media all and (max-width: 480px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer {
    margin-inline-start: 15px;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer {
    margin-inline-start: 7px;
  }
}
div.header-container div.wrapper div.rightContainer div.buttonContainer button.signIn {
  background-color: transparent;
  font-weight: 700;
  font-size: 14px;
  color: var(--text-color);
  cursor: pointer;
}
@media all and (max-width: 480px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer button.signIn {
    font-size: 12px;
  }
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer div.buttonContainer button.signIn {
    font-size: 10px;
  }
}
div.header-container div.wrapper div.rightContainer div.menuIconContainer {
  line-height: 0;
  margin-inline-start: 20px;
}
@media all and (max-width: 360px) {
  div.header-container div.wrapper div.rightContainer div.menuIconContainer {
    margin-inline-start: 5px;
  }
}
div.header-container div.wrapper div.rightContainer div.menuIconContainer svg {
  width: 18px !important;
  height: 18px !important;
  color: var(--text-color);
  cursor: pointer;
}
div.header-container div.wrapper div.rightContainer div.menuListContainer {
  width: 10rem;
  position: absolute;
  inset-inline-end: 0;
  background-color: var(--bg-color);
  top: 60px;
  padding: 10px 18px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
div.header-container div.wrapper div.rightContainer div.menuListContainer::after {
  content: "";
  position: absolute;
  top: -10px;
  /* width: 7px; */
  /* height: 4px; */
  border-color: transparent transparent var(--bg-color);
  border-style: solid;
  border-width: 1px 9px 16px;
  /* right: 2%; */
  /* transform: translateX(-580%); */
  inset-inline-end: 4px;
}
div.header-container div.wrapper div.rightContainer div.menuListContainer ul a {
  cursor: pointer;
}
div.header-container div.wrapper div.rightContainer div.menuListContainer ul li {
  cursor: pointer;
  margin-bottom: 6px;
}
div.header-container div.wrapper div.rightContainer div.menuListContainer ul li span {
  font-size: 14px;
  color: var(--text-color);
  letter-spacing: 0.2px;
}
div.header-container div.wrapper div.rightContainer div.menuListContainer ul li:hover span {
  color: var(--text-hover);
}
div.header-container div.wrapper div.rightContainer div.menuListContainer ul li.bg-set:hover span {
  color: var(--grey-color);
}
div.header-container div.wrapper div.rightContainer div.menuListContainer ul li.bg-not-set:hover span {
  color: var(--text-hover);
}

div.userInfoContainer {
  position: fixed;
  inset-inline-end: 30px;
  width: 20rem;
  top: 15%;
  background-color: rgb(37, 42, 52);
  border-radius: 8px;
  display: none;
  z-index: 9999;
}
div.userInfoContainer.active {
  display: block;
}
@media all and (max-width: 1200px) {
  div.userInfoContainer {
    top: 15%;
  }
}
@media all and (max-width: 640px) {
  div.userInfoContainer {
    width: 84%;
  }
}
div.userInfoContainer div.top {
  padding: 20px 20px 0;
}
div.userInfoContainer div.top ul li {
  border-bottom: 1px solid var(--border-color);
  padding: 15px 0;
  cursor: pointer;
}
div.userInfoContainer div.top ul li:first-child {
  padding-top: 0;
}
div.userInfoContainer div.top ul li:last-child {
  border-bottom: none;
}
div.userInfoContainer div.top ul li span {
  color: var(--white-color);
  font-weight: 600;
  font-size: 15px;
}
div.userInfoContainer div.bottom {
  background: linear-gradient(180deg, #10141f, rgba(16, 20, 31, 0));
  padding: 15px;
}
div.userInfoContainer div.bottom div.signOutContainer {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: var(--border-color);
  border-radius: 8px;
  cursor: pointer;
}
div.userInfoContainer div.bottom div.signOutContainer svg {
  color: var(--white-color);
  width: 22px !important;
  height: 22px !important;
  margin-inline-end: 10px;
}
div.userInfoContainer div.bottom div.signOutContainer span {
  color: var(--white-color);
  font-weight: 600;
  font-size: 15px;
}

@media all and (max-width: 768px) {
  div.overlayOne.active {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--overlay);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.3s ease-in-out;
  }
}

div.overlayOne.active {
  display: flex;
}

div.browseMore {
  display: none;
}
@media all and (max-width: 768px) {
  div.browseMore {
    display: none;
  }
}
div.browseMore div.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s ease-in-out;
}
div.browseMore div.browseMoreContainer {
  animation: fadeIn 0.7s ease-in-out;
  position: fixed;
  top: 15%;
  inset-inline-end: 8%;
  background-color: var(--bg-color);
  display: flex;
  border-radius: 6px;
  z-index: 999;
}
div.browseMore div.browseMoreContainer::after {
  content: "";
  top: -10px;
  /* width: 7px; */
  /* height: 4px; */
  border-color: transparent transparent var(--bg-color);
  border-style: solid;
  border-width: 0px 9px 15px;
  position: absolute;
  inset-inline-end: 40px;
}
@media all and (max-width: 1768px) {
  div.browseMore div.browseMoreContainer::after {
    inset-inline-end: 60px;
  }
}
@media all and (max-width: 1600px) {
  div.browseMore div.browseMoreContainer::after {
    inset-inline-end: 70px;
  }
}
@media all and (max-width: 2500px) {
  div.browseMore div.browseMoreContainer {
    top: 5%;
    inset-inline-end: 6%;
  }
}
@media all and (max-width: 1980px) {
  div.browseMore div.browseMoreContainer {
    top: 6%;
  }
}
@media all and (max-width: 1600px) {
  div.browseMore div.browseMoreContainer {
    top: 7%;
  }
}
@media all and (max-width: 1400px) {
  div.browseMore div.browseMoreContainer {
    top: 10%;
  }
}
div.browseMore div.browseMoreContainer h1.categoryName {
  font-size: 14px;
  margin-bottom: 20px;
  color: var(--grey-color);
}
div.browseMore div.browseMoreContainer ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
div.browseMore div.browseMoreContainer ul li {
  margin-bottom: 8px;
  cursor: pointer;
}
div.browseMore div.browseMoreContainer ul li:hover span {
  color: var(--text-hover);
}
div.browseMore div.browseMoreContainer ul li span {
  font-size: 14px;
  color: #10141f;
  transition: 0.3s ease-in-out;
}
div.browseMore div.browseMoreContainer div.left {
  padding: 30px 25px;
  overflow-y: scroll;
  height: 500px;
}
div.browseMore div.browseMoreContainer div.left::-webkit-scrollbar {
  width: 8px;
}
div.browseMore div.browseMoreContainer div.left::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
div.browseMore div.browseMoreContainer div.left::-webkit-scrollbar-thumb:hover {
  background-color: rgb(125, 128, 128);
}
div.browseMore div.browseMoreContainer div.center {
  width: 40%;
  background-color: #f0f3f5;
  padding: 30px 25px;
}
div.browseMore div.browseMoreContainer div.center div.sections {
  display: flex;
  justify-content: space-between;
}
div.browseMore div.browseMoreContainer div.center div.sections div.leftSection {
  width: 50%;
}
div.browseMore div.browseMoreContainer div.center div.sections div.rightSection {
  width: 50%;
}
div.browseMore div.browseMoreContainer div.right {
  width: 40%;
  padding: 30px 25px;
}

div.browseMore.active {
  display: block;
}
@media all and (max-width: 768px) {
  div.browseMore.active {
    display: none;
  }
}

div.browseMoreResponsive div.searchArea {
  display: none;
  z-index: 99999;
  position: fixed;
  width: 100%;
  background: #fff;
  padding: 15px;
  top: 0;
  inset-inline-start: -50%;
  border-bottom: 1px solid var(--border-color);
  transition: 0.5s ease-in-out;
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.searchArea {
    display: block;
  }
  div.browseMoreResponsive div.searchArea.active {
    inset-inline-start: 0;
  }
  div.browseMoreResponsive div.searchArea div.inputContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    position: relative;
  }
  div.browseMoreResponsive div.searchArea div.inputContainer input {
    display: block;
    outline: none;
    border: none;
    background-color: transparent;
    width: 93%;
    color: var(--text-color);
    font-size: 16px;
  }
  div.browseMoreResponsive div.searchArea div.inputContainer svg {
    margin-inline-end: 10px;
  }
}
div.browseMoreResponsive div.searchArea div.closeIcon {
  position: absolute;
  inset-inline-end: 5px;
  top: 30%;
  display: none;
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.searchArea div.closeIcon {
    display: block;
  }
}
div.browseMoreResponsive div.searchArea div.closeIcon svg {
  width: 16px !important;
  height: 16px !important;
}
div.browseMoreResponsive div.searchArea div.searchLoading {
  position: absolute;
  inset-inline-end: 30px;
  bottom: 30px;
  display: none;
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.searchArea div.searchLoading {
    display: block;
  }
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium {
  position: absolute;
  top: 47px;
  z-index: 99999;
  background-color: var(--white-color);
  padding: 15px;
  width: 100%;
  inset-inline-start: 0;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: none;
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium {
    display: block;
  }
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium h6.heading {
  font-size: 12px;
  color: var(--grey-color);
  margin-bottom: 10px;
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium h6.heading span.count {
  font-weight: normal;
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium ul.lists li.list {
  display: flex;
  margin-bottom: 3px;
  cursor: pointer;
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium ul.lists li.list div.left {
  margin-inline-end: 10px;
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium ul.lists li.list div.left div.imageContainer {
  width: 50px;
  height: 50px;
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium ul.lists li.list div.left div.imageContainer img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium ul.lists li.list div.right h1.title {
  font-size: 14px;
  color: var(--black-color);
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium ul.lists li.list div.right span.director {
  font-size: 12px;
  color: var(--grey-color);
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium div.seeAll {
  text-align: center;
  border-top: 1px solid #eaeaea;
  padding: 10px 0;
  margin-top: 20px;
  cursor: pointer;
  background-color: var(--secondary-bg-color);
}
div.browseMoreResponsive div.searchArea div.searchSuggestionsContainerMedium div.seeAll button {
  background-color: transparent;
  font-size: 14px;
  color: var(--text-color);
  font-weight: 700;
  cursor: pointer;
}
div.browseMoreResponsive div.container {
  position: fixed;
  inset-inline-start: -50%;
  width: 35%;
  background-color: var(--bg-color);
  padding: 70px 30px 35px;
  z-index: 9999;
  top: 0;
  bottom: 0;
  overflow: scroll;
  transition: 0.5s ease-in-out;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.browseMoreResponsive div.container::-webkit-scrollbar {
  display: none;
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.container.active {
    inset-inline-start: 0;
  }
}
@media all and (max-width: 980px) {
  div.browseMoreResponsive div.container {
    width: 45%;
  }
}
@media all and (max-width: 768px) {
  div.browseMoreResponsive div.container {
    width: 100%;
    inset-inline-start: -100%;
  }
}
div.browseMoreResponsive div.container div.language {
  cursor: pointer;
  display: none;
}
@media all and (max-width: 480px) {
  div.browseMoreResponsive div.container div.language {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    align-items: center;
  }
}
div.browseMoreResponsive div.container div.language i {
  font-size: 12px;
  font-weight: 900;
  transition: 0.3s ease-in-out;
  color: var(--text-color);
  margin-inline-end: 6px;
}
div.browseMoreResponsive div.container div.language span {
  font-size: 12px;
  font-weight: 900;
  transition: 0.3s ease-in-out;
  color: var(--text-color);
  text-transform: uppercase;
}
div.browseMoreResponsive div.container div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
div.browseMoreResponsive div.container div.head div.left h1 {
  color: var(--text-hover);
  font-size: 15px;
  font-weight: 700;
}
div.browseMoreResponsive div.container div.head div.right svg {
  color: var(--text-color);
  width: 23px !important;
  height: 23px !important;
}
div.browseMoreResponsive div.container div.top {
  margin-top: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--border-color);
}
div.browseMoreResponsive div.container div.top h1.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--grey-color);
  font-weight: 700;
}
div.browseMoreResponsive div.container div.top ul li {
  margin-bottom: 8px;
}
div.browseMoreResponsive div.container div.top ul li span {
  font-size: 14px;
  color: var(--text-color);
}
div.browseMoreResponsive div.container div.top ul li:hover span {
  color: var(--text-hover);
}
div.browseMoreResponsive div.container div.center {
  padding: 30px 0;
  border-bottom: 1px solid var(--border-color);
}
div.browseMoreResponsive div.container div.center h1.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--grey-color);
  font-weight: 700;
}
div.browseMoreResponsive div.container div.center ul li {
  margin-bottom: 8px;
}
div.browseMoreResponsive div.container div.center ul li span {
  font-size: 14px;
  color: var(--text-color);
}
div.browseMoreResponsive div.container div.bottom {
  padding: 30px 0;
}
div.browseMoreResponsive div.container div.bottom h1.categoryName {
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--grey-color);
  font-weight: 700;
}
div.browseMoreResponsive div.container div.bottom ul li {
  margin-bottom: 8px;
}
div.browseMoreResponsive div.container div.bottom ul li span {
  font-size: 14px;
  color: #fff;
}
div.browseMoreResponsive div.container div.languageDropdown {
  position: relative;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  color: white;
  padding: 15px 0;
}
div.browseMoreResponsive div.container div.languageDropdown:hover {
  color: gray;
}
div.browseMoreResponsive div.container div.languageDropdown h1.language {
  font-size: 16px;
  font-weight: bold;
  transition: color 0.3s ease, font-weight 0.3s ease;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions {
  position: absolute;
  top: 100%;
  inset-inline-start: 0;
  min-width: 210px;
  background-color: var(--bg-color);
  padding-top: 15px;
  padding-bottom: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions::before {
  content: "";
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(281deg, var(--gradient-right-color), var(--gradient-left-color));
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList {
  display: flex;
  flex-direction: column;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption {
  font-size: 16px;
  padding: 8px 12px;
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true] {
  color: var(--gradient-left-color);
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption[data-selected=true]:after {
  content: "✔";
  margin-inline-start: auto;
}
div.browseMoreResponsive div.container div.languageDropdown div.languageOptions div.optionsList div.languageOption:hover {
  color: var(--gradient-left-color);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
div.featured {
  overflow: hidden;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--border-color);
}
@media all and (max-width: 768px) {
  div.featured {
    height: 300px;
  }
}
@media all and (max-width: 640px) {
  div.featured {
    height: 100%;
  }
}
div.featured .swiper {
  height: 100%;
}
div.featured .swiper div.addToWatchList {
  position: absolute;
  top: 30px;
  inset-inline-end: 30px;
  z-index: 9;
  cursor: pointer;
  display: none;
}
div.featured .swiper div.addToWatchList svg {
  width: 25px !important;
  height: 25px !important;
}
div.featured .swiper .swiper-slide:hover div.addToWatchList {
  display: block;
}
div.featured .swiper-pagination-bullet {
  background-color: var(--heading-color) !important;
  opacity: 0.8 !important;
}
div.featured .swiper-pagination-bullet-active {
  background-color: var(--grey-color) !important;
  opacity: 1 !important;
}
div.featured div.bgImageContainer {
  width: 100%;
  display: block;
  height: 100%;
}
div.featured div.bgImageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.featured div.bgImageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.featured div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.featured div.gallerySection {
  position: absolute;
  z-index: 9;
  inset-inline-end: 0;
  bottom: 42px;
  width: 22rem;
}
@media all and (max-width: 1400px) {
  div.featured div.gallerySection {
    width: 18.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.featured div.gallerySection {
    width: 21rem;
  }
}
@media all and (max-width: 980px) {
  div.featured div.gallerySection {
    display: none;
  }
}
div.featured div.gallerySection .swiper-slide {
  width: 108px !important;
}
@media all and (max-width: 1400px) {
  div.featured div.gallerySection .swiper-slide {
    width: 89px !important;
  }
}
@media all and (max-width: 1200px) {
  div.featured div.gallerySection .swiper-slide {
    width: 103px !important;
  }
}
div.featured div.gallerySection .swiper {
  overflow: hidden;
}
div.featured div.gallerySection .swiper-slide-visible {
  opacity: 0.4;
}
div.featured div.gallerySection .swiper-slide-thumb-active {
  opacity: 1;
}
div.featured div.gallerySection div.imageContainer {
  width: 100%;
}
div.featured div.gallerySection div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 6px;
}
div.featured div.contents {
  position: absolute;
  bottom: 30px;
  width: 92%;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
div.featured div.contents span.line {
  height: 1px;
  background-color: var(--border-color);
  width: 30%;
  display: block;
  margin-bottom: 20px;
}
@media all and (max-width: 768px) {
  div.featured div.contents span.line {
    display: none;
  }
}
div.featured div.contents h1.title {
  font-size: 55px;
  color: var(--white-color);
  margin-bottom: 30px;
  animation: slideUp 0.5s ease-in-out;
}
@media all and (max-width: 768px) {
  div.featured div.contents h1.title {
    font-size: 36px;
    margin-bottom: 0;
  }
}
div.featured div.contents div.containers {
  display: flex;
  align-items: center;
}
div.featured div.contents div.containers div.left {
  width: 8%;
  margin-inline-end: 30px;
}
@media all and (max-width: 1200px) {
  div.featured div.contents div.containers div.left {
    width: 12%;
  }
}
@media all and (max-width: 980px) {
  div.featured div.contents div.containers div.left {
    width: 14%;
  }
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.left {
    display: none;
  }
}
div.featured div.contents div.containers div.left div.activeThumb {
  width: 100%;
}
div.featured div.contents div.containers div.left div.activeThumb div.imageContainer {
  width: 100%;
}
div.featured div.contents div.containers div.left div.activeThumb div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 8px;
}
div.featured div.contents div.containers div.right {
  width: 78%;
}
div.featured div.contents div.containers div.right div.metaData {
  padding: 35px 0;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.right div.metaData {
    padding: 20px 0;
  }
}
div.featured div.contents div.containers div.right div.metaData div.datas {
  animation: slideUp 0.5s ease-in-out;
}
div.featured div.contents div.containers div.right div.metaData div.datas span {
  color: var(--grey-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.featured div.contents div.containers div.right div.metaData div.datas span {
    font-size: 15px;
  }
}
div.featured div.contents div.containers div.right div.metaData div.datas span.year {
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.duration {
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.duration::before {
  content: "·";
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas span.certificate {
  display: inline-block;
  padding: 1px 6px;
  background-color: rgba(150, 153, 163, 0.32);
  font-size: 12px;
  font-weight: 700;
  color: var(--white-color);
  border-radius: 4px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres {
  margin-top: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span {
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span::before {
  content: "·";
  margin-inline-end: 6px;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span:first-child::before {
  display: none;
}
div.featured div.contents div.containers div.right div.metaData div.datas div.genres span:last-child {
  margin-inline-end: 0;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer {
  margin-top: 30px;
  background: var(--text-hover);
  padding: 12px 24px;
  width: 13rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  color: var(--white-color);
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer.no-flex {
  justify-content: start;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer svg {
  margin-inline-end: 10px;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer:hover {
  background: var(--button-hover);
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer span {
  color: var(--white-color);
  font-weight: 700;
  font-size: 15px;
}
div.featured div.contents div.containers div.right div.metaData div.buttonContainer span.free {
  display: inline-block;
  padding: 1px 6px;
  color: #000;
  background-color: var(--white-color);
  border-radius: 30px;
  font-size: 10px;
}
@keyframes slideUp {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

div.homePage {
  overflow-x: hidden;
  padding: 50px 0 40px;
  z-index: 999 !important;
  background-color: var(--bg-color);
}
div.homePage .swiper {
  overflow: visible;
}
div.homePage .swiper-button-next {
  right: 0;
  height: 100%;
  width: 4%;
  top: 5%;
  background: rgba(0, 0, 0, 0.5);
}
div.homePage .swiper-button-prev {
  height: 100%;
  left: -150px;
  height: 100%;
  width: 12%;
  top: 5%;
  background-image: --swiper-prev-background;
}
@media all and (max-width: 2500px) {
  div.homePage .swiper-button-prev {
    left: -229px;
    width: 14rem;
  }
}
div.categoriesContainer {
  border-bottom: 1px solid var(--border-color);
  margin-top: 20px;
}
@media all and (max-width: 1200px) {
  div.categoriesContainer {
    width: 98%;
    margin-inline-start: auto;
    margin-top: 20px;
  }
  div.categoriesContainer .swiper-button-next, div.categoriesContainer .swiper-button-prev {
    display: none;
  }
}
@media all and (max-width: 480px) {
  div.categoriesContainer {
    width: 81%;
    margin-inline-start: 0;
    position: relative;
    inset-inline-start: 20px;
  }
}
div.categoriesContainer .swiper-slide {
  z-index: 9;
}
div.categoriesContainer .swiper-slide:hover {
  z-index: 99 !important;
}
div.categoriesContainer:hover .swiper-button-next::after {
  opacity: 1;
}
div.categoriesContainer:hover .swiper-button-prev::after {
  opacity: 1;
}
div.categoriesContainer .swiper-button-prev {
  inset-inline-start: -112px;
}
div.categoriesContainer .swiper-button-disabled {
  display: none !important;
}
div.categoriesContainer div.wrapper:hover div.itemsContainer div.categoryNameContainer span.seeAll {
  visibility: visible;
}
div.categoriesContainer div.categoryNameContainerMedium {
  display: none;
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.categoryNameContainerMedium {
    display: block;
  }
  div.categoriesContainer div.categoryNameContainerMedium h1 {
    font-size: 11px;
    text-transform: uppercase;
    color: var(--text-color);
  }
}
div.categoriesContainer div.itemsContainer {
  position: relative;
  margin-top: 20px;
  width: 90%;
  margin-inline-start: auto;
  z-index: 999;
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer {
    margin-inline-start: 0;
    width: 100%;
    margin-top: 10px;
  }
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer {
  position: absolute;
  z-index: 9;
  inset-inline-start: -152px;
  width: 9.5rem;
  background-color: var(--bg-color);
  height: -webkit-fill-available;
  cursor: pointer;
}
@media (max-width: 2600px) {
  div.categoriesContainer div.itemsContainer div.categoryNameContainer {
    inset-inline-start: -260px;
    width: 16.2rem;
  }
}
@media (max-width: 1980px) {
  div.categoriesContainer div.itemsContainer div.categoryNameContainer {
    inset-inline-start: -198px;
    width: 12.4rem;
  }
}
@media (max-width: 1600px) {
  div.categoriesContainer div.itemsContainer div.categoryNameContainer {
    inset-inline-start: -160px;
    width: 10rem;
  }
}
@media (max-width: 1400px) {
  div.categoriesContainer div.itemsContainer div.categoryNameContainer {
    inset-inline-start: -152px;
    width: 9.5rem;
  }
}
@media all and (max-width: 1200px) {
  div.categoriesContainer div.itemsContainer div.categoryNameContainer {
    display: none;
  }
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.categoryDetails {
  margin-inline-start: 20%;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.categoryDetails h1 {
  font-size: 12px;
  color: var(--text-color);
  text-transform: uppercase;
  max-width: 99%;
}
div.categoriesContainer div.itemsContainer div.categoryNameContainer div.categoryDetails span.seeAll {
  font-size: 11px;
  text-decoration: underline;
  visibility: hidden;
  color: var(--text-color);
}
div.categoriesContainer div.itemsContainer div.show {
  width: 14rem;
}
@media all and (min-width: 320px) {
  div.categoriesContainer div.itemsContainer div.show {
    width: 100%;
  }
}

div.categoriesContainer.ltr {
  direction: ltr;
  text-align: left;
}
div.categoriesContainer.ltr div.categoryNameContainerMedium {
  direction: rtl;
  text-align: right;
}
div.categoriesContainer.ltr div.itemsContainer {
  margin-inline-start: unset;
  margin-right: auto;
}
@media all and (max-width: 1200px) {
  div.categoriesContainer.ltr div.itemsContainer {
    margin-inline-start: unset;
    margin-right: 0;
  }
}
div.categoriesContainer.ltr div.itemsContainer div.categoryNameContainer {
  direction: rtl;
  text-align: right;
}
div.categoriesContainer.ltr .swiper-button-next {
  background: none;
}
div.categoriesContainer.ltr .swiper-button-prev {
  background: rgba(0, 0, 0, 0.5);
  width: 4%;
  left: 0;
}
div.categoriesContainer.ltr .swiper-button-prev::after {
  left: unset;
}
@media all and (max-width: 2500px) {
  div.categoriesContainer.ltr .swiper-button-prev {
    left: 0;
  }
}

div.showCardContainer {
  width: 100%;
  position: relative;
  transition: transform 0.5s ease-in-out, box-shadow 0.3s ease-in-out;
}
div.showCardContainer div.showsSubscriptions {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.showsSubscriptions {
    display: none;
  }
}
div.showCardContainer div.showsSubscriptions div.items {
  width: 5rem;
  margin-inline-end: 10px;
  background-color: var(--yellow-color);
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--white-color);
  margin-bottom: 10px;
  border-radius: 30px;
}
div.showCardContainer div.showsSubscriptions div.items.free {
  background-color: var(--success-color);
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.showsSubscriptions div.items {
    font-size: 8px;
    width: 4rem;
  }
}
div.showCardContainer div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.premiumStatus div.premium {
    width: 26px;
  }
}
div.showCardContainer div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.showCardContainer div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  width: 50px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.premiumStatus div.free {
    width: 30px;
  }
}
div.showCardContainer div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.showCardContainer div.premiumStatus div.lock {
  position: absolute;
  top: 6px;
  inset-inline-start: 6px;
  z-index: 8;
  width: 20px;
}
@media all and (max-width: 1200px) {
  div.showCardContainer div.premiumStatus div.lock {
    width: 15px;
  }
}
div.showCardContainer div.premiumStatus div.lock img {
  display: block;
  width: 100%;
}
div.showCardContainer:hover {
  z-index: 1;
}
div.showCardContainer:hover div.imageContainer div.extraActions {
  visibility: visible;
  opacity: 1;
  animation: opacity 0.3s ease-in-out !important;
}
div.showCardContainer:hover div.imageContainer img {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.667);
  width: 100%;
  display: block;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  transition: none;
  filter: none;
  z-index: 1;
}
div.showCardContainer div.imageContainer {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}
div.showCardContainer div.imageContainer img {
  width: 100%;
  display: block;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  z-index: 1;
}
div.showCardContainer div.imageContainer img.landscape {
  min-height: 150px;
  background: black;
}
div.showCardContainer div.imageContainer img.portrait {
  min-height: 420px;
  background: black;
}
div.showCardContainer div.imageContainer div.continueWatchingBar {
  position: absolute;
  bottom: 0;
  height: 6px;
  background-color: var(--white-color);
  width: 100%;
}
div.showCardContainer div.imageContainer div.continueWatchingBar div.line {
  height: 100%;
  background-color: var(--button-hover);
}
div.showCardContainer div.imageContainer div.extraActions {
  visibility: hidden;
  animation: fadeOut 0.2s ease-in-out forwards;
}
div.showCardContainer div.imageContainer div.extraActions.hide {
  display: none;
}
div.showCardContainer div.imageContainer div.extraActions.show {
  display: block;
}
div.showCardContainer div.imageContainer div.extraActions.show div.showCardOverlay {
  cursor: pointer;
}
div.showCardContainer div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.showCardContainer div.imageContainer div.extraActions div.playIconContainer {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
@media all and (min-width: 320px) {
  div.showCardContainer div.imageContainer div.extraActions div.playIconContainer {
    width: 30px;
    height: 30px;
  }
  div.showCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 13px !important;
    height: 25px !important;
  }
}
@media all and (min-width: 320px) {
  div.showCardContainer div.imageContainer div.extraActions div.playIconContainer {
    width: 40px;
    height: 40px;
  }
}
div.showCardContainer div.imageContainer div.extraActions div.playIconContainer:hover {
  border-color: var(--button-hover);
  background-color: var(--button-hover);
}
div.showCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
  width: 13px !important;
  height: 13px !important;
  color: var(--white-color);
  transition: 0.3s ease-in-out;
}
@media all and (min-width: 320px) {
  div.showCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 13px !important;
    height: 13px !important;
  }
}
@media all and (min-width: 480px) {
  div.showCardContainer div.imageContainer div.extraActions div.playIconContainer svg {
    width: 25px !important;
    height: 18px !important;
  }
}
div.showCardContainer div.imageContainer div.extraActions div.addToList {
  position: absolute;
  top: 10px;
  inset-inline-end: 10px;
  z-index: 9;
  cursor: pointer;
}
div.showCardContainer div.imageContainer div.extraActions div.addToList div.icon svg {
  width: 25px !important;
  height: 25px !important;
}
div.showCardContainer div.imageContainer div.extraActions div.playTrailer {
  position: absolute;
  z-index: 9;
  bottom: 10px;
  inset-inline-end: 10px;
  cursor: pointer;
}
div.showCardContainer div.imageContainer div.extraActions div.playTrailer svg {
  width: 30px !important;
  height: 30px !important;
  fill: white !important;
}
div.showCardContainer div.metaData {
  margin-top: 10px;
  color: var(--text-color);
  position: absolute;
  bottom: 10px;
  padding-inline-start: 20px;
  width: 100%;
}
div.showCardContainer div.metaData h1.title {
  color: var(--white-color);
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media all and (max-width: 640px) {
  div.showCardContainer div.metaData h1.title {
    font-size: 14px;
  }
}
div.showCardContainer div.metaData div.datas span {
  font-size: 12px;
  font-weight: 700;
  color: var(--white-color);
  text-transform: uppercase;
  margin-inline-end: 3px;
}
div.showCardContainer div.metaData div.datas span:last-child {
  margin-inline-end: 0;
}
div.showCardContainer div.metaData div.datas span.director {
  font-size: 12px;
  font-weight: 700;
  color: var(--white-color);
  text-transform: uppercase;
  margin-inline-end: 3px;
}
div.showCardContainer div.metaData div.datas span.year.ltr {
  direction: ltr !important;
  unicode-bidi: embed !important;
}
div.showCardContainer div.metaData div.datas p.description {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 30px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

div.footerContainer {
  padding: 30px 0;
  position: relative;
  z-index: 9;
  background-color: var(--default-bg-color);
  border-top: 1px solid var(--default-border-color);
}
div.footerContainer div.wrapper {
  width: 90%;
}
div.footerContainer div.topContainer {
  display: flex;
  justify-content: space-between;
}
div.footerContainer div.topContainer div.left {
  width: 20%;
  border-bottom: 1px solid var(--default-border-color);
  padding-bottom: 20px;
}
div.footerContainer div.topContainer div.left div.logoContainer {
  width: 100px;
}
div.footerContainer div.topContainer div.left div.logoContainer img {
  display: block;
  width: 100%;
}
div.footerContainer div.topContainer div.left div.logoContainer img.jwm {
  width: 60%;
}
@media (max-width: 480px) {
  div.footerContainer div.topContainer div.left div.logoContainer img.jwm {
    width: 50%;
  }
}
@media (max-width: 480px) {
  div.footerContainer div.topContainer div.left div.logoContainer img.jwm {
    width: 45%;
  }
}
div.footerContainer div.topContainer div.right {
  width: 20%;
  border-bottom: 1px solid var(--default-border-color);
  padding-bottom: 20px;
}
div.footerContainer div.topContainer div.right.jwm {
  width: 30%;
}
@media all and (max-width: 640px) {
  div.footerContainer div.topContainer div.right {
    width: 35%;
  }
}
div.footerContainer div.topContainer div.right img {
  display: none;
  padding-bottom: 10px;
}
@media (max-width: 980px) {
  div.footerContainer div.topContainer div.right img {
    position: relative;
    display: block;
    inset-inline-end: 60%;
    width: 250px;
  }
}
@media (max-width: 480px) {
  div.footerContainer div.topContainer div.right img {
    width: 200px;
  }
}
@media (max-width: 360px) {
  div.footerContainer div.topContainer div.right img {
    width: 150px;
  }
}
div.footerContainer div.topContainer div.right ul.social {
  display: flex;
  align-items: center;
}
div.footerContainer div.topContainer div.right ul.social li {
  margin-inline-end: 10px;
}
div.footerContainer div.topContainer div.right ul.social li:last-child {
  margin-inline-end: 0;
}
div.footerContainer div.topContainer div.right ul.social li:hover svg {
  opacity: 0.6;
  color: var(--default-text-hover-color);
}
div.footerContainer div.topContainer div.right ul.social li svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--grey-color);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.footerContainer div.topContainer div.right ul.social li img {
  display: block;
  width: 350px !important;
}
@media (max-width: 1600px) {
  div.footerContainer div.topContainer div.right ul.social li img {
    width: 300px !important;
  }
}
@media (max-width: 1200px) {
  div.footerContainer div.topContainer div.right ul.social li img {
    width: 250px !important;
  }
}
@media (max-width: 980px) {
  div.footerContainer div.topContainer div.right ul.social li img {
    display: none;
  }
}
div.footerContainer div.centerContainer div.informationContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 0;
}
div.footerContainer div.centerContainer div.informationContainer div.items {
  width: 20%;
  color: var(--white-color);
}
@media all and (max-width: 768px) {
  div.footerContainer div.centerContainer div.informationContainer div.items {
    width: 25%;
    margin-bottom: 20px;
  }
}
@media all and (max-width: 640px) {
  div.footerContainer div.centerContainer div.informationContainer div.items {
    width: 33%;
  }
}
div.footerContainer div.centerContainer div.informationContainer div.items h6.heading {
  font-size: 12px;
  color: var(--text-color);
  margin-bottom: 10px;
}
div.footerContainer div.centerContainer div.informationContainer div.items ul li span {
  font-size: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
@media all and (max-width: 360px) {
  div.footerContainer div.centerContainer div.informationContainer div.items ul li span {
    font-size: 13px;
  }
}
div.footerContainer div.centerContainer div.informationContainer div.items ul li span:hover {
  opacity: 0.6;
  color: var(--default-text-hover-color);
}
div.footerContainer div.bottomContainer {
  border-top: 1px solid var(--default-border-color);
  padding: 40px 0;
}
div.footerContainer div.bottomContainer div.stores {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
div.footerContainer div.bottomContainer div.stores svg {
  margin-inline-end: 10px;
  width: 120px !important;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.footerContainer div.bottomContainer div.stores svg:last-child {
  margin-inline-end: 0;
}
div.footerContainer div.bottomContainer div.stores svg:hover {
  opacity: 0.8;
}
div.footerContainer div.bottomContainer p {
  text-align: center;
  font-size: 12px;
  color: var(--grey-color);
  opacity: 0.7;
}
div.footerContainer div.bottomContainer div.typeOfPayments {
  color: var(--grey-color) !important;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
div.footerContainer div.bottomContainer div.typeOfPayments i {
  margin-inline-end: 6px;
}
div.footerContainer div.bottomContainer div.typeOfPayments i:last-child {
  margin-inline-end: 0;
}

div.detailsScreen {
  background-color: var(--secondary-bg-color);
}
div.detailsScreen div.topBackgroundContainer {
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100%;
  background-size: cover;
  top: 50px;
  height: 93vh;
  margin-bottom: 20px;
}
@media (max-width: 1200px) {
  div.detailsScreen div.topBackgroundContainer {
    height: unset;
  }
}
@media (max-width: 768px) {
  div.detailsScreen div.topBackgroundContainer {
    top: unset;
    padding-bottom: 20px;
    height: unset;
  }
}
div.detailsScreen div.topBackgroundContainer div.contents {
  display: flex;
  justify-content: space-between;
  padding: 0 50px 20px 50px;
}
div.detailsScreen div.topBackgroundContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.6) 70%, rgba(0, 0, 0, 0.8) 100%);
}
div.detailsScreen div.topBackgroundContainer div.left {
  padding-top: 220px;
  width: 23%;
  z-index: 9;
  padding-inline-start: 5px;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    padding-inline-start: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    padding-inline-start: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    padding-inline-start: 30px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    width: 24%;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left {
    width: 100%;
    padding-top: 280px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left h1.title {
  color: var(--white-color);
  font-size: 30px;
  text-transform: uppercase;
  margin-bottom: 16px;
}
div.detailsScreen div.topBackgroundContainer div.left h2.director {
  color: var(--white-color);
  font-size: 15px;
  font-weight: normal;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left h2.director {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left h2.director span.name {
  font-weight: 700;
  font-size: 15px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left h2.director span.name {
    font-size: 15px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left span.year {
  font-size: 15px;
  color: var(--white-color);
  margin-bottom: 10px;
  display: inline-block;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo {
  display: flex;
  color: var(--white-color);
  align-items: center;
  border-bottom: 1px solid var(--white-color);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon {
  margin-inline-end: 5px;
  line-height: 0;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon svg {
  width: 16px !important;
  height: 16px !important;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon svg path {
  fill: var(--white-color) !important;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration {
  display: flex;
  align-items: center;
  margin-inline-end: 10px;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration span {
  font-size: 15px;
  font-weight: 500;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration span {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration span.ltr {
  direction: ltr !important;
  unicode-bidi: embed !important;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.language {
  display: flex;
  align-items: center;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.language span {
  font-size: 15px;
  font-weight: 500;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.language span {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.rating {
  padding: 1px 6px;
  border: 1px solid var(--white-color);
  border-radius: 4px;
  margin-inline-start: 10px;
}
div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.rating span {
  font-size: 14px;
  font-weight: 500;
  display: block;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.rating span {
    font-size: 13px;
  }
}
div.detailsScreen div.topBackgroundContainer div.left div.trailer {
  padding-bottom: 15px !important;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.left div.trailer {
    display: none;
  }
}
div.detailsScreen div.topBackgroundContainer div.left div.trailer button.trailerBtn {
  background-color: transparent;
  border: 1px solid var(--white-color);
  width: 6.5rem;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 6px;
  color: var(--white-color);
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.left div.trailer button.trailerBtn:hover {
  border-color: var(--button-hover);
  background-color: var(--button-hover);
}
div.detailsScreen div.topBackgroundContainer div.left div.trailer button.trailerBtn div.icon {
  line-height: 0;
  margin-inline-start: 8px;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--text-color);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.addRating {
  margin-inline-end: 10px;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.addRating:hover svg {
  fill: var(--grey-color);
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.addRating svg {
  width: 27px !important;
  height: 27px !important;
  fill: var(--white-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks {
  display: flex;
  align-items: center;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.facebookLink, div.detailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.instagramLink {
  margin-inline-end: 10px;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.facebookLink:hover svg, div.detailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.instagramLink:hover svg {
  color: var(--grey-color);
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.facebookLink svg, div.detailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.instagramLink svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--white-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.userRatings {
  display: flex;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.userRatings div.star.active svg {
  fill: #fbe725;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.userRatings div.star svg {
  width: 23px !important;
  height: 23px !important;
  fill: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.favourite {
  margin-inline-end: 10px;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.favourite:hover svg {
  fill: var(--grey-color);
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.favourite svg {
  width: 23px !important;
  height: 23px !important;
  fill: var(--white-color);
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.watchListButton {
  cursor: pointer;
  margin-inline-end: 10px;
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.watchListButton:hover svg {
  fill: var(--grey-color);
}
div.detailsScreen div.topBackgroundContainer div.left div.actions div.watchListButton svg {
  width: 22px !important;
  height: 22px !important;
  transition: 0.3s ease-in-out;
}
div.detailsScreen div.topBackgroundContainer div.left div.externalLinks {
  display: flex;
}
div.detailsScreen div.topBackgroundContainer div.left div.externalLinks div.imageContainer {
  margin-inline-end: 10px;
  width: 35px;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.left div.externalLinks div.imageContainer:last-child {
  margin-inline-end: 0;
}
div.detailsScreen div.topBackgroundContainer div.left div.externalLinks div.imageContainer img {
  width: 100%;
  display: block;
}
div.detailsScreen div.topBackgroundContainer div.categoryName {
  background-color: #68abff;
  padding: 10px 6px;
  width: 8rem;
  text-transform: uppercase;
  color: var(--white-color);
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-start: auto;
  position: absolute;
  inset-inline-end: 0;
  top: 20px;
  z-index: 9;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.categoryName {
    display: none;
  }
}
div.detailsScreen div.topBackgroundContainer div.top {
  position: absolute;
  inset-inline-end: 0;
  text-align: end;
  padding-top: 60px;
  z-index: 9;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.top {
    inset-inline-end: 100px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.top {
    inset-inline-end: 50px;
  }
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.topBackgroundContainer div.top {
    inset-inline-end: 60px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top {
    padding-top: 75px;
    inset-inline-end: 40px;
  }
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-inline-end: 20px;
  margin-top: 10px;
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings div.icon {
  margin-inline-end: 5px;
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings div.icon svg {
  width: 18px !important;
  height: 18px !important;
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings h1.rating {
  font-size: 22px;
  color: var(--white-color);
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top div.ratings h1.rating {
    font-size: 23px;
  }
}
div.detailsScreen div.topBackgroundContainer div.top div.ratings h1.rating span.outOf {
  color: var(--text-color);
  font-size: 15px;
  font-weight: 700;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top div.ratings h1.rating span.outOf {
    font-size: 15px;
  }
}
div.detailsScreen div.topBackgroundContainer div.top span.totalRatings {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 700;
  padding-inline-end: 3px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.top span.totalRatings {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.topLeft {
  position: absolute;
  text-align: end;
  margin-top: 100px;
  z-index: 999;
  cursor: pointer;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.topLeft {
    margin-inline-start: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.topLeft {
    margin-inline-start: 15px;
  }
}
div.detailsScreen div.topBackgroundContainer div.topLeft svg {
  width: 30px;
  fill: #fff !important;
  stroke: #fff;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.right {
  width: 73%;
  position: relative;
  top: 500px;
  z-index: 2;
  display: flex;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    padding-inline-end: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    padding-inline-end: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    padding-inline-end: 30px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    width: 70%;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.right {
    display: none;
  }
}
div.detailsScreen div.topBackgroundContainer div.right div.synopsis {
  color: var(--white-color);
  margin-inline-end: 40px;
  width: 50%;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.right div.synopsis {
    margin-inline-end: 0;
    margin-bottom: 20px;
  }
}
div.detailsScreen div.topBackgroundContainer div.right div.synopsis h1.head {
  font-size: 18px;
}
div.detailsScreen div.topBackgroundContainer div.right div.synopsis p.synopis {
  font-size: 15px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.right div.synopsis p.synopis {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.right div.ourTake {
  color: var(--white-color);
  width: 50%;
}
div.detailsScreen div.topBackgroundContainer div.right div.ourTake h1.head {
  font-size: 18px;
}
div.detailsScreen div.topBackgroundContainer div.right div.ourTake p.ourTake {
  font-size: 15px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.right div.ourTake p.ourTake {
    font-size: 14px;
  }
}
div.detailsScreen div.topBackgroundContainer div.pageDown {
  z-index: 9;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.pageDown:hover {
  bottom: -10px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.pageDown {
    display: none;
  }
}
div.detailsScreen div.topBackgroundContainer div.pageDown svg {
  width: 35px !important;
  height: 35px !important;
  transform: rotate(-90deg);
  fill: var(--text-color);
}
div.detailsScreen div.topBackgroundContainer div.playIconContainer {
  position: absolute;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid var(--white-color);
  transition: 0.3s ease-in-out;
  z-index: 9;
  left: 50%;
  top: 35%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.playIconContainer {
    top: 30%;
  }
}
div.detailsScreen div.topBackgroundContainer div.playIconContainer svg {
  width: 30px !important;
  height: 30px !important;
  color: var(--white-color);
}
div.detailsScreen div.topBackgroundContainer div.playIconContainer:hover {
  background-color: var(--button-hover);
  border-color: var(--button-hover);
}
div.detailsScreen div.topBackgroundContainer div.subscriptions {
  margin-top: 20px;
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  z-index: 9;
  padding-inline-start: 5px;
  padding-bottom: 30px;
}
@media all and (max-width: 2500px) {
  div.detailsScreen div.topBackgroundContainer div.subscriptions {
    padding-inline-start: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.detailsScreen div.topBackgroundContainer div.subscriptions {
    padding-inline-start: 50px;
  }
}
@media (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.subscriptions {
    width: 100%;
  }
}
div.detailsScreen div.topBackgroundContainer div.subscriptions div.items {
  z-index: 9;
  cursor: pointer;
  margin-inline-end: 10px;
  background-color: var(--yellow-color);
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  color: var(--white-color);
  margin-bottom: 20px;
  border-radius: 30px;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.topBackgroundContainer div.subscriptions div.items {
    font-size: 12px;
  }
}
div.detailsScreen div.topBackgroundContainer div.subscriptions div.items.free {
  background-color: var(--success-color);
}
div.detailsScreen div.topBackgroundContainer div.shareContainer {
  position: absolute;
  inset-inline-end: 5px;
  z-index: 9;
  bottom: 10px;
  display: flex;
  align-items: center;
  padding-inline-end: 50px;
  cursor: pointer;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer span {
  color: var(--white-color);
  font-size: 14px;
  margin-inline-end: 15px;
  transition: 0.2s ease-in-out;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer span.active {
  transform: translateX(-10px);
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.icon {
  line-height: 0;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.icon svg {
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.icon .closeIcon {
  stroke: var(--white-color) !important;
  display: block;
  width: 11px !important;
  height: 11px !important;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons {
  line-height: 0;
  animation: fadeIn 0.9s;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .facebook {
  margin-inline-end: 15px;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .facebook div.icon svg {
  color: var(--white-color);
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .twitter {
  margin-inline-end: 15px;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .twitter div.icon svg {
  color: var(--white-color);
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .clipBoard {
  margin-inline-end: 15px;
  display: inline;
}
div.detailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .clipBoard svg {
  fill: var(--white-color);
  width: 20px !important;
  height: 20px !important;
}
div.detailsScreen div.responsiveContent {
  padding-bottom: 25px;
  display: none;
}
@media all and (max-width: 980px) {
  div.detailsScreen div.responsiveContent {
    display: block;
  }
}
div.detailsScreen div.responsiveContent div.categoryNameSM {
  background-color: rgb(242, 89, 89);
  padding: 12px 16px;
  text-transform: uppercase;
  color: var(--white-color);
  font-weight: 700;
  font-size: 15px;
}
div.detailsScreen div.responsiveContent div.synopsisSM {
  padding: 25px 15px;
  color: var(--text-color);
}
div.detailsScreen div.responsiveContent div.synopsisSM h1.heading {
  font-size: 16px;
  margin-bottom: 8px;
}
div.detailsScreen div.responsiveContent div.synopsisSM p.synopsis {
  font-size: 16px;
}
div.detailsScreen div.responsiveContent div.ourTakeSM {
  padding: 0 15px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.detailsScreen div.responsiveContent div.ourTakeSM h1.heading {
  font-size: 16px;
  margin-bottom: 8px;
}
div.detailsScreen div.responsiveContent div.ourTakeSM p.ourTake {
  font-size: 16px;
}
div.detailsScreen div.responsiveContent div.trailer {
  padding-inline-end: 15px;
}
div.detailsScreen div.responsiveContent div.trailer button.trailerBtn {
  background-color: transparent;
  border: 1px solid var(--text-color);
  width: 5.5rem;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 7px 10px;
  color: var(--text-color);
  margin-inline-start: auto;
  cursor: pointer;
}
div.detailsScreen div.responsiveContent div.trailer button.trailerBtn:hover {
  border-color: var(--button-hover);
  background-color: var(--button-hover);
}
div.detailsScreen div.responsiveContent div.trailer button.trailerBtn div.icon {
  line-height: 0;
  margin-inline-start: 8px;
}
div.detailsScreen div.categories {
  padding: 30px 60px;
  background-color: var(--secondary-bg-color);
  position: relative;
  z-index: 3;
}
@media all and (max-width: 768px) {
  div.detailsScreen div.categories {
    padding: 30px 30px;
  }
}
div.detailsScreen div.categories h1.heading {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 20px;
}
div.detailsScreen div.categories div.categoryLists ul {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.categories div.categoryLists ul li.list {
  display: flex;
  margin-inline-end: 10px;
  width: 24%;
  background-color: var(--white-color);
  margin-bottom: 10px;
  cursor: pointer;
}
div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.categories div.categoryLists ul li.list {
    width: 32.6%;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(4n) {
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.categories div.categoryLists ul li.list {
    width: 49%;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-inline-end: 10px;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.detailsScreen div.categories div.categoryLists ul li.list {
    width: 100%;
    margin-inline-end: 0;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.categories div.categoryLists ul li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.detailsScreen div.categories div.categoryLists ul li.list div.left div.imageContainer {
  width: 70px;
}
div.detailsScreen div.categories div.categoryLists ul li.list div.left div.imageContainer img {
  display: block;
  width: 100%;
}
div.detailsScreen div.categories div.categoryLists ul li.list div.right {
  padding: 10px;
}
div.detailsScreen div.categories div.categoryLists ul li.list div.right h1.title {
  font-size: 14px;
  text-transform: uppercase;
}
div.detailsScreen div.aboutSection {
  background-color: var(--secondary-bg-color);
  padding: 30px 60px;
}
@media all and (max-width: 768px) {
  div.detailsScreen div.aboutSection {
    padding: 30px 30px;
  }
}
div.detailsScreen div.aboutSection h1.heading {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
div.detailsScreen div.aboutSection div.aboutLists {
  display: flex;
  flex-wrap: wrap;
}
div.detailsScreen div.aboutSection div.aboutLists ul {
  flex-direction: column;
  width: 100%;
}
div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer {
  background-color: var(--secondary-bg-color);
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}
div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
  width: 100%;
  background-color: var(--white-color);
  margin-inline-end: 7px;
}
div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:hover div.info h1.name {
  color: var(--grey-color);
}
div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(8n) {
  margin-inline-end: 0;
}
div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:last-child {
  margin-inline-end: 0 !important;
}
@media all and (max-width: 2600px) {
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 14%;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(7n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(8n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1400px) {
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 14%;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(7n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(8n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1200px) {
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 19%;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(7n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(6n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 24%;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(6n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 768px) {
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 32%;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(6n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 480px) {
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 49%;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(3n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 360px) {
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist {
    width: 99%;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(3n) {
    margin-inline-end: 7px;
  }
  div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.imageContainer {
  width: 100%;
  background-color: var(--white-color);
  margin-inline-end: 7px;
  position: relative;
  align-content: space-around;
}
div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.imageContainer img {
  width: 100%;
  align-items: center;
  display: block;
}
div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.imageContainer div.openurl {
  position: absolute;
  z-index: 99;
  margin-top: -44px;
  inset-inline-end: 5px;
  cursor: pointer;
}
div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.imageContainer div.openurl svg {
  fill: #fff !important;
  width: 16px;
  stroke: #fff;
}
div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.info {
  padding: 10px 10px 30px;
  background: #fff;
  padding: 8px;
  min-height: 121px;
  max-height: 121px;
}
div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.info h1.title {
  font-size: 16px;
  color: var(--text-hover);
}
div.detailsScreen div.aboutSection div.aboutLists ul div.aboutContainer li.aboutlist div.info p.description {
  color: #666;
  font-weight: 400;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 10px;
}

div.awardsAndFestivals {
  padding: 30px 60px 0;
  background-color: var(--secondary-bg-color);
  align-items: center;
}
div.awardsAndFestivals h1.heading {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 20px;
}
div.awardsAndFestivals ul.lists {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}
div.awardsAndFestivals ul.lists li.list {
  margin-inline-end: 10px;
  width: 24%;
  margin-bottom: 20px;
  text-align: center;
  align-items: center;
}
div.awardsAndFestivals ul.lists li.list:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.awardsAndFestivals ul.lists li.list {
    width: 32.6%;
  }
  div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.awardsAndFestivals ul.lists li.list:nth-child(4n) {
    margin-inline-end: 10px;
  }
}
@media all and (max-width: 980px) {
  div.awardsAndFestivals ul.lists li.list {
    width: 49%;
  }
  div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-inline-end: 10px;
  }
  div.awardsAndFestivals ul.lists li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 640px) {
  div.awardsAndFestivals ul.lists li.list {
    width: 100%;
    margin-inline-end: 0;
  }
  div.awardsAndFestivals ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0;
  }
  div.awardsAndFestivals ul.lists li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.awardsAndFestivals ul.lists li.list div.imageContainer {
  margin: 0 auto 20px;
  width: 40px;
  filter: grayscale(100%);
  background: rgb(200, 200, 200);
  margin-bottom: 20px;
  align-items: center;
}
div.awardsAndFestivals ul.lists li.list div.imageContainer img {
  display: block;
  width: 100%;
}
div.awardsAndFestivals ul.lists li.list h1.title {
  color: var(--text-color);
  font-size: 14px;
  margin-bottom: 10px;
}
div.awardsAndFestivals ul.lists li.list div.descriptionAndYear {
  display: flex;
  justify-content: center;
}
div.awardsAndFestivals ul.lists li.list div.descriptionAndYear span.year {
  margin-inline-end: 6px;
  font-size: 13px;
  color: var(--grey-color);
  font-weight: normal;
}
div.awardsAndFestivals ul.lists li.list div.descriptionAndYear p.description {
  font-size: 13px;
  color: var(--grey-color);
  font-weight: normal;
}
div.awardsAndFestivals ul.lists li.list div.svgContainer {
  position: relative;
  width: 100%;
  display: table;
}
div.awardsAndFestivals ul.lists li.list div.svgContainer svg {
  width: 76px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}
div.awardsAndFestivals ul.lists li.list div.svgContainer div.metaData {
  position: relative;
  top: 10px;
  z-index: 1;
}
div.awardsAndFestivals ul.lists li.list div.svgContainer div.metaData h1.title {
  color: var(--text-color);
  font-size: 14px;
  margin-bottom: 10px;
}
div.awardsAndFestivals ul.lists li.list div.svgContainer div.metaData div.descriptionAndYear {
  display: flex;
  justify-content: center;
}
div.awardsAndFestivals ul.lists li.list div.svgContainer div.metaData div.descriptionAndYear span.year {
  margin-inline-end: 6px;
  font-size: 13px;
  color: var(--grey-color);
  font-weight: normal;
  margin-top: -10px;
}
div.awardsAndFestivals ul.lists li.list div.svgContainer div.metaData div.descriptionAndYear p.description {
  font-size: 13px;
  color: var(--grey-color);
  font-weight: normal;
}

div.castAndCrew {
  background-color: var(--secondary-bg-color);
  padding: 30px 60px;
}
@media all and (max-width: 768px) {
  div.castAndCrew {
    padding: 30px 30px;
  }
}
div.castAndCrew h1.heading {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
div.castAndCrew ul.lists {
  display: flex;
  flex-wrap: wrap;
}
div.castAndCrew ul.lists li.list {
  width: 12%;
  background-color: var(--white-color);
  margin-inline-end: 7px;
  margin-bottom: 20px;
  cursor: pointer;
}
div.castAndCrew ul.lists li.list:hover div.info h1.name {
  color: var(--grey-color);
}
div.castAndCrew ul.lists li.list:nth-child(8n) {
  margin-inline-end: 0;
}
div.castAndCrew ul.lists li.list:last-child {
  margin-inline-end: 0 !important;
}
@media all and (max-width: 1400px) {
  div.castAndCrew ul.lists li.list {
    width: 13.7%;
  }
  div.castAndCrew ul.lists li.list:nth-child(7n) {
    margin-inline-end: 0;
  }
  div.castAndCrew ul.lists li.list:nth-child(8n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 1200px) {
  div.castAndCrew ul.lists li.list {
    width: 19%;
  }
  div.castAndCrew ul.lists li.list:nth-child(7n) {
    margin-inline-end: 7px;
  }
  div.castAndCrew ul.lists li.list:nth-child(6n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.castAndCrew ul.lists li.list {
    width: 24%;
  }
  div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.castAndCrew ul.lists li.list:nth-child(6n) {
    margin-inline-end: 7px;
  }
}
@media all and (max-width: 768px) {
  div.castAndCrew ul.lists li.list {
    width: 32.5%;
  }
  div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-inline-end: 7px;
  }
  div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 640px) {
  div.castAndCrew ul.lists li.list {
    width: 32%;
  }
  div.castAndCrew ul.lists li.list:nth-child(4n) {
    margin-inline-end: 7px;
  }
  div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 480px) {
  div.castAndCrew ul.lists li.list {
    width: 48%;
  }
  div.castAndCrew ul.lists li.list:nth-child(3n) {
    margin-inline-end: 7px;
  }
  div.castAndCrew ul.lists li.list:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 360px) {
  div.castAndCrew ul.lists li.list {
    width: 99%;
  }
}
div.castAndCrew ul.lists li.list div.imageContainer {
  filter: grayscale(100%);
  background: rgb(200, 200, 200);
  width: 100%;
}
div.castAndCrew ul.lists li.list div.imageContainer img {
  width: 100%;
  display: block;
}
div.castAndCrew ul.lists li.list div.info {
  padding: 10px 10px 30px;
}
div.castAndCrew ul.lists li.list div.info h1.name {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--text-color);
}
div.castAndCrew ul.lists li.list div.info p.role {
  font-size: 11px;
  color: var(--grey-color);
  text-transform: uppercase;
}

div.relatedFilms {
  background-color: var(--secondary-bg-color);
  padding: 30px 60px 0;
}
@media all and (max-width: 768px) {
  div.relatedFilms {
    padding: 30px 30px;
  }
}
div.relatedFilms h1.heading {
  color: var(--text-color);
  font-size: 17px;
  margin-bottom: 15px;
}
div.relatedFilms div.films {
  display: flex;
  flex-wrap: wrap;
}
div.relatedFilms div.films div.items.rowCount3 {
  width: 32.5%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.relatedFilms div.films div.items.rowCount3:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.relatedFilms div.films div.items.rowCount3 {
    width: 32%;
  }
}
div.relatedFilms div.films div.items.rowCount4 {
  width: 24%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.relatedFilms div.films div.items.rowCount4:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.relatedFilms div.films div.items.rowCount4 {
    width: 23.8%;
  }
}
div.relatedFilms div.films div.items.rowCount5 {
  width: 19.8%;
  margin-inline-end: 3px;
  margin-bottom: 30px;
}
div.relatedFilms div.films div.items.rowCount5:nth-child(5n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.relatedFilms div.films div.items.rowCount5 {
    width: 18.5%;
  }
}
div.relatedFilms div.films div.items.rowCount5 div.showCardContainer div.metaData h1.title {
  font-size: 14px;
}
div.relatedFilms div.films div.items.rowCount6 {
  width: 15.8%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.relatedFilms div.films div.items.rowCount6:nth-child(6n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.relatedFilms div.films div.items.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.relatedFilms div.films div.items.rowCount6 {
    width: 15%;
  }
}
div.relatedFilms div.films div.items.rowCount6 div.showCardContainer div.metaData h1.title {
  font-size: 14px;
}
div.relatedFilms div.films div.items.portrait {
  width: 15.6%;
  padding: 0 5px 10px;
}
@media all and (max-width: 1400px) {
  div.relatedFilms div.films div.items.portrait {
    width: 15.6%;
  }
}
@media all and (max-width: 1200px) {
  div.relatedFilms div.films div.items.portrait {
    width: 18.8%;
  }
}
@media all and (max-width: 980px) {
  div.relatedFilms div.films div.items.portrait {
    width: 23.5%;
    margin-inline-end: 13px;
    margin-bottom: 40px;
  }
  div.relatedFilms div.films div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.relatedFilms div.films div.items.portrait:nth-child(5n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 768px) {
  div.relatedFilms div.films div.items.portrait {
    width: 23%;
  }
  div.relatedFilms div.films div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.relatedFilms div.films div.items.portrait:nth-child(5n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 640px) {
  div.relatedFilms div.films div.items.portrait {
    width: 31%;
  }
  div.relatedFilms div.films div.items.portrait:nth-child(4n) {
    margin-inline-end: 13px;
  }
  div.relatedFilms div.films div.items.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.relatedFilms div.films div.items.portrait {
    width: 47%;
  }
  div.relatedFilms div.films div.items.portrait:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.relatedFilms div.films div.items.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.relatedFilms div.films div.items.landscape {
    width: 32%;
    margin-inline-end: 13px;
    margin-bottom: 40px;
  }
  div.relatedFilms div.films div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.relatedFilms div.films div.items.landscape {
    width: 47.5%;
  }
  div.relatedFilms div.films div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
  div.relatedFilms div.films div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 640px) {
  div.relatedFilms div.films div.items.landscape {
    width: 48%;
  }
  div.relatedFilms div.films div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.relatedFilms div.films div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.relatedFilms div.films div.items.landscape {
    width: 99%;
    margin-inline-end: 0px;
  }
  div.relatedFilms div.films div.items.landscape:nth-child(3n) {
    margin-inline-end: 0px;
  }
  div.relatedFilms div.films div.items.landscape:nth-child(2n) {
    margin-inline-end: 0px;
  }
}
@media all and (max-width: 360px) {
  div.relatedFilms div.films div.items.landscape {
    width: 99%;
  }
  div.relatedFilms div.films div.items.landscape:nth-child(3n) {
    margin-inline-end: 0px;
  }
  div.relatedFilms div.films div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
div.loginPage {
  background: linear-gradient(90deg, rgb(30, 38, 170) 0%, rgb(0, 90, 137) 66%, rgb(106, 164, 185) 100%);
}
div.loginPage span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: start;
  display: block;
  margin-bottom: 10px;
}
div.loginPage div.updatesContainer {
  text-align: center;
  padding-top: 100px;
  width: 50%;
  margin: 0 auto -90px;
}
@media all and (max-width: 1200px) {
  div.loginPage div.updatesContainer {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.loginPage div.updatesContainer {
    width: 90%;
  }
}
div.loginPage div.updatesContainer p {
  background-color: #fff;
  font-size: 14px;
  color: var(--error-color);
  padding: 10px;
  border-radius: 5px;
  font-weight: 600;
  border-bottom: 4px solid var(--error-color);
}
div.loginPage div.loginContainer {
  width: 20rem;
  margin: 0 auto;
  padding: 130px 0;
  text-align: center;
}
@media all and (max-width: 640px) {
  div.loginPage div.loginContainer {
    width: 95%;
  }
}
div.loginPage div.loginContainer div.top div#appleid-signin {
  height: 60px;
  cursor: pointer;
  padding: 5px;
  border: transparent;
  border-radius: 10px;
  transition: border 5s ease;
}
div.loginPage div.loginContainer div.top div#appleid-signin:hover {
  border: 1px solid transparent;
}
div.loginPage div.loginContainer div.top div.userIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
}
div.loginPage div.loginContainer div.top div.userIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--white-color);
}
div.loginPage div.loginContainer div.top h1 {
  color: var(--white-color);
  margin-bottom: 20px;
}
@media all and (max-width: 640px) {
  div.loginPage div.loginContainer div.top h1 {
    font-size: 25px;
  }
}
div.loginPage div.loginContainer div.top div.facebookContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.loginPage div.loginContainer div.top div.facebookContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.top div.facebookContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3d6ac3;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.left {
  line-height: 0;
  margin-inline-end: 50px;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--white-color);
  line-height: 0;
}
div.loginPage div.loginContainer div.top div.facebookContainer div.right span {
  color: var(--white-color);
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.loginPage div.loginContainer div.top div.googleContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.loginPage div.loginContainer div.top div.googleContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.top div.googleContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.top div.googleContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.loginPage div.loginContainer div.top div.googleContainer div.left {
  line-height: 0;
  margin-inline-end: 50px;
}
div.loginPage div.loginContainer div.top div.googleContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.loginPage div.loginContainer div.top div.googleContainer div.right span {
  color: #3c4043;
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.loginPage div.loginContainer div.top div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.loginPage div.loginContainer div.top div.seperate span {
  font-size: 12px;
  color: var(--white-color);
}
div.loginPage div.loginContainer div.top div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--text-color);
}
div.loginPage div.loginContainer div.top div.seperate span.line:first-child {
  margin-inline-end: 8px;
}
div.loginPage div.loginContainer div.top div.seperate span.line:last-child {
  margin-inline-start: 8px;
}
div.loginPage div.loginContainer div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.loginPage div.loginContainer div.seperate span {
  font-size: 12px;
  color: var(--text-color);
}
div.loginPage div.loginContainer div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--inner-text-color);
}
div.loginPage div.loginContainer div.seperate span.line:first-child {
  margin-inline-end: 8px;
}
div.loginPage div.loginContainer div.seperate span.line:last-child {
  margin-inline-start: 8px;
}
div.loginPage div.loginContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.loginPage div.loginContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.buttonContainer div.background {
  background: rgba(150, 153, 163, 0.16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginPage div.loginContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
}
div.loginPage div.loginContainer div.bottom {
  margin-top: 15px;
}
div.loginPage div.loginContainer div.bottom h3 {
  color: var(--white-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
div.loginPage div.loginContainer div.bottom form {
  margin-bottom: 20px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer {
  background-color: var(--white-color);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input {
  background: transparent;
  outline: none;
  border: none;
  text-align: start;
  width: 100%;
  padding: 20px 15px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.emailContainer input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer {
  background-color: var(--white-color);
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input {
  background: transparent;
  outline: none;
  border: none;
  text-align: start;
  width: 100%;
  padding: 20px 15px;
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.passwordContainer input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer div.background {
  background: var(--button-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in-out;
}
div.loginPage div.loginContainer div.bottom form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 9;
}
div.loginPage div.loginContainer div.bottom form h4.forgotPassword {
  color: var(--white-color);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
  cursor: pointer;
}
div.loginPage div.loginContainer div.bottom form h4 {
  font-size: 15px;
  color: #d5d5d6;
  font-weight: 400;
}
div.loginPage div.loginContainer div.bottom form h4 span {
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
div.loginPage div.loginContainer div.bottom p {
  font-size: 11px;
  color: var(--grey-color);
  line-height: 1.6em;
}
div.loginPage div.loginContainer div.bottom p span {
  color: var(--white-color);
  cursor: pointer;
}

div.registerPage {
  background: linear-gradient(90deg, rgb(30, 38, 170) 0%, rgb(0, 90, 137) 66%, rgb(106, 164, 185) 100%);
}
div.registerPage span.error {
  color: #e72a31;
  font-size: 12px;
  text-align: start;
  display: block;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer {
  width: 20rem;
  margin: 0 auto;
  padding: 130px 0 100px;
  text-align: center;
}
@media all and (max-width: 640px) {
  div.registerPage div.registerContainer {
    width: 95%;
  }
}
div.registerPage div.registerContainer div.top div.userIconContainer {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 10px;
}
div.registerPage div.registerContainer div.top div.userIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--white-color);
}
div.registerPage div.registerContainer div.top h1 {
  color: var(--white-color);
}
@media all and (max-width: 640px) {
  div.registerPage div.registerContainer div.top h1 {
    font-size: 25px;
  }
}
div.registerPage div.registerContainer div.top h6 {
  font-size: 15px;
  color: var(--grey-color);
  margin-bottom: 20px;
}
div.registerPage div.registerContainer div.top div.facebookContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.top div.facebookContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.top div.facebookContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3d6ac3;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.left {
  line-height: 0;
  margin-inline-end: 50px;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--white-color);
  line-height: 0;
}
div.registerPage div.registerContainer div.top div.facebookContainer div.right span {
  color: var(--white-color);
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.registerPage div.registerContainer div.top div.googleContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 8px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.registerPage div.registerContainer div.top div.googleContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.top div.googleContainer div.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  transition: 0.1s ease-in-out;
  border-radius: 8px;
}
div.registerPage div.registerContainer div.top div.googleContainer div.contents {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
div.registerPage div.registerContainer div.top div.googleContainer div.left {
  line-height: 0;
  margin-inline-end: 50px;
}
div.registerPage div.registerContainer div.top div.googleContainer div.left svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--text-color);
  line-height: 0;
}
div.registerPage div.registerContainer div.top div.googleContainer div.right span {
  color: #3c4043;
  font-weight: 700;
  line-height: 0;
  font-size: 15px;
}
div.registerPage div.registerContainer div.top div.seperate {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
div.registerPage div.registerContainer div.top div.seperate span {
  font-size: 12px;
  color: var(--white-color);
}
div.registerPage div.registerContainer div.top div.seperate span.line {
  height: 0.1px;
  width: 45%;
  background-color: var(--text-color);
}
div.registerPage div.registerContainer div.top div.seperate span.line:first-child {
  margin-inline-end: 8px;
}
div.registerPage div.registerContainer div.top div.seperate span.line:last-child {
  margin-inline-start: 8px;
}
div.registerPage div.registerContainer div.bottom {
  margin-top: 15px;
}
div.registerPage div.registerContainer div.bottom h3 {
  color: var(--white-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
div.registerPage div.registerContainer div.bottom form {
  margin-bottom: 20px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne {
  background-color: var(--white-color);
  width: 100%;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input {
  background: transparent;
  outline: none;
  border: none;
  text-align: start;
  width: 100%;
  padding: 17px 15px;
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByOne input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo {
  width: 100%;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo label {
  font-size: 12px;
  color: var(--white-color);
  text-align: start;
  display: block;
  font-weight: 600;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers {
  display: flex;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input {
  outline: none;
  border: none;
  text-align: start;
  width: 100%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-webkit-outer-spin-button,
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers input[type=number] {
  -moz-appearance: textfield;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.left {
  margin-inline-end: 10px;
  width: 70%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.left input[type=date] {
  padding: 17px 15px;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right {
  width: 30%;
  height: 100%;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer {
  background-color: var(--white-color);
  position: relative;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer svg {
  position: absolute;
  inset-inline-end: 5px;
  width: 15px !important;
  height: 15px !important;
  color: var(--text-color);
  top: 50%;
  transform: translateY(-50%);
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer select {
  padding: 17px 15px;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
div.registerPage div.registerContainer div.bottom form div.oneByTwo div.containers div.right div.genderContainer select option {
  padding: 17px 15px;
  background-color: var(--white-color);
  color: var(--black-color);
  border-bottom: 1px solid var(--border-color);
  position: relative;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer div.background {
  background: var(--button-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.registerPage div.registerContainer div.bottom form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 8;
  cursor: pointer;
}
div.registerPage div.registerContainer div.bottom form h4.forgotPassword {
  color: var(--white-color);
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
  cursor: pointer;
}
div.registerPage div.registerContainer div.bottom h4.signIn {
  font-size: 12px;
  color: #d5d5d6;
  font-weight: 400;
}
div.registerPage div.registerContainer div.bottom h4.signIn span {
  font-weight: 600;
  font-size: 15px;
  cursor: pointer;
}
div.registerPage div.registerContainer div.bottom p {
  font-size: 11px;
  color: var(--grey-color);
  line-height: 1.6em;
  margin-bottom: 10px;
}
div.registerPage div.registerContainer div.bottom p span {
  color: var(--white-color);
  cursor: pointer;
}

div.commonPage {
  background-color: var(--secondary-bg-color);
}
div.commonPage div.top.inside {
  position: absolute;
  top: 60px;
  z-index: 8;
  inset-inline-start: 60px;
}
@media all and (max-width: 640px) {
  div.commonPage div.top.inside {
    top: 50px;
    z-index: 8;
    inset-inline-start: 20px;
  }
}
div.commonPage div.top.inside h1.heading {
  text-align: start;
  color: var(--white-color);
}
div.commonPage div.top h1.heading {
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: var(--heading-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.commonPage div.top h1.heading {
    font-size: 22px;
    margin-top: 50px;
  }
}
div.commonPage div.top h1.heading::first-line {
  color: rgb(255, 234, 0);
}
div.commonPage div.top p.desc {
  font-size: 18px;
  color: var(--white-color);
}
@media all and (max-width: 640px) {
  div.commonPage div.top p.desc {
    font-size: 14px;
  }
}
div.commonPage div.bgImageContainer {
  width: 100%;
  display: block;
  height: 508px;
  position: relative;
}
@media all and (max-width: 768px) {
  div.commonPage div.bgImageContainer {
    height: 556px;
  }
}
div.commonPage div.bgImageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 768px) {
  div.commonPage div.bgImageContainer img {
    -o-object-fit: cover;
       object-fit: cover;
  }
}
div.commonPage div.bgImageContainer div.bannerArrow {
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: antiquewhite;
}
div.commonPage div.bottomGradient {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}
div.commonPage div.bottom {
  padding: 60px 20px 40px;
}
div.commonPage div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.commonPage div.bottom div.itemsContainer.portrait {
  margin-left: 8%;
  margin-right: 8%;
}
div.commonPage div.bottom div.itemsContainer.landscape {
  margin-left: 0;
  margin-right: 0;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount5 {
    width: 18.5%;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount5 div.showCardContainer div.metaData h1.title {
  font-size: 14px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
  width: 15.8%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15%;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.rowCount6 div.showCardContainer div.metaData h1.title {
  font-size: 14px;
}
div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount3 {
  width: 32.5%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount3:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount3 {
    width: 32%;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount4 {
  width: 24%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount4:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount4 {
    width: 23.8%;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount5 {
  width: 19%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount5:nth-child(5n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount5 {
    width: 18.5%;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount5 div.showCardContainer div.metaData h1.title {
  font-size: 14px;
}
div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount6 {
  width: 15.8%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount6:nth-child(6n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount6 {
    width: 15%;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.portrait.rowCount6 div.showCardContainer div.metaData h1.title {
  font-size: 14px;
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 23.5%;
    margin-inline-end: 13px;
    margin-bottom: 40px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 23%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 640px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 31%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.portrait {
    width: 47%;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount3 {
  width: 32.5%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount3:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount3 {
    width: 32%;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount4 {
  width: 24%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount4:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount4 {
    width: 23.8%;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount4 {
    width: 24%;
    margin-inline-end: 9px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount4:nth-child(4n) {
    margin-inline-end: 0 !important;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount4 {
    width: 24%;
    margin-inline-end: 8px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount4:nth-child(4n) {
    margin-inline-end: 0 !important;
  }
}
@media all and (max-width: 648px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount4 {
    width: 32%;
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount4:nth-child(4n) {
    margin-inline-end: 0px !important;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount4 {
    width: 48%;
    margin-inline-end: 5px;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5 {
  width: 19%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5:nth-child(5n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5 {
    width: 18.5%;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5 {
    width: 24%;
    margin-inline-end: 9px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5:nth-child(5n) {
    margin-inline-end: 9px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5:nth-child(4n) {
    margin-inline-end: 0 !important;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5 {
    width: 24%;
    margin-inline-end: 8px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5:nth-child(5n) {
    margin-inline-end: 8px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5:nth-child(4n) {
    margin-inline-end: 0 !important;
  }
}
@media all and (max-width: 648px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5 {
    width: 32%;
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5:nth-child(4n) {
    margin-inline-end: 6px !important;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5:nth-child(3n) {
    margin-inline-end: 0 !important;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5 {
    width: 48%;
    margin-inline-end: 5px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5:nth-child(5n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5:nth-child(4n) {
    margin-inline-end: 6px !important;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5:nth-child(3n) {
    margin-inline-end: 6px !important;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5:nth-child(2n) {
    margin-inline-end: 0 !important;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount5 div.showCardContainer div.metaData h1.title {
  font-size: 14px;
}
div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6 {
  width: 15.8%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6:nth-child(6n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6 {
    width: 15%;
  }
}
@media all and (max-width: 980px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6 {
    width: 24%;
    margin-inline-end: 9px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6:nth-child(6n) {
    margin-inline-end: 9px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6:nth-child(4n) {
    margin-inline-end: 0 !important;
  }
}
@media all and (max-width: 768px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6 {
    width: 24%;
    margin-inline-end: 8px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6:nth-child(6n) {
    margin-inline-end: 8px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6:nth-child(4n) {
    margin-inline-end: 0 !important;
  }
}
@media all and (max-width: 648px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6 {
    width: 32%;
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6:nth-child(4n) {
    margin-inline-end: 6px !important;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6:nth-child(3n) {
    margin-inline-end: 0 !important;
  }
}
@media all and (max-width: 480px) {
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6 {
    width: 48%;
    margin-inline-end: 5px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6:nth-child(6n) {
    margin-inline-end: 6px;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6:nth-child(4n) {
    margin-inline-end: 6px !important;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6:nth-child(3n) {
    margin-inline-end: 6px !important;
  }
  div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6:nth-child(2n) {
    margin-inline-end: 0 !important;
  }
}
div.commonPage div.bottom div.itemsContainer div.items.landscape.rowCount6 div.showCardContainer div.metaData h1.title {
  font-size: 14px;
}

div.failed {
  padding: 178px 0;
}
div.failed div.failedContainer {
  background-color: #fff;
  width: 25%;
  margin: 0 auto;
  position: relative;
  padding: 70px 20px 30px;
  text-align: center;
  border-radius: 8px;
}
@media all and (max-width: 1200px) {
  div.failed div.failedContainer {
    width: 31%;
  }
}
@media all and (max-width: 980px) {
  div.failed div.failedContainer {
    width: 41%;
  }
}
@media all and (max-width: 768px) {
  div.failed div.failedContainer {
    width: 45%;
  }
}
@media (max-width: 640px) {
  div.failed div.failedContainer {
    width: 52%;
  }
}
@media (max-width: 480px) {
  div.failed div.failedContainer {
    width: 68%;
  }
}
div.failed div.failedContainer h5 {
  color: #000;
  margin-bottom: 20px;
  font-size: 30px;
}
div.failed div.failedContainer p {
  color: #636363;
  font-size: 15px;
  margin-bottom: 40px;
}
div.failed div.failedContainer button {
  width: 100%;
  outline: none;
  border: 1px solid #e50914;
  background-color: #e50914;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.failed div.failedContainer button:hover {
  background-color: transparent;
  color: #000;
}
div.failed div.failedContainer div.crossIcon {
  text-align: center;
  position: absolute;
  top: -35px;
  right: 0;
  left: 0;
}
div.failed div.failedContainer div.crossIcon svg {
  padding: 20px;
  background-color: #e50914;
  border-radius: 50%;
}

/* div.successContainer {
    padding: 100px 0;
    background-color: #fff;
    width: 30%;
    margin: 0 auto;
} */
div.success {
  padding: 178px 0;
  background-color: var(--secondary-bg-color);
}
div.success div.successContainer {
  background-color: var(--bg-color);
  width: 25%;
  margin: 0 auto;
  position: relative;
  padding: 70px 20px 30px;
  text-align: center;
  border-radius: 8px;
}
@media all and (max-width: 1200px) {
  div.success div.successContainer {
    width: 31%;
  }
}
@media all and (max-width: 980px) {
  div.success div.successContainer {
    width: 41%;
  }
}
@media all and (max-width: 768px) {
  div.success div.successContainer {
    width: 45%;
  }
}
@media (max-width: 640px) {
  div.success div.successContainer {
    width: 52%;
  }
}
@media (max-width: 480px) {
  div.success div.successContainer {
    width: 68%;
  }
}
div.success div.successContainer h5 {
  color: #000;
  margin-bottom: 20px;
  font-size: 30px;
}
div.success div.successContainer p {
  color: #636363;
  font-size: 15px;
  margin-bottom: 40px;
}
div.success div.successContainer button {
  width: 100%;
  outline: none;
  border: 1px solid #28a745;
  background-color: #28a745;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.success div.successContainer button:hover {
  background-color: transparent;
  color: #000;
}
div.success div.successContainer div.checkIcon {
  text-align: center;
  position: absolute;
  top: -35px;
  right: 0;
  left: 0;
}
div.success div.successContainer div.checkIcon svg {
  padding: 20px;
  background-color: #28a745;
  border-radius: 50%;
}

div.forgotPassword {
  background: linear-gradient(90deg, rgb(30, 38, 170) 0%, rgb(0, 90, 137) 66%, rgb(106, 164, 185) 100%);
}
div.forgotPassword span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: start;
  display: block;
  margin-bottom: 10px;
}
div.forgotPassword div.forgotPasswordContainer {
  padding: 200px 0;
}
div.forgotPassword div.forgotPasswordContainer h1.heading {
  font-size: 34px;
  color: var(--white-color);
  text-align: center;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer h1.heading {
    font-size: 26px;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer {
  text-align: center;
  width: 35rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer {
    width: 25rem;
  }
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer {
    width: 100%;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input {
  background: transparent;
  outline: none;
  border: 1px solid var(--grey-color);
  text-align: start;
  width: 100%;
  padding: 20px 15px;
  caret-color: var(--white-color);
  color: var(--white-color);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input:focus {
  border: 1px solid var(--white-color);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input::-moz-placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form input::placeholder {
  font-size: 15px;
  color: rgb(186, 184, 184);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer {
    width: 10rem;
  }
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer div.background {
  background: var(--button-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.forgotPassword div.forgotPasswordContainer div.formContainer form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.resetPassword {
  background: linear-gradient(90deg, rgb(30, 38, 170) 0%, rgb(0, 90, 137) 66%, rgb(106, 164, 185) 100%);
  padding: 150px 0;
}
div.resetPassword span.error {
  color: #E72A31;
  font-size: 12px;
  text-align: start;
  display: block;
  margin-bottom: 10px;
}
div.resetPassword span.success {
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 10px;
}
div.resetPassword h1.heading {
  font-size: 34px;
  padding-bottom: 10px;
  text-align: center;
  color: var(--white-color);
}
@media all and (max-width: 640px) {
  div.resetPassword h1.heading {
    font-size: 22px;
    margin: 0 20px;
  }
}
div.resetPassword div.contents {
  margin-top: 40px;
}
@media all and (max-width: 480px) {
  div.resetPassword div.contents {
    padding: 0 10px;
  }
}
div.resetPassword div.contents div.formSection {
  width: 30%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.resetPassword div.contents div.formSection {
    width: 55%;
  }
}
@media all and (max-width: 980px) {
  div.resetPassword div.contents div.formSection {
    width: 75%;
  }
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection {
    width: 100%;
  }
}
div.resetPassword div.contents div.formSection form div.inputContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
div.resetPassword div.contents div.formSection form div.inputContainer:nth-child(3) {
  margin-bottom: 60px;
}
div.resetPassword div.contents div.formSection form div.inputContainer label {
  font-size: 16px;
  margin-bottom: 8px;
  color: var(--white-color);
}
div.resetPassword div.contents div.formSection form div.inputContainer input {
  outline: none;
  font-size: 14px;
  background: transparent;
  text-align: start;
  padding: 20px 15px;
  border: 1px solid var(--grey-color);
  caret-color: var(--white-color);
  color: var(--white-color);
  margin-bottom: 10px;
}
div.resetPassword div.contents div.formSection form div.inputContainer input:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection form div.inputContainer input {
    padding: 16px 15px;
  }
}
div.resetPassword div.contents div.formSection form div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.resetPassword div.contents div.formSection form div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.resetPassword div.contents div.formSection form div.buttonContainer {
    width: 10rem;
  }
}
div.resetPassword div.contents div.formSection form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.resetPassword div.contents div.formSection form div.buttonContainer div.background {
  background: var(--button-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.resetPassword div.contents div.formSection form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

header.accountsPageHeader {
  height: 100px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  width: 95%;
  justify-content: space-between;
  margin: 0 auto;
}
header.accountsPageHeader div.left div.logoContainer h1 {
  width: 120px;
}
@media all and (max-width: 480px) {
  header.accountsPageHeader div.left div.logoContainer h1 {
    width: 72px;
  }
}
@media all and (max-width: 360px) {
  header.accountsPageHeader div.left div.logoContainer h1 {
    width: 72px;
  }
}
header.accountsPageHeader div.left div.logoContainer h1 img {
  display: block;
  width: 100%;
}
header.accountsPageHeader div.right button {
  background: transparent;
  color: var(--white-color);
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}

div.otpModal div.overlayOtp {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 8;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.otpModal div.otpContainer {
  z-index: 99;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  /* Firefox */
}
@media all and (max-width: 1200px) {
  div.otpModal div.otpContainer {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.otpModal div.otpContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.otpModal div.otpContainer {
    width: 90%;
  }
}
div.otpModal div.otpContainer label {
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--text-color);
}
div.otpModal div.otpContainer button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.otpModal div.otpContainer input {
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: start;
  padding: 20px 15px;
  border: 1px solid var(--text-color);
  caret-color: var(--white-color);
  margin-bottom: 10px;
  color: var(--text-color);
}
div.otpModal div.otpContainer input:focus {
  border: 1px solid var(--button-hover);
}
@media all and (max-width: 640px) {
  div.otpModal div.otpContainer input {
    padding: 16px 15px;
  }
}
div.otpModal div.otpContainer input::-webkit-outer-spin-button,
div.otpModal div.otpContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.otpModal div.otpContainer input[type=number] {
  -moz-appearance: textfield;
}
div.otpModal div.otpContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.otpModal div.otpContainer div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.otpModal div.otpContainer div.buttonContainer {
    width: 10rem;
  }
}
div.otpModal div.otpContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.otpModal div.otpContainer div.buttonContainer div.background {
  background: var(--button-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in-out;
  z-index: 99;
}
div.otpModal div.otpContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 999;
  cursor: pointer;
}

div.continueWatching div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.continueWatching div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.continueWatching div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.continueWatching div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.continueWatching div.contents {
    width: 90%;
  }
}
div.continueWatching div.contents h1 {
  color: var(--text-color);
  font-size: 22px;
}
div.continueWatching div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.continueWatching div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.continueWatching div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-inline-end: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.continueWatching div.contents div.buttons div.buttonContainer:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.continueWatching div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.continueWatching div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.continueWatching div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.continueWatching div.contents div.buttons div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.continueWatching div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.currentPasswordModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.currentPasswordModal div.currentPasswordContainer {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--modal-bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  display: flex;
  flex-direction: column;
  /* Firefox */
}
@media all and (max-width: 1200px) {
  div.currentPasswordModal div.currentPasswordContainer {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.currentPasswordModal div.currentPasswordContainer {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.currentPasswordModal div.currentPasswordContainer {
    width: 90%;
  }
}
div.currentPasswordModal div.currentPasswordContainer label {
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--heading-color);
  text-transform: uppercase;
  font-weight: 700;
}
div.currentPasswordModal div.currentPasswordContainer button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.currentPasswordModal div.currentPasswordContainer input {
  outline: none;
  font-size: 14px;
  background: transparent;
  text-align: start;
  padding: 18px 15px;
  border: 1px solid var(--text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
}
div.currentPasswordModal div.currentPasswordContainer input:focus {
  border: 1px solid var(--button-hover);
}
@media all and (max-width: 640px) {
  div.currentPasswordModal div.currentPasswordContainer input {
    padding: 16px 15px;
  }
}
div.currentPasswordModal div.currentPasswordContainer input::-webkit-outer-spin-button,
div.currentPasswordModal div.currentPasswordContainer input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div.currentPasswordModal div.currentPasswordContainer input[type=number] {
  -moz-appearance: textfield;
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer {
  margin-top: 20px;
}
div.currentPasswordModal div.currentPasswordContainer div.buttonContainer button {
  background-color: var(--button-hover);
  padding: 10px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  width: 10rem;
}

div.watchWithoutAds div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.watchWithoutAds div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.watchWithoutAds div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.watchWithoutAds div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.watchWithoutAds div.contents {
    width: 90%;
  }
}
div.watchWithoutAds div.contents h1 {
  color: var(--text-color);
  font-size: 22px;
}
div.watchWithoutAds div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.watchWithoutAds div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-inline-end: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.watchWithoutAds div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.watchWithoutAds div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.watchWithoutAds div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.logoutAll div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.logoutAll div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 999;
}
div.logoutAll div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.logoutAll div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.logoutAll div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.logoutAll div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.logoutAll div.contents {
    width: 90%;
  }
}
div.logoutAll div.contents h1.error {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.logoutAll div.contents p {
  color: var(--white-color);
  font-size: 16px;
}
div.logoutAll div.contents h1 {
  color: var(--text-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.logoutAll div.contents h1 {
    font-size: 16px;
  }
}
div.logoutAll div.contents div.buttonContainer {
  margin-top: 20px;
}
div.logoutAll div.contents div.buttonContainer button {
  background-color: var(--button-hover);
  padding: 10px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  width: 10rem;
}

div.cancelSub div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.cancelSub div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 999;
}
div.cancelSub div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
  stroke: #E72A31 !important;
}
div.cancelSub div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.cancelSub div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.cancelSub div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.cancelSub div.contents {
    width: 90%;
  }
}
div.cancelSub div.contents h1.error {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.cancelSub div.contents p {
  color: var(--white-color);
  font-size: 16px;
}
div.cancelSub div.contents h1 {
  color: var(--text-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.cancelSub div.contents h1 {
    font-size: 16px;
  }
}
div.cancelSub div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.cancelSub div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.cancelSub div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-inline-end: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.cancelSub div.contents div.buttons div.buttonContainer:last-child {
  margin-inline-end: 0;
}
div.cancelSub div.contents div.buttons div.buttonContainer:last-child div.background {
  background: var(--error-color);
}
@media all and (max-width: 640px) {
  div.cancelSub div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.cancelSub div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.cancelSub div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.cancelSub div.contents div.buttons div.buttonContainer div.background {
  background: var(--success-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.cancelSub div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.loginRequest {
  background: linear-gradient(354deg, rgb(20, 20, 20) 0%, rgb(20, 20, 20) 38%, rgb(129, 17, 23) 100%);
  background-repeat: no-repeat;
  height: 100vh;
}

div.playNextEpisode div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.playNextEpisode div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.playNextEpisode div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.playNextEpisode div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.playNextEpisode div.contents {
    width: 90%;
  }
}
div.playNextEpisode div.contents h1 {
  color: var(--text-color);
  font-size: 22px;
}
div.playNextEpisode div.contents div.buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
div.playNextEpisode div.contents div.buttons button.bg-button {
  margin: 20px auto 0;
  width: 30%;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  margin-inline-end: 20px;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 10rem;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.playNextEpisode div.contents div.buttons div.buttonContainer {
    width: 15rem;
    padding: 10px 20px;
  }
}
@media all and (max-width: 480px) {
  div.playNextEpisode div.contents div.buttons div.buttonContainer {
    width: 10rem;
  }
}
div.playNextEpisode div.contents div.buttons div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.playNextEpisode div.contents div.buttons div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.playNextEpisode div.contents div.buttons div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.accountSettings {
  padding: 80px 5px;
  position: relative;
  display: flex;
  background-color: var(--secondary-bg-color);
}
@media all and (max-width: 768px) {
  div.accountSettings {
    flex-direction: column;
  }
}
div.accountSettings span.error {
  font-size: 14px;
  color: #e72a31;
  display: block;
  margin-top: 10px;
  font-weight: normal !important;
}
div.accountSettings div.leftContainer {
  width: 18%;
  position: relative;
  margin-inline-end: 20px;
}
@media all and (max-width: 1300px) {
  div.accountSettings div.leftContainer {
    width: 25%;
  }
}
@media all and (max-width: 980px) {
  div.accountSettings div.leftContainer {
    width: 31%;
  }
}
@media all and (max-width: 768px) {
  div.accountSettings div.leftContainer {
    width: 60%;
    margin: 0 auto;
  }
}
div.accountSettings div.leftContainer ul.tabs {
  position: fixed;
}
@media all and (max-width: 768px) {
  div.accountSettings div.leftContainer ul.tabs {
    position: unset;
  }
}
div.accountSettings div.leftContainer ul.tabs li {
  font-size: 13px;
  color: var(--text-color);
  font-weight: 700;
  padding-bottom: 10px;
  border-bottom: 1px solid #c8c8c8;
  margin-bottom: 10px;
  cursor: pointer;
}
div.accountSettings div.leftContainer ul.tabs li.active {
  color: var(--button-hover);
}
div.accountSettings div.rightContainer {
  width: 80%;
}
@media all and (max-width: 1300px) {
  div.accountSettings div.rightContainer {
    width: 70%;
  }
}
@media all and (max-width: 980px) {
  div.accountSettings div.rightContainer {
    width: 65%;
  }
}
@media all and (max-width: 768px) {
  div.accountSettings div.rightContainer {
    width: 90%;
    margin: 0 auto;
  }
}
div.accountSettings div.rightContainer div.top {
  background-color: var(--white-color);
  padding: 8px 15px;
  margin-bottom: 10px;
}
div.accountSettings div.rightContainer div.top h1 {
  color: var(--text-color);
  font-size: 17px;
}
div.accountSettings div.rightContainer div.bottom {
  background-color: var(--white-color);
  padding: 30px 25px;
}
div.accountSettings div.rightContainer div.bottom div.profileSection {
  padding-bottom: 40px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer {
  width: 200px;
  height: 200px;
  margin: 0 auto;
  text-align: center;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer input {
  display: none;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer label {
  font-size: 13px;
  color: var(--text-color);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
}
div.accountSettings div.rightContainer div.bottom div.profileSection div.imageContainer label:hover {
  color: var(--button-hover);
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row {
  padding-bottom: 25px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row h1.heading {
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField {
    width: 100%;
  }
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField.noMargin {
  margin-bottom: 0;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField label {
  font-size: 12px;
  color: var(--text-color);
  margin-bottom: 10px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField input {
  width: 100%;
  background-color: transparent;
  outline: none;
  border: 1px solid #c8c8c8;
  padding: 10px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField input:focus {
  border: 2px solid var(--black-color);
  border-radius: 5px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField select {
  width: 100%;
  background-color: transparent;
  outline: none;
  border: 1px solid #c8c8c8;
  padding: 10px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.inputField select:focus {
  border: 2px solid var(--black-color);
  border-radius: 5px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.newsletterSubscribe {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.newsletterSubscribe input {
  margin-inline-end: 6px;
  width: 15px;
  height: 15px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.newsletterSubscribe label {
  color: var(--text-color);
  font-size: 13px;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.buttonContainer button {
  background-color: var(--button-hover);
  padding: 8px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
}
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.buttonContainer button:disabled,
div.accountSettings div.rightContainer div.bottom div.contactDetails div.row div.buttonContainer button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: var(--text-color);
  cursor: default;
}
div.accountSettings div.rightContainer div.planDetails {
  background-color: var(--white-color);
  padding: 30px 25px;
}
div.accountSettings div.rightContainer div.planDetails h1.heading {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.planDetails h2.planName {
  font-size: 12px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.planDetails div.buttonContainer {
  margin-bottom: 20px;
}
div.accountSettings div.rightContainer div.planDetails div.buttonContainer button {
  background-color: var(--button-hover);
  padding: 8px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
}
div.accountSettings div.rightContainer div.planDetails span.link {
  display: block;
  font-size: 12px;
  color: var(--button-hover);
  font-weight: 700;
  margin-bottom: 10px;
  cursor: pointer;
}
div.accountSettings div.rightContainer div.streamingActivity {
  background-color: var(--white-color);
  padding: 30px 25px;
}
@media all and (max-width: 1200px) {
  div.accountSettings div.rightContainer div.streamingActivity {
    width: 100%;
  }
}
div.accountSettings div.rightContainer div.streamingActivity p {
  font-size: 18px;
  color: var(--text-color);
  margin-bottom: 30px;
}
div.accountSettings div.rightContainer div.streamingActivity ul.recentDeviceContainer li {
  border-bottom: 1px dashed var(--heading-color);
  padding-bottom: 30px;
  margin-bottom: 30px;
}
div.accountSettings div.rightContainer div.streamingActivity ul.recentDeviceContainer li span {
  color: var(--text-color);
  display: block;
  font-size: 14px;
}
div.accountSettings div.rightContainer div.streamingActivity ul.recentDeviceContainer li span:first-child {
  margin-bottom: 10px;
}
div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer {
    width: 10rem;
  }
}
div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.accountSettings div.rightContainer div.streamingActivity div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.billingAndSubscription {
  padding: 80px 0;
  background-color: var(--grey-color);
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription {
    width: 100%;
  }
}
div.billingAndSubscription h1.heading {
  font-size: 24px;
  color: var(--heading-color);
  margin-bottom: 30px;
}
div.billingAndSubscription div.billingDetails {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.billingDetails {
    width: 90%;
  }
}
div.billingAndSubscription div.billingDetails h6.subHeading {
  font-size: 14px;
  color: var(--text-color);
  font-weight: normal;
  letter-spacing: 0.3px;
  margin-bottom: 20px;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer {
  border: 3px solid #c8c8c8;
  padding: 20px;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer span.head {
  display: block;
  color: var(--text-color);
  margin-bottom: 10px;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer span.value {
  display: block;
  color: var(--text-color);
  font-size: 14px;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer span.value.bold {
  font-weight: 600;
  margin-bottom: 20px;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer .billingButtonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  max-width: 20rem;
  margin: 30px auto 0;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer .billingButtonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer .billingButtonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.billingAndSubscription div.billingDetails div.billingDetailsContainer .billingButtonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--text-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.billingAndSubscription div.subscriptionDetails {
  width: 80%;
  margin: 0 auto;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.subscriptionDetails {
    width: 90%;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li.head {
  display: flex;
  color: var(--heading-color);
  font-size: 14px;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li.head {
    display: none;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li {
  border-bottom: 1px solid #c8c8c8;
  display: flex;
  padding: 6px;
  color: var(--text-color);
  font-size: 14px;
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left {
  display: flex;
  width: 80%;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left {
    flex-direction: column;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.date {
  width: 17%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.date {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.transactionType {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.transactionType {
    width: 100%;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.servicePeriod {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.servicePeriod {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.paymentMethod {
  width: 25%;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.paymentMethod {
    width: 100%;
    margin-bottom: 10px;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
  width: 25%;
}
@media all and (max-width: 980px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
    width: 30%;
  }
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.left div.subscriptionName {
    width: 100%;
  }
}
div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.right {
  width: 20%;
  text-align: end;
}
@media all and (max-width: 980px) {
  div.billingAndSubscription div.subscriptionDetails ul.subscriptionContainer li div.right {
    width: 8%;
  }
}
div.billingAndSubscription div.bottom {
  width: 80%;
  margin: 30px auto 0;
}
@media all and (max-width: 1200px) {
  div.billingAndSubscription div.bottom {
    width: 90%;
  }
}
div.billingAndSubscription div.bottom p {
  color: var(--text-color);
  font-size: 12px;
}
div.billingAndSubscription div.bottom div.buttonContainer {
  margin-top: 20px;
  text-align: center;
}
div.billingAndSubscription div.bottom div.buttonContainer button {
  background-color: var(--button-hover);
  padding: 8px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  width: 20rem;
}
@media all and (max-width: 768px) {
  div.billingAndSubscription div.bottom div.buttonContainer button {
    width: 100%;
  }
}

div.liveSection {
  width: 100%;
  margin-top: 30px;
}
div.liveSection h1.heading {
  font-size: 28px;
  color: var(--black-color);
  margin-bottom: 10px;
  margin-inline-start: 10px;
}
div.liveSection div.videoContainer {
  width: 100%;
  position: relative;
}
div.liveSection div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (min-width: 768px) {
  div.liveSection div.videoContainer:hover div.bottom div.details {
    transform: translateY(0);
  }
}
div.liveSection div.videoContainer div.bottom {
  width: 90%;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  overflow: hidden;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom {
    position: relative;
    bottom: unset;
    inset-inline-start: unset;
    transform: unset;
    width: 100%;
    height: auto;
  }
}
div.liveSection div.videoContainer div.bottom div.details {
  display: flex;
  justify-content: space-between;
  height: 100%;
  transform: translateY(200px);
  transition: 0.3s ease-in-out;
  background-color: rgba(41, 36, 35, 0.6);
  padding: 20px;
  border-radius: 8px;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details {
    flex-direction: column;
    transform: unset;
  }
}
div.liveSection div.videoContainer div.bottom div.details h1.heading {
  font-size: 28px;
  color: var(--white-color);
  margin-bottom: 10px;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details h1.heading {
    font-size: 22px;
  }
}
div.liveSection div.videoContainer div.bottom div.details h1.title {
  font-size: 20px;
  color: var(--text-hover);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.liveSection div.videoContainer div.bottom div.details span.time {
  font-size: 14px;
  color: var(--text-color);
  display: inline-block;
  margin-bottom: 10px;
}
div.liveSection div.videoContainer div.bottom div.details p.description {
  color: var(--white-color);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
div.liveSection div.videoContainer div.bottom div.details div.left {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.liveSection div.videoContainer div.bottom div.details div.right {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.liveSection div.videoContainer div.bottom div.details div.right {
    width: 100%;
  }
}

div.showsRow {
  padding: 40px 60px;
  position: relative;
  z-index: 3;
}
@media all and (max-width: 768px) {
  div.showsRow {
    padding: 30px 30px;
  }
}
div.showsRow h1.heading {
  font-size: 25px;
  color: var(--text-color);
  margin-bottom: 20px;
}
div.showsRow div.seasonContainer {
  border: 2px solid var(--white-color);
  border-radius: 8px;
  width: 15rem;
  margin-bottom: 20px;
  margin-left: 5px;
  background: transparent;
  padding: 15px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  color: var(--text-color);
  cursor: pointer;
}
@media all and (max-width: 480px) {
  div.showsRow div.seasonContainer {
    width: 100%;
  }
}
div.showsRow div.seasonContainer svg {
  width: 26px !important;
  height: 26px !important;
  transition: 0.3s ease-in-out;
}
div.showsRow div.seasonContainer svg.active {
  transform: rotate(-180deg);
}
div.showsRow div.seasonContainer.zIndexController div.listContainer {
  z-index: 9;
}
div.showsRow div.seasonContainer div.listContainer {
  height: 200px;
  overflow-y: scroll;
  position: absolute;
  top: 55px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: -1;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  cursor: pointer;
}
div.showsRow div.seasonContainer div.listContainer::-webkit-scrollbar {
  display: none;
}
div.showsRow div.seasonContainer div.listContainer.scroll {
  -ms-overflow-style: unset; /* IE and Edge */
  scrollbar-width: unset; /* Firefox */
}
div.showsRow div.seasonContainer div.listContainer.scroll::-webkit-scrollbar {
  display: block;
}
div.showsRow div.seasonContainer div.listContainer.scroll::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.showsRow div.seasonContainer div.listContainer.scroll::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px;
}
div.showsRow div.seasonContainer div.listContainer.scroll::-webkit-scrollbar-track {
  background-color: var(--grey-color);
}
div.showsRow div.seasonContainer div.list {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  display: none;
}
div.showsRow div.seasonContainer div.list.active {
  display: block;
}
div.showsRow div.seasonContainer div.list div.item {
  width: 100%;
  padding: 8px 10px;
  border-bottom: 1px solid var(--grey-color);
  background-color: var(--bg-color);
}
div.showsRow div.seasonContainer div.list div.item:hover {
  border-color: var(--white-color);
  background-color: var(--secondary-bg-color);
  color: var(--white-color);
}
div.showsRow div.seasonContainer div.list div.item:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
div.showsRow div.seasonContainer div.list div.item span {
  font-size: 16px;
  color: var(--text-color);
}
div.showsRow div.seasonContainer:hover {
  border-color: var(--white-color);
  background-color: #cac6c6;
  color: var(--white-color);
}
div.showsRow .portrait.rowCount4 .swiper-button-next {
  right: 10px;
  left: auto;
  bottom: 80px;
  margin-top: 15%;
}
@media all and (max-width: 2600px) {
  div.showsRow .portrait.rowCount4 .swiper-button-next {
    margin-top: 17%;
  }
}
@media all and (max-width: 2200px) {
  div.showsRow .portrait.rowCount4 .swiper-button-next {
    margin-top: 16%;
  }
}
@media all and (max-width: 768px) {
  div.showsRow .portrait.rowCount4 .swiper-button-next {
    margin-top: 7%;
  }
}
@media all and (max-width: 640px) {
  div.showsRow .portrait.rowCount4 .swiper-button-next {
    margin-top: 11%;
  }
}
@media all and (max-width: 480px) {
  div.showsRow .portrait.rowCount4 .swiper-button-next {
    margin-top: 17%;
  }
}
@media all and (max-width: 360px) {
  div.showsRow .portrait.rowCount4 .swiper-button-next {
    margin-top: 26%;
  }
}
div.showsRow .portrait.rowCount4 .swiper-button-prev {
  margin-top: 60px;
  left: -7%;
  right: 100px;
  margin-top: 15%;
}
@media all and (max-width: 2600px) {
  div.showsRow .portrait.rowCount4 .swiper-button-prev {
    margin-top: 17%;
    margin-left: 3%;
  }
}
@media all and (max-width: 2200px) {
  div.showsRow .portrait.rowCount4 .swiper-button-prev {
    margin-top: 16%;
    margin-left: 2.5%;
  }
}
@media all and (max-width: 1980px) {
  div.showsRow .portrait.rowCount4 .swiper-button-prev {
    margin-left: 2%;
  }
}
@media all and (max-width: 1768px) {
  div.showsRow .portrait.rowCount4 .swiper-button-prev {
    margin-left: 0%;
  }
}
@media all and (max-width: 1200px) {
  div.showsRow .portrait.rowCount4 .swiper-button-prev {
    margin-left: -1%;
  }
}
@media all and (max-width: 980px) {
  div.showsRow .portrait.rowCount4 .swiper-button-prev {
    margin-left: -3%;
  }
}
@media all and (max-width: 768px) {
  div.showsRow .portrait.rowCount4 .swiper-button-prev {
    margin-left: -7%;
    margin-top: 7%;
  }
}
@media all and (max-width: 640px) {
  div.showsRow .portrait.rowCount4 .swiper-button-prev {
    margin-left: -10%;
    margin-top: 11%;
  }
}
@media all and (max-width: 480px) {
  div.showsRow .portrait.rowCount4 .swiper-button-prev {
    margin-left: -16%;
    margin-top: 17%;
  }
}
@media all and (max-width: 360px) {
  div.showsRow .portrait.rowCount4 .swiper-button-prev {
    margin-left: -22%;
    margin-top: 26%;
  }
}
div.showsRow .portrait.rowCount5 .swiper-button-next {
  right: 10px;
  left: auto;
  margin-top: 12%;
}
@media all and (max-width: 2600px) {
  div.showsRow .portrait.rowCount5 .swiper-button-next {
    margin-top: 14%;
  }
}
@media all and (max-width: 2200px) {
  div.showsRow .portrait.rowCount5 .swiper-button-next {
    margin-top: 13%;
  }
}
@media all and (max-width: 1980px) {
  div.showsRow .portrait.rowCount5 .swiper-button-next {
    margin-top: 12%;
  }
}
@media all and (max-width: 1400px) {
  div.showsRow .portrait.rowCount5 .swiper-button-next {
    margin-top: 12%;
  }
}
@media all and (max-width: 1200px) {
  div.showsRow .portrait.rowCount5 .swiper-button-next {
    margin-top: 11%;
  }
}
@media all and (max-width: 980px) {
  div.showsRow .portrait.rowCount5 .swiper-button-next {
    margin-top: 10%;
  }
}
@media all and (max-width: 768px) {
  div.showsRow .portrait.rowCount5 .swiper-button-next {
    margin-top: 7%;
  }
}
@media all and (max-width: 640px) {
  div.showsRow .portrait.rowCount5 .swiper-button-next {
    margin-top: 12%;
  }
}
@media all and (max-width: 480px) {
  div.showsRow .portrait.rowCount5 .swiper-button-next {
    margin-top: 15%;
  }
}
@media all and (max-width: 360px) {
  div.showsRow .portrait.rowCount5 .swiper-button-next {
    margin-top: 26%;
  }
}
div.showsRow .portrait.rowCount5 .swiper-button-prev {
  margin-top: 12%;
  left: -7%;
  right: 100px;
  bottom: 60px;
}
@media all and (max-width: 2600px) {
  div.showsRow .portrait.rowCount5 .swiper-button-prev {
    margin-top: 14%;
    margin-left: 3%;
  }
}
@media all and (max-width: 2200px) {
  div.showsRow .portrait.rowCount5 .swiper-button-prev {
    margin-top: 13%;
    margin-left: 2%;
  }
}
@media all and (max-width: 1980px) {
  div.showsRow .portrait.rowCount5 .swiper-button-prev {
    margin-top: 12%;
    margin-left: 2%;
  }
}
@media all and (max-width: 1768px) {
  div.showsRow .portrait.rowCount5 .swiper-button-prev {
    margin-left: 1%;
  }
}
@media all and (max-width: 1600px) {
  div.showsRow .portrait.rowCount5 .swiper-button-prev {
    margin-left: 0%;
  }
}
@media all and (max-width: 1400px) {
  div.showsRow .portrait.rowCount5 .swiper-button-prev {
    margin-top: 12%;
  }
}
@media all and (max-width: 1200px) {
  div.showsRow .portrait.rowCount5 .swiper-button-prev {
    margin-top: 11%;
    margin-left: -2%;
  }
}
@media all and (max-width: 980px) {
  div.showsRow .portrait.rowCount5 .swiper-button-prev {
    margin-top: 10%;
    margin-left: -4%;
  }
}
@media all and (max-width: 768px) {
  div.showsRow .portrait.rowCount5 .swiper-button-prev {
    margin-left: -7%;
    margin-top: 7%;
  }
}
@media all and (max-width: 640px) {
  div.showsRow .portrait.rowCount5 .swiper-button-prev {
    margin-top: 12%;
    margin-left: -10%;
  }
}
@media all and (max-width: 480px) {
  div.showsRow .portrait.rowCount5 .swiper-button-prev {
    margin-top: 15%;
    margin-left: -15%;
  }
}
@media all and (max-width: 360px) {
  div.showsRow .portrait.rowCount5 .swiper-button-prev {
    margin-top: 26%;
    margin-left: -22%;
  }
}
div.showsRow .portrait.rowCount6 .swiper-button-next {
  right: 10px;
  left: auto;
  bottom: 80px;
  margin-top: 15%;
}
@media all and (max-width: 2600px) {
  div.showsRow .portrait.rowCount6 .swiper-button-next {
    margin-top: 11%;
  }
}
@media all and (max-width: 1980px) {
  div.showsRow .portrait.rowCount6 .swiper-button-next {
    margin-top: 10%;
  }
}
@media all and (max-width: 1200px) {
  div.showsRow .portrait.rowCount6 .swiper-button-next {
    margin-top: 9%;
    margin-right: -0.5%;
  }
}
@media all and (max-width: 980px) {
  div.showsRow .portrait.rowCount6 .swiper-button-next {
    margin-top: 8%;
    margin-right: -1%;
  }
}
@media all and (max-width: 768px) {
  div.showsRow .portrait.rowCount6 .swiper-button-next {
    margin-top: 7%;
  }
}
@media all and (max-width: 640px) {
  div.showsRow .portrait.rowCount6 .swiper-button-next {
    margin-top: 11%;
  }
}
@media all and (max-width: 480px) {
  div.showsRow .portrait.rowCount6 .swiper-button-next {
    margin-top: 16%;
  }
}
@media all and (max-width: 360px) {
  div.showsRow .portrait.rowCount6 .swiper-button-next {
    margin-top: 25%;
  }
}
div.showsRow .portrait.rowCount6 .swiper-button-prev {
  margin-top: 60px;
  left: -7%;
  right: 100px;
  margin-top: 15%;
}
@media all and (max-width: 2600px) {
  div.showsRow .portrait.rowCount6 .swiper-button-prev {
    margin-top: 11%;
    margin-left: 3%;
  }
}
@media all and (max-width: 2200px) {
  div.showsRow .portrait.rowCount6 .swiper-button-prev {
    margin-left: 2.5%;
  }
}
@media all and (max-width: 1980px) {
  div.showsRow .portrait.rowCount6 .swiper-button-prev {
    margin-top: 10%;
    margin-left: 2%;
  }
}
@media all and (max-width: 1768px) {
  div.showsRow .portrait.rowCount6 .swiper-button-prev {
    margin-left: 1%;
  }
}
@media all and (max-width: 1600px) {
  div.showsRow .portrait.rowCount6 .swiper-button-prev {
    margin-left: 0.5%;
  }
}
@media all and (max-width: 1400px) {
  div.showsRow .portrait.rowCount6 .swiper-button-prev {
    margin-left: -0.5%;
  }
}
@media all and (max-width: 1200px) {
  div.showsRow .portrait.rowCount6 .swiper-button-prev {
    margin-top: 9%;
    margin-left: -2%;
  }
}
@media all and (max-width: 980px) {
  div.showsRow .portrait.rowCount6 .swiper-button-prev {
    margin-top: 8%;
    margin-left: -4%;
  }
}
@media all and (max-width: 768px) {
  div.showsRow .portrait.rowCount6 .swiper-button-prev {
    margin-top: 7%;
    margin-left: -7%;
  }
}
@media all and (max-width: 640px) {
  div.showsRow .portrait.rowCount6 .swiper-button-prev {
    margin-top: 11%;
    margin-left: -9%;
  }
}
@media all and (max-width: 480px) {
  div.showsRow .portrait.rowCount6 .swiper-button-prev {
    margin-top: 16%;
    margin-left: -15%;
  }
}
@media all and (max-width: 360px) {
  div.showsRow .portrait.rowCount6 .swiper-button-prev {
    margin-top: 26%;
    margin-left: -22%;
  }
}
div.showsRow .landscape.rowCount4 .swiper-button-next {
  right: 10px;
  left: auto;
  bottom: 80px;
  margin-top: 60px;
}
@media all and (max-width: 2600px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 125px;
  }
}
@media all and (max-width: 2500px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 125px;
  }
}
@media all and (max-width: 2200px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 110px;
  }
}
@media all and (max-width: 1980px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 90px;
  }
}
@media all and (max-width: 1768px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 71px;
  }
}
@media all and (max-width: 1600px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 65px;
  }
}
@media all and (max-width: 1400px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 50px;
  }
}
@media all and (max-width: 1200px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 38px;
  }
}
@media all and (max-width: 980px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 23px;
  }
}
@media all and (max-width: 768px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 10px;
  }
}
@media all and (max-width: 640px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 0px;
  }
}
@media all and (max-width: 480px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 20px;
  }
}
@media all and (max-width: 360px) {
  div.showsRow .landscape.rowCount4 .swiper-button-next {
    margin-top: 60px;
  }
}
div.showsRow .landscape.rowCount4 .swiper-button-prev {
  margin-top: 60px;
  left: 10px;
  right: 100px;
  bottom: 60px;
}
@media all and (max-width: 2600px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 140px;
    margin-left: -110px;
  }
}
@media all and (max-width: 2500px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 125px;
  }
}
@media all and (max-width: 2200px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 110px;
  }
}
@media all and (max-width: 1980px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 90px;
  }
}
@media all and (max-width: 1768px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 75px;
  }
}
@media all and (max-width: 1600px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 71px;
  }
}
@media all and (max-width: 1400px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 55px;
  }
}
@media all and (max-width: 1200px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 40px;
  }
}
@media all and (max-width: 980px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 18px;
  }
}
@media all and (max-width: 768px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 10px;
  }
}
@media all and (max-width: 640px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 0px;
  }
}
@media all and (max-width: 480px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 20px;
  }
}
@media all and (max-width: 360px) {
  div.showsRow .landscape.rowCount4 .swiper-button-prev {
    margin-top: 60px;
  }
}
div.showsRow .landscape.rowCount5 .swiper-button-next {
  right: 10px;
  left: auto;
  margin-top: 3%;
}
@media all and (max-width: 2600px) {
  div.showsRow .landscape.rowCount5 .swiper-button-next {
    margin-top: 4%;
  }
}
@media all and (max-width: 1980px) {
  div.showsRow .landscape.rowCount5 .swiper-button-next {
    margin-top: 3%;
  }
}
@media all and (max-width: 1400px) {
  div.showsRow .landscape.rowCount5 .swiper-button-next {
    margin-top: 2.5%;
  }
}
@media all and (max-width: 1200px) {
  div.showsRow .landscape.rowCount5 .swiper-button-next {
    margin-top: 2%;
  }
}
@media all and (max-width: 980px) {
  div.showsRow .landscape.rowCount5 .swiper-button-next {
    margin-top: 1%;
  }
}
@media all and (max-width: 768px) {
  div.showsRow .landscape.rowCount5 .swiper-button-next {
    margin-top: 4%;
  }
}
@media all and (max-width: 640px) {
  div.showsRow .landscape.rowCount5 .swiper-button-next {
    margin-top: 0%;
  }
}
@media all and (max-width: 480px) {
  div.showsRow .landscape.rowCount5 .swiper-button-next {
    margin-top: 5%;
  }
}
@media all and (max-width: 360px) {
  div.showsRow .landscape.rowCount5 .swiper-button-next {
    margin-top: 16%;
  }
}
div.showsRow .landscape.rowCount5 .swiper-button-prev {
  margin-top: 3%;
  left: -7%;
  right: 100px;
  bottom: 60px;
}
@media all and (max-width: 2600px) {
  div.showsRow .landscape.rowCount5 .swiper-button-prev {
    margin-top: 4%;
    margin-left: 3%;
  }
}
@media all and (max-width: 1980px) {
  div.showsRow .landscape.rowCount5 .swiper-button-prev {
    margin-top: 3%;
    margin-left: 2%;
  }
}
@media all and (max-width: 1768px) {
  div.showsRow .landscape.rowCount5 .swiper-button-prev {
    margin-left: 1%;
  }
}
@media all and (max-width: 1400px) {
  div.showsRow .landscape.rowCount5 .swiper-button-prev {
    margin-left: 0%;
    margin-top: 2.5%;
  }
}
@media all and (max-width: 1200px) {
  div.showsRow .landscape.rowCount5 .swiper-button-prev {
    margin-top: 2%;
    margin-left: -2%;
  }
}
@media all and (max-width: 980px) {
  div.showsRow .landscape.rowCount5 .swiper-button-prev {
    margin-top: 1%;
    margin-left: -4%;
  }
}
@media all and (max-width: 768px) {
  div.showsRow .landscape.rowCount5 .swiper-button-prev {
    margin-left: -7%;
    margin-top: 4%;
  }
}
@media all and (max-width: 640px) {
  div.showsRow .landscape.rowCount5 .swiper-button-prev {
    margin-top: 0%;
    margin-left: -10%;
  }
}
@media all and (max-width: 480px) {
  div.showsRow .landscape.rowCount5 .swiper-button-prev {
    margin-top: 5%;
    margin-left: -15%;
  }
}
@media all and (max-width: 360px) {
  div.showsRow .landscape.rowCount5 .swiper-button-prev {
    margin-top: 16%;
    margin-left: -22%;
  }
}
div.showsRow .landscape.rowCount6 .swiper-button-next {
  right: 10px;
  left: auto;
  bottom: 80px;
  margin-top: 2%;
}
@media all and (max-width: 2600px) {
  div.showsRow .landscape.rowCount6 .swiper-button-next {
    margin-top: 3%;
  }
}
@media all and (max-width: 1768px) {
  div.showsRow .landscape.rowCount6 .swiper-button-next {
    margin-top: 2%;
  }
}
@media all and (max-width: 1200px) {
  div.showsRow .landscape.rowCount6 .swiper-button-next {
    margin-top: 1%;
  }
}
@media all and (max-width: 980px) {
  div.showsRow .landscape.rowCount6 .swiper-button-next {
    margin-top: 0%;
  }
}
@media all and (max-width: 768px) {
  div.showsRow .landscape.rowCount6 .swiper-button-next {
    margin-top: 3%;
  }
}
@media all and (max-width: 640px) {
  div.showsRow .landscape.rowCount6 .swiper-button-next {
    margin-top: 5%;
    margin-left: -10%;
  }
}
@media all and (max-width: 360px) {
  div.showsRow .landscape.rowCount6 .swiper-button-next {
    margin-top: 14%;
    margin-left: -22%;
  }
}
div.showsRow .landscape.rowCount6 .swiper-button-prev {
  margin-top: 2%;
  left: -7%;
  right: 100px;
  bottom: 60px;
}
@media all and (max-width: 2600px) {
  div.showsRow .landscape.rowCount6 .swiper-button-prev {
    margin-top: 3%;
    margin-left: 3%;
  }
}
@media all and (max-width: 1768px) {
  div.showsRow .landscape.rowCount6 .swiper-button-prev {
    margin-top: 2%;
  }
}
@media all and (max-width: 1600px) {
  div.showsRow .landscape.rowCount6 .swiper-button-prev {
    margin-left: -1%;
  }
}
@media all and (max-width: 1200px) {
  div.showsRow .landscape.rowCount6 .swiper-button-prev {
    margin-left: -2%;
    margin-top: 1%;
  }
}
@media all and (max-width: 980px) {
  div.showsRow .landscape.rowCount6 .swiper-button-prev {
    margin-top: 0%;
  }
}
@media all and (max-width: 768px) {
  div.showsRow .landscape.rowCount6 .swiper-button-prev {
    margin-top: 3%;
    margin-left: -7%;
  }
}
@media all and (max-width: 640px) {
  div.showsRow .landscape.rowCount6 .swiper-button-prev {
    margin-top: 5%;
    margin-left: -15%;
  }
}
@media all and (max-width: 360px) {
  div.showsRow .landscape.rowCount6 .swiper-button-prev {
    margin-top: 14%;
  }
}

div.showsRow.ltr .portrait {
  direction: ltr;
  text-align: left;
}
div.showsRow.ltr .landscape {
  direction: ltr;
  text-align: left;
}

div.subcriptionPage {
  padding: 150px 5px;
  background-color: var(--secondary-bg-color);
}
div.subcriptionPage h1.heading {
  text-align: center;
  font-size: 34px;
  color: var(--white-color);
}
div.subcriptionPage div.subscriptionContainer {
  width: 90%;
  margin: 30px auto 0;
  overflow-x: scroll;
}
div.subcriptionPage div.subscriptionContainer table {
  width: 100%;
  border-collapse: collapse;
}
div.subcriptionPage div.subscriptionContainer table thead tr th {
  width: 80%;
}
div.subcriptionPage div.subscriptionContainer table thead tr th button {
  display: inline-block;
  padding: 10px 25px;
  border-radius: 30px;
  color: var(--white-color);
  cursor: pointer;
}
div.subcriptionPage div.subscriptionContainer table thead tr th button.active {
  background-color: var(--text-hover);
}
div.subcriptionPage div.subscriptionContainer table thead tr th button.no-active {
  background-color: var(--text-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr {
  border-bottom: 1px solid var(--border-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td {
  padding: 10px 0;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.big span {
  color: var(--white-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span {
  display: block;
  text-align: center;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.no-active {
  color: var(--text-color);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.no-active svg {
  stroke: var(--text-color) !important;
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.active {
  color: var(--text-hover);
}
div.subcriptionPage div.subscriptionContainer table tbody tr td.value span.active svg {
  stroke: var(--text-hover) !important;
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 50px auto 0;
}
@media all and (max-width: 640px) {
  div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer {
    width: 10rem;
  }
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.subcriptionPage div.subscriptionContainer div.buyNowButtonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.subscriptionList {
  padding: 100px 5px;
  background-color: var(--sub-bg-color);
}
div.subscriptionList h1.heading {
  font-size: 28px;
  text-align: center;
  color: var(--sub-heading-color);
}
div.subscriptionList div.wrapper {
  width: 90%;
}
div.subscriptionList div.subscriptionDetails {
  border: 1px solid var(--border-color);
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
  padding: 15px 28px;
  margin-top: 50px;
  color: var(--text-color);
}
div.subscriptionList div.subscriptionDetails h2.heading {
  font-size: 22px;
  color: var(--sub-heading-color);
  text-align: center;
}
div.subscriptionList div.subscriptionDetails ul.conditions {
  list-style: square;
  margin-top: 30px;
}
div.subscriptionList div.subscriptionDetails ul.conditions li {
  line-height: 28px;
}
div.subscriptionList div.subscriptionDetails ul.conditions li span {
  font-size: 16px;
}
div.subscriptionList div.pricing {
  margin-top: 30px;
}
div.subscriptionList div.pricing ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
}
div.subscriptionList div.pricing ul li {
  margin-inline-end: 20px;
  margin-bottom: 30px;
  padding: 60px 60px;
  border: 1px solid var(--border-color);
  width: 20rem;
  text-align: center;
  box-shadow: rgba(255, 255, 255, 0.24) 0px 3px 8px;
}
div.subscriptionList div.pricing ul li:last-child {
  margin-inline-end: 0;
}
div.subscriptionList div.pricing ul li button {
  display: block;
  border: none;
  outline: none;
  padding: 10px 25px;
  background-color: transparent;
  border: 1px solid var(--border-color);
  margin: 0 auto 20px;
  color: #fff;
  border-radius: 30px;
  transition: 0.5s all ease-in-out;
  cursor: pointer;
}
div.subscriptionList div.pricing ul li button:hover {
  background-color: var(--sub-heading-color);
  border: 1px solid var(--sub-heading-color);
  color: var(--white-color);
}
div.subscriptionList div.pricing ul li span.category {
  display: block;
  margin-bottom: 10px;
  color: var(--white-color);
}
div.subscriptionList div.pricing ul li span.price {
  display: block;
  color: var(--white-color);
}
div.subscriptionList div.pricing ul li span.price.discount span.priceCut {
  margin-inline-end: 10px;
  text-decoration: line-through;
  display: block;
}

/* div.subscriptionList div.subscriptionDetails ul.conditions li::before { 
    content: "-";
    margin-right: 4px;
} */
@media all and (max-width: 1200px) {
  div.subscriptionList div.subscriptionDetails h2.heading {
    font-size: 20px;
  }
  div.subscriptionList div.subscriptionDetails ul.conditions li {
    font-size: 15px;
  }
}
@media all and (max-width: 980px) {
  div.subscriptionList div.subscriptionDetails h2.heading {
    font-size: 19px;
  }
}
@media all and (max-width: 768px) {
  div.subscriptionList div.subscriptionDetails h2.heading {
    font-size: 18px;
  }
}
@media all and (max-width: 640px) {
  div.subscriptionList div.pricing ul {
    flex-direction: column;
  }
  div.subscriptionList div.pricing ul li {
    margin-inline-end: 0;
    width: 80%;
    margin: 0 auto 20px;
  }
  div.subscriptionList div.pricing ul li:last-child {
    margin-bottom: 0;
  }
  div.subscriptionList div.pricing ul li button {
    margin: 0 auto 20px;
  }
}
@media all and (max-width: 480px) {
  div.subscriptionList div.pricing ul li {
    width: 100%;
  }
  div.subscriptionList h1.heading {
    font-size: 25px;
  }
}
div.paymentScreen {
  padding: 100px 0;
  background-color: var(--sub-bg-color);
}
div.paymentScreen .paypal-button-container {
  min-width: 326px !important;
  max-width: 284px !important;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper {
    width: 90%;
  }
}
div.paymentScreen div.wrapper h1.heading {
  text-align: center;
  font-size: 30px;
  color: var(--white-color);
  margin-bottom: 30px;
  text-transform: uppercase;
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper h1.heading {
    font-size: 25px;
  }
}
div.paymentScreen div.wrapper div.mainContainer {
  width: 80%;
  margin: 0 auto;
  display: flex;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer {
    flex-direction: column;
    width: 80%;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer {
    width: 100%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left {
  width: 60%;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.left {
    width: 100%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer label {
  font-size: 20px;
  display: block;
  color: var(--white-color);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 35rem;
}
@media all and (max-width: 768px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 30rem;
  }
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer {
    width: 100%;
    flex-direction: column;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
  outline: none;
  font-size: 14px;
  background: #000;
  text-align: start;
  padding: 20px 15px;
  border: 1px solid var(--text-color);
  caret-color: var(--white-color);
  display: block;
  width: 100%;
  color: var(--white-color);
  margin-inline-end: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer input {
    padding: 16px 15px;
    margin-bottom: 20px;
    margin-inline-end: 0;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 0 auto;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
    width: 15rem;
    padding: 16px 20px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer {
    width: 10rem;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer div.background {
  background: var(--button-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer div.inputContainer div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.couponCodeContainer span.success {
  color: var(--success-color);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer {
  margin-top: 30px;
  margin-bottom: 20px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer h2.heading {
  font-size: 20px;
  color: var(--white-color);
  font-weight: 400;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways {
  display: flex;
  flex-wrap: wrap;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
  margin: 10px 10px 0px 0px;
  width: 90px;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li:last-child {
  margin-inline-end: 0;
}
@media all and (max-width: 640px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
    width: 70px;
  }
}
@media all and (max-width: 480px) {
  div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li {
    width: 70px;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer {
  width: 100%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer:hover {
  transform: scale(1.1);
}
div.paymentScreen div.wrapper div.mainContainer div.left div.paymentGatewayContainer ul.gateways li div.imageContainer img {
  width: 100%;
  display: block;
}
div.paymentScreen div.wrapper div.mainContainer div.right {
  width: 40%;
  border-inline-start: 2px solid var(--border-color);
  padding: 80px 40px 0;
}
@media all and (max-width: 980px) {
  div.paymentScreen div.wrapper div.mainContainer div.right {
    width: 90%;
    padding: 40px 0 0;
    border-top: 2px solid var(--border-color);
    border-inline-start: none;
  }
}
@media all and (max-width: 768px) {
  div.paymentScreen div.wrapper div.mainContainer div.right {
    width: 100%;
  }
}
div.paymentScreen div.wrapper div.mainContainer div.right iframe {
  z-index: 2 !important;
}

div.searchPage {
  padding: 100px 5px 40px;
  background-color: var(--secondary-bg-color);
}
div.searchPage div.top {
  border-bottom: 1px solid var(--border-color);
  margin-inline-start: 20px;
}
@media all and (max-width: 768px) {
  div.searchPage div.top {
    margin-inline-start: 10px;
  }
}
div.searchPage div.top h1.heading {
  font-size: 28px;
  margin-bottom: 10px;
  color: var(--heading-color);
}
@media all and (max-width: 640px) {
  div.searchPage div.top h1.heading {
    font-size: 36px;
  }
}
div.searchPage div.top p.desc {
  font-size: 18px;
  color: var(--heading-color);
}
@media all and (max-width: 640px) {
  div.searchPage div.top p.desc {
    font-size: 14px;
  }
}
div.searchPage div.bottom {
  margin-top: 10px;
}
div.searchPage div.bottom div.categories {
  display: flex;
  margin-bottom: 20px;
}
div.searchPage div.bottom div.categories button {
  background-color: transparent;
  outline: none;
  color: var(--text-color);
  padding: 8px 30px;
  border-radius: 30px;
  font-size: 18px;
  margin-inline-end: 10px;
  cursor: pointer;
  border: 1px solid transparent;
}
@media all and (max-width: 1600px) {
  div.searchPage div.bottom div.categories button {
    font-size: 15px;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.categories button {
    font-size: 12px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 15px;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 6px;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 10px;
    font-size: 10px;
  }
}
@media all and (max-width: 360px) {
  div.searchPage div.bottom div.categories button {
    padding: 8px 8px;
  }
}
div.searchPage div.bottom div.categories button:last-child {
  margin-inline-end: 0;
}
div.searchPage div.bottom div.categories button.active {
  border: 1px solid var(--grey-color);
  background-color: var(--button-hover);
  color: var(--white-color);
}
div.searchPage div.bottom div.categories button:hover {
  background-color: var(--button-hover);
  color: var(--white-color);
}
div.searchPage div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount5 {
    width: 18.5%;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount5 div.showCardContainer div.metaData h1.title {
  font-size: 14px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
  width: 15.8%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.searchPage div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15%;
  }
}
div.searchPage div.bottom div.itemsContainer div.items.rowCount6 div.showCardContainer div.metaData h1.title {
  font-size: 14px;
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 23.5%;
    margin-inline-end: 13px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 23%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 31%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.portrait {
    width: 47%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 32%;
    margin-inline-end: 13px;
    margin-bottom: 40px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 47.6%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 640px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 48%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.searchPage div.bottom div.itemsContainer div.items.landscape {
    width: 47%;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.searchPage div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}

div.myList {
  padding: 50px 0 0;
}
div.myList div.top {
  background-color: var(--white-color);
  padding: 20px 0;
}
div.myList div.top h1.heading {
  font-size: 30px;
  color: var(--heading-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.myList div.top h1.heading {
    font-size: 25px;
  }
}
div.myList div.top p.desc {
  font-size: 18px;
  color: var(--text-color);
}
@media all and (max-width: 640px) {
  div.myList div.top p.desc {
    font-size: 14px;
  }
}
div.myList div.bottom {
  background-color: var(--secondary-bg-color);
  padding: 20px 5px 0;
  text-align: center;
}
div.myList div.bottom div.noContentInfo {
  width: 20rem;
  margin: 0 auto;
  padding: 50px 5px 200px;
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.noContentInfo {
    width: 100%;
  }
}
div.myList div.bottom div.noContentInfo h4.heading {
  font-size: 15px;
  color: var(--heading-color);
  margin-bottom: 10px;
}
div.myList div.bottom div.noContentInfo p {
  font-size: 14px;
  color: var(--heading-color);
  line-height: 1.8em;
}
div.myList div.bottom div.noContentInfo p svg {
  width: 12px !important;
  height: 12px !important;
  fill: var(--text-color);
}
div.myList div.bottom div.itemsContainer {
  display: flex;
  flex-wrap: wrap;
  text-align: start;
}
div.myList div.bottom div.itemsContainer div.items.rowCount3 {
  width: 32.5%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount3:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount3 {
    width: 32%;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount4 {
  width: 24%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount4:nth-child(4n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount4 {
    width: 23.8%;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount5 {
  width: 19%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount5:nth-child(5n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount5 {
    width: 18.5%;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount5 div.showCardContainer div.metaData h1.title {
  font-size: 14px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount6 {
  width: 15.8%;
  margin-inline-end: 13px;
  margin-bottom: 30px;
}
div.myList div.bottom div.itemsContainer div.items.rowCount6:nth-child(6n) {
  margin-inline-end: 0;
}
@media all and (max-width: 1400px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15.5%;
  }
}
@media all and (max-width: 1200px) {
  div.myList div.bottom div.itemsContainer div.items.rowCount6 {
    width: 15%;
  }
}
div.myList div.bottom div.itemsContainer div.items.rowCount6 div.showCardContainer div.metaData h1.title {
  font-size: 14px;
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 23.5%;
    margin-inline-end: 13px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 23%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(5n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 31%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(4n) {
    margin-inline-end: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.portrait {
    width: 47%;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.portrait:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 32%;
    margin-inline-end: 13px;
    margin-bottom: 40px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 768px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 47.6%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
}
@media all and (max-width: 640px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 48%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.myList div.bottom div.itemsContainer div.items.landscape {
    width: 47%;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(3n) {
    margin-inline-end: 13px;
  }
  div.myList div.bottom div.itemsContainer div.items.landscape:nth-child(2n) {
    margin-inline-end: 0;
  }
}

div.privacyPolicy {
  padding: 130px 50px;
  background-color: var(--secondary-bg-color);
  direction: ltr !important;
  text-align: left !important;
}
div.privacyPolicy h1.noData {
  text-align: center;
  color: var(--heading-color);
}

div.termsAndConditions {
  padding: 130px 50px;
  background-color: var(--secondary-bg-color);
  direction: ltr !important;
  text-align: left !important;
}
div.termsAndConditions h1.noData {
  text-align: center;
  color: var(--heading-color);
}

div.contactUs {
  padding: 130px 0;
}

div.contactSupport {
  padding: 130px 0;
  background-color: var(--secondary-bg-color);
}
div.contactSupport h1.heading {
  font-size: 28px;
  margin-bottom: 30px;
  color: var(--text-color);
  text-align: center;
}
@media all and (max-width: 640px) {
  div.contactSupport h1.heading {
    font-size: 28px;
  }
}
div.contactSupport div.formContainer {
  width: 50%;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  div.contactSupport div.formContainer {
    width: 100%;
  }
}
div.contactSupport div.formContainer form div.full {
  margin-bottom: 20px;
}
div.contactSupport div.formContainer form div.full input,
div.contactSupport div.formContainer form div.full select {
  color: var(--text-color) !important;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  text-align: start;
  padding: 20px 15px;
  border: 1px solid var(--text-color);
  caret-color: var(--text-color);
  display: block;
  width: 100%;
  color: var(--text-color);
}
div.contactSupport div.formContainer form div.full input option,
div.contactSupport div.formContainer form div.full select option {
  color: #000 !important;
}
div.contactSupport div.formContainer form div.full input:focus,
div.contactSupport div.formContainer form div.full select:focus {
  border: 1px solid var(--white-color);
}
@media all and (max-width: 640px) {
  div.contactSupport div.formContainer form div.full input,
  div.contactSupport div.formContainer form div.full select {
    padding: 16px 15px;
  }
}
div.contactSupport div.formContainer form div.full label {
  color: var(--text-color);
  font-size: 16px;
  display: inline-block;
  margin-bottom: 10px;
}
div.contactSupport div.formContainer form div.full.message {
  display: flex;
  flex-direction: column;
}
div.contactSupport div.formContainer form div.full.message textarea {
  height: 150px;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  background: transparent;
  caret-color: var(--text-color);
  color: var(--text-color);
  padding: 20px 15px;
}
div.contactSupport div.formContainer form div.full div.buttonContainer {
  margin-bottom: 20px;
  text-align: center;
}
div.contactSupport div.formContainer form div.full div.buttonContainer button {
  background-color: var(--button-hover);
  padding: 8px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
}

div.aboutUs {
  padding: 130px 0;
  background-color: var(--secondary-bg-color);
  direction: ltr !important;
  text-align: left !important;
}
div.aboutUs h1.noData {
  text-align: center;
  color: var(--heading-color);
}

/* Demo */
/* Animation */
div.loadingContent {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loadingContent div.loadingOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--white-color);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.loadingContent div.loading-container {
  width: 256px;
  height: 256px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
}
div.loadingContent .loading {
  width: 64px;
  height: 64px;
  position: absolute;
  top: 36%;
  inset-inline-start: 36%;
  transform: rotate(45deg);
}
div.loadingContent .loading div {
  width: 20px;
  height: 20px;
  position: absolute;
}
div.loadingContent .l1 div, div.loadingContent .l2 div, div.loadingContent .l3 div, div.loadingContent .l4 div {
  width: 100%;
  height: 100%;
  background-color: #3498db;
}
div.loadingContent .l1 {
  transform: translate(0, 0);
}
div.loadingContent .l2 {
  transform: translate(0, 32px);
}
div.loadingContent .l3 {
  transform: translate(32px, 0px);
}
div.loadingContent .l4 {
  transform: translate(32px, 32px);
}
@keyframes rot1 {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes rot2 {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}
@keyframes rot3 {
  0% {
    transform: rotate(45deg);
  }
  35% {
    transform: rotate(45deg);
  }
  65% {
    transform: rotate(405deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
@keyframes l1-rise {
  0% {
    transform: translate(0px, 0px);
  }
  30% {
    transform: translate(-8px, -8px);
  }
  70% {
    transform: translate(-8px, -8px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes l2-rise {
  0% {
    transform: translate(0, 32px);
  }
  30% {
    transform: translate(-8px, 40px);
  }
  70% {
    transform: translate(-8px, 40px);
  }
  100% {
    transform: translate(0, 32px);
  }
}
@keyframes l3-rise {
  0% {
    transform: translate(32px, 0px);
  }
  30% {
    transform: translate(40px, -8px);
  }
  70% {
    transform: translate(40px, -8px);
  }
  100% {
    transform: translate(32px, 0px);
  }
}
@keyframes l4-rise {
  0% {
    transform: translate(32px, 32px);
  }
  30% {
    transform: translate(40px, 40px);
  }
  70% {
    transform: translate(40px, 40px);
  }
  100% {
    transform: translate(32px, 32px);
  }
}
@keyframes color {
  0% {
    background-color: #3498db;
  }
  50% {
    background-color: #2980b9;
  }
  100% {
    background-color: #3498db;
  }
}
div.loadingContent .l1 {
  animation: l1-rise 1s ease 0s infinite;
}
div.loadingContent .l2 {
  animation: l2-rise 1s ease 0s infinite;
}
div.loadingContent .l3 {
  animation: l3-rise 1s ease 0s infinite;
}
div.loadingContent .l4 {
  animation: l4-rise 1s ease 0s infinite;
}
div.loadingContent .l1 div, div.loadingContent .l4 div {
  animation: rot1 1s ease 0s infinite, color 1s linear 0s infinite;
}
div.loadingContent .l2 div, div.loadingContent .l3 div {
  animation: rot2 1s ease 0s infinite, color 1s linear 0s infinite;
}

div.newsCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: var(--bg-color);
  transition: 0.4s ease-in-out;
}
div.newsCard:hover {
  background-color: var(--secondary-bg-color);
}
@media all and (max-width: 980px) {
  div.newsCard {
    flex-direction: column;
  }
}
div.newsCard div.left {
  width: 15%;
}
@media all and (max-width: 1200px) {
  div.newsCard div.left {
    width: 50%;
  }
}
@media all and (max-width: 980px) {
  div.newsCard div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.newsCard div.left div.imageContainer {
  width: 100%;
}
div.newsCard div.left div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 8px;
}
div.newsCard div.right {
  width: 80%;
}
@media all and (max-width: 980px) {
  div.newsCard div.right {
    width: 100%;
  }
}
div.newsCard div.right h1.title {
  font-size: 22px;
  margin-bottom: 20px;
  color: var(--white-color);
}
@media all and (max-width: 1200px) {
  div.newsCard div.right h1.title {
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right h1.title {
    font-size: 20px;
  }
}
div.newsCard div.right p {
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 10px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right p {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right p {
    font-size: 13px;
  }
}
div.newsCard div.right div.dateSection {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media all and (max-width: 1200px) {
  div.newsCard div.right div.dateSection {
    margin-bottom: 10px;
  }
}
div.newsCard div.right div.dateSection span.icon {
  width: 20px;
  display: inline-block;
  margin-inline-end: 10px;
}
div.newsCard div.right div.dateSection span.icon img {
  width: 100%;
  display: block;
}
div.newsCard div.right div.dateSection span.date {
  font-size: 14px;
  color: var(--text-hover);
}
@media all and (max-width: 640px) {
  div.newsCard div.right div.dateSection span.date {
    font-size: 13px;
  }
}
div.newsCard div.right div.buttonContainer {
  display: flex;
  padding: 12px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
  margin: 20px 0 0;
}
@media all and (max-width: 768px) {
  div.newsCard div.right div.buttonContainer {
    margin: 20px 0 0;
  }
}
@media all and (max-width: 640px) {
  div.newsCard div.right div.buttonContainer {
    width: 12rem;
  }
}
@media all and (max-width: 480px) {
  div.newsCard div.right div.buttonContainer {
    width: 15rem;
  }
}
div.newsCard div.right div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.newsCard div.right div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.newsCard div.right div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.latestNews {
  padding: 130px 0 80px;
  position: relative;
}
div.latestNews div.head {
  display: flex;
  justify-content: end;
}
@media all and (max-width: 640px) {
  div.latestNews div.head {
    padding-inline-end: 20px;
  }
}
div.latestNews div.head div.logoContainer {
  width: 150px;
}
@media all and (max-width: 640px) {
  div.latestNews div.head div.logoContainer {
    width: 120px;
  }
}
div.latestNews div.head div.logoContainer img {
  display: block;
  width: 100%;
}
div.latestNews div.body h1.heading {
  font-size: 34px;
  color: var(--white-color);
  margin-bottom: 30px;
}
div.latestNews div.body div.gradientBg {
  background: url(./assets/Icons/gradient.svg) repeat-x center bottom;
  background-size: contain;
  position: absolute;
  inset-inline-start: 0;
  bottom: 0;
  width: 100%;
  height: 200px;
}
div.latestNews div.body div.gradientBg div.buttonContainer {
  text-align: center;
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
  border: 1px solid var(--text-hover);
  background-color: transparent;
  color: var(--text-hover);
  padding: 6px 20px;
  font-size: 14px;
  border-radius: 6px;
  width: 15%;
  transition: 0.3s ease-in-out;
  margin: 140px auto 0;
  cursor: pointer;
}
@media all and (max-width: 1200px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 20%;
  }
}
@media all and (max-width: 980px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 25%;
  }
}
@media all and (max-width: 769px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 30%;
  }
}
@media all and (max-width: 769px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 30%;
  }
}
@media all and (max-width: 640px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 35%;
  }
}
@media all and (max-width: 480px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 45%;
  }
}
@media all and (max-width: 360px) {
  div.latestNews div.body div.gradientBg div.buttonContainer button.showMore {
    width: 50%;
  }
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore svg {
  transition: 0.3s ease-in-out;
  margin-inline-start: 6px;
}
div.latestNews div.body div.gradientBg div.buttonContainer button.showMore:hover {
  background-color: var(--text-hover);
  color: var(--white-color);
}

div.articleInfo {
  padding: 130px 0 80px;
}
div.articleInfo div.wrapper {
  width: 80%;
}
div.articleInfo div.wrapper h1.heading {
  font-size: 32px;
  color: var(--white-color);
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper h1.heading {
    padding-inline-start: 20px;
  }
}
div.articleInfo div.wrapper div.dateSection {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
@media all and (max-width: 640px) {
  div.articleInfo div.wrapper div.dateSection {
    padding-inline-start: 20px;
  }
}
div.articleInfo div.wrapper div.dateSection span.icon {
  width: 20px;
  display: block;
  margin-inline-end: 10px;
}
div.articleInfo div.wrapper div.dateSection span.icon img {
  display: block;
  width: 100%;
}
div.articleInfo div.wrapper div.dateSection span.date {
  font-size: 12px;
  color: var(--text-hover);
}
div.articleInfo div.wrapper div.articleDetails {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 20px auto 0;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.articleDetails {
    width: 90%;
  }
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails {
    flex-direction: column;
  }
}
div.articleInfo div.wrapper div.articleDetails div.left {
  width: 100%;
  margin-bottom: 30px;
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.articleInfo div.wrapper div.articleDetails div.left div.imageContainer {
  width: 100%;
  margin: 0 auto;
}
div.articleInfo div.wrapper div.articleDetails div.left div.imageContainer img {
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.articleInfo div.wrapper div.articleDetails div.right {
  width: 100%;
}
@media all and (max-width: 768px) {
  div.articleInfo div.wrapper div.articleDetails div.right {
    width: 100%;
  }
}
div.articleInfo div.wrapper div.articleDetails div.right p {
  font-size: 15px;
  margin-bottom: 20px;
  color: var(--text-color);
}
div.articleInfo div.wrapper div.articleDetails div.right p:last-child {
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.articleDetails div.right p {
    font-size: 13px;
  }
}
div.articleInfo div.wrapper div.videoContainer {
  width: 80%;
  margin: 90px auto 0;
}
div.articleInfo div.wrapper div.videoContainer video {
  display: block;
  width: 100%;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.videoContainer {
    width: 90%;
  }
}
div.articleInfo div.wrapper div.buttonContainer {
  text-align: center;
  margin-top: 50px;
}
div.articleInfo div.wrapper div.buttonContainer button {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  padding: 6px 10px;
  width: 15%;
  border-radius: 10px;
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  transition: 0.4s ease-in-out;
}
@media all and (max-width: 980px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 20%;
    font-size: 14px;
  }
}
@media all and (max-width: 641px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 25%;
  }
}
@media all and (max-width: 480px) {
  div.articleInfo div.wrapper div.buttonContainer button {
    width: 35%;
    padding: 6px 15px;
  }
}
div.articleInfo div.wrapper div.buttonContainer button span {
  color: var(--primary-color);
  margin: 0 10px;
  transition: 0.4s ease-in-out;
}
div.articleInfo div.wrapper div.buttonContainer button svg {
  transition: 0.4s ease-in-out;
}
div.articleInfo div.wrapper div.buttonContainer button:hover {
  border-color: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button:hover span {
  color: var(--icons-color);
}
div.articleInfo div.wrapper div.buttonContainer button:hover svg {
  fill: var(--icons-color);
}

div.landingScreen {
  padding: 100px 0 0;
}
div.landingScreen h1.heading {
  color: var(--white-color);
  margin-bottom: 10px;
  font-size: 45px;
  font-weight: 500;
}
@media all and (max-width: 1400px) {
  div.landingScreen h1.heading {
    font-size: 38px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen h1.heading {
    font-size: 31px;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen h1.heading {
    font-size: 27px;
  }
}
div.landingScreen p.description {
  color: var(--white-color);
  margin-bottom: 10px;
  font-size: 23px;
}
@media all and (max-width: 1400px) {
  div.landingScreen p.description {
    font-size: 19px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen p.description {
    font-size: 17px;
  }
}
div.landingScreen div.buttonContainer {
  display: flex;
  padding: 12px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 15rem;
}
@media all and (max-width: 768px) {
  div.landingScreen div.buttonContainer {
    margin: 20px 0 0;
    width: 13rem;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.buttonContainer {
    width: 12rem;
    margin: 0 auto;
  }
}
@media all and (max-width: 480px) {
  div.landingScreen div.buttonContainer {
    width: 15rem;
  }
}
div.landingScreen div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.landingScreen div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.landingScreen div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  div.landingScreen div.buttonContainer button {
    font-size: 13px;
  }
}
div.landingScreen div.spotlight {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight {
    flex-direction: column-reverse;
    height: 269px;
  }
}
div.landingScreen div.spotlight.rightType {
  position: relative;
}
div.landingScreen div.spotlight.rightType div.left {
  width: 45%;
  position: absolute;
  inset-inline-start: 7%;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to right, #000 80%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight.rightType div.left {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    inset-inline-start: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -44%;
  }
}
div.landingScreen div.spotlight.rightType div.right {
  width: 60%;
  position: absolute;
  inset-inline-end: 0;
  height: 100%;
  z-index: 1;
}
@media all and (max-width: 640px) {
  div.landingScreen div.spotlight.rightType div.right {
    inset-inline-start: unset;
    top: 0;
    width: 100%;
    height: 52%;
  }
}
div.landingScreen div.spotlight.rightType div.right div.imageContainer {
  width: 100%;
  height: 100%;
}
div.landingScreen div.spotlight.rightType div.right div.imageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.landingScreen div.watchNowSection {
  display: flex;
  color: var(--black-color);
  align-items: center;
  background-color: var(--black-color);
  position: relative;
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection {
    flex-direction: column;
    height: 269px;
    margin-top: 99px;
  }
}
div.landingScreen div.watchNowSection div.left {
  width: 60%;
  height: 100%;
  position: absolute;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.left {
    inset-inline-start: unset;
    top: 0;
    width: 100%;
    height: 55%;
  }
}
div.landingScreen div.watchNowSection div.left div.imageContainer {
  height: 100%;
  width: 100%;
}
div.landingScreen div.watchNowSection div.left div.imageContainer img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  display: block;
  width: 100%;
}
div.landingScreen div.watchNowSection div.right {
  width: 50%;
  position: absolute;
  inset-inline-end: 0;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to left, #000 87%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    inset-inline-end: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -47%;
  }
}
div.landingScreen div.watchNowSection div.right h1,
div.landingScreen div.watchNowSection div.right p,
div.landingScreen div.watchNowSection div.right div.buttonContainer {
  margin-inline-start: 80px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right h1,
  div.landingScreen div.watchNowSection div.right p,
  div.landingScreen div.watchNowSection div.right div.buttonContainer {
    margin-inline-start: 0;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.watchNowSection div.right div.buttonContainer {
    margin: 0 auto;
  }
}
div.landingScreen div.watchNowSection div.right p {
  font-size: 20px;
}
@media all and (max-width: 1400px) {
  div.landingScreen div.watchNowSection div.right p {
    font-size: 19px;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.watchNowSection div.right p {
    font-size: 17px;
  }
}
div.landingScreen div.other.rightType {
  display: flex;
  align-items: center;
  background-color: var(--black-color);
  position: relative;
  height: 500px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType {
    flex-direction: column-reverse;
    height: 269px;
  }
}
div.landingScreen div.other.rightType div.left {
  width: 45%;
  position: absolute;
  inset-inline-start: 7%;
  z-index: 3;
  height: 100%;
  padding-top: 120px;
  background: linear-gradient(to right, #000 80%, transparent 100%);
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType div.left {
    background: linear-gradient(to top, #000 96%, transparent 100%);
    inset-inline-start: unset;
    width: 100%;
    text-align: center;
    padding-top: 40px;
    bottom: -91%;
  }
}
div.landingScreen div.other.rightType div.right {
  width: 60%;
  position: absolute;
  inset-inline-end: 0;
  height: 100%;
  z-index: 1;
}
@media all and (max-width: 640px) {
  div.landingScreen div.other.rightType div.right {
    inset-inline-start: unset;
    top: 129px;
    width: 100%;
    height: 52%;
  }
}
div.landingScreen div.other.rightType div.right div.imageContainer {
  width: 100%;
  height: 100%;
}
div.landingScreen div.other.rightType div.right div.imageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.landingScreen div.device {
  display: flex;
  background: var(--white-color);
  padding-top: 30px;
  justify-content: space-between;
  padding-bottom: 100px;
}
@media all and (max-width: 640px) {
  div.landingScreen div.device {
    margin-top: 245px;
    flex-direction: column;
  }
}
div.landingScreen div.device div.left {
  width: 40%;
  padding-inline-start: 60px;
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.left {
    padding-inline-start: 30px;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.left {
    width: 100%;
    margin-bottom: 30px;
  }
}
div.landingScreen div.device div.left h1.heading {
  color: var(--black-color);
}
div.landingScreen div.device div.left p.description {
  color: var(--text-color);
}
div.landingScreen div.device div.right {
  width: 40%;
}
@media all and (max-width: 1980px) {
  div.landingScreen div.device div.right {
    width: 43%;
  }
}
@media all and (max-width: 1600px) {
  div.landingScreen div.device div.right {
    width: 46%;
  }
}
@media all and (max-width: 1400px) {
  div.landingScreen div.device div.right {
    width: 55%;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.device div.right {
    width: 46%;
  }
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.right {
    width: 56%;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.right {
    width: 91%;
    margin: 0 auto;
  }
}
div.landingScreen div.device div.right ul.devices {
  display: flex;
  flex-wrap: wrap;
}
div.landingScreen div.device div.right ul.devices li {
  cursor: pointer;
  width: 17rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline-end: 6px;
  margin-bottom: 6px;
  background: rgb(54, 124, 160);
  background: linear-gradient(90deg, rgb(54, 124, 160) 0%, rgb(14, 64, 115) 35%, rgb(0, 41, 97) 100%);
  transition: 0.2s ease-in-out;
}
div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
  margin-inline-end: 0;
}
@media all and (max-width: 2200px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 15rem;
  }
}
@media all and (max-width: 1980px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 13rem;
    height: 9rem;
  }
}
@media all and (max-width: 1200px) {
  div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
    margin-inline-end: 6px;
  }
  div.landingScreen div.device div.right ul.devices li:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 12rem;
    height: 7rem;
  }
}
@media all and (max-width: 768px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 640px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 13rem;
  }
}
@media all and (max-width: 480px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 10rem;
  }
}
@media all and (max-width: 360px) {
  div.landingScreen div.device div.right ul.devices li {
    width: 100%;
    margin-inline-end: 0;
  }
  div.landingScreen div.device div.right ul.devices li:nth-child(3n) {
    margin-inline-end: 0;
  }
}
div.landingScreen div.device div.right ul.devices li:hover {
  box-shadow: 0px 1px 6px 4px #333;
}
div.landingScreen div.device div.right ul.devices li span {
  color: var(--white-color);
  font-weight: 700;
  font-size: 30px;
}
@media all and (max-width: 980px) {
  div.landingScreen div.device div.right ul.devices li span {
    font-size: 24px;
  }
}

div.landingScreen.rtl div.spotlight.rightType div.left {
  background: none;
  background: linear-gradient(to left, #000 80%, transparent 100%);
}
div.landingScreen.rtl div.other.rightType div.left {
  background: none;
  background: linear-gradient(to left, #000 80%, transparent 100%);
}
div.landingScreen.rtl div.watchNowSection div.right {
  background: none;
  background: linear-gradient(to right, #000 87%, transparent 100%);
}

div.notFound {
  padding: 60px 0;
}
@media all and (max-width: 768px) {
  div.notFound {
    padding: 80px 0;
  }
}
@media all and (max-width: 480px) {
  div.notFound {
    padding: 100px 0;
  }
}
div.notFound div.imageContainer {
  width: 40%;
  margin: 0 auto;
}
@media all and (max-width: 768px) {
  div.notFound div.imageContainer {
    width: 50%;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.imageContainer {
    width: 60%;
  }
}
div.notFound div.imageContainer img {
  width: 100%;
  display: block;
}
div.notFound div.content {
  text-align: center;
}
div.notFound div.content h1 {
  font-size: 80px;
  color: var(--text-hover);
  line-height: 0.8em;
}
@media all and (max-width: 768px) {
  div.notFound div.content h1 {
    font-size: 70px;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content h1 {
    font-size: 60px;
  }
}
div.notFound div.content p {
  font-size: 25px;
  color: var(--text-color);
}
@media all and (max-width: 768px) {
  div.notFound div.content p {
    font-size: 20px;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content p {
    font-size: 16px;
  }
}
@media all and (max-width: 360px) {
  div.notFound div.content p {
    font-size: 14px;
  }
}
div.notFound div.content div.buttonContainer {
  display: flex;
  padding: 18px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
  width: 20rem;
  margin: 30px auto 0;
}
@media all and (max-width: 640px) {
  div.notFound div.content div.buttonContainer {
    width: 15rem;
  }
}
@media all and (max-width: 480px) {
  div.notFound div.content div.buttonContainer {
    width: 10rem;
  }
}
div.notFound div.content div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.notFound div.content div.buttonContainer div.background {
  background: var(--text-hover);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
  z-index: 1;
}
div.notFound div.content div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

div.playerShowCard div.imageContainer {
  width: 100%;
  position: relative;
}
@media all and (max-width: 768px) {
  div.playerShowCard div.imageContainer {
    height: 300px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer {
    height: 100%;
  }
}
div.playerShowCard div.imageContainer img {
  width: 100%;
  display: block;
}
@media all and (max-width: 768px) {
  div.playerShowCard div.imageContainer img {
    height: 300px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer img {
    height: 100%;
    -o-object-fit: contain;
       object-fit: contain;
  }
}
div.playerShowCard div.imageContainer div.playIconContainer {
  position: absolute;
  transform: translate(-50%, -50%);
  border: 1px solid var(--white-color);
  z-index: 99999;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 50%;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
div.playerShowCard div.imageContainer div.playIconContainer svg {
  color: var(--white-color);
  width: 30px !important;
  height: 30px !important;
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.playIconContainer {
    padding: 9px;
  }
  div.playerShowCard div.imageContainer div.playIconContainer svg {
    width: 20px !important;
    height: 20px !important;
  }
}
div.playerShowCard div.imageContainer div.playIconContainer:hover {
  border: 1px solid var(--button-hover);
  background-color: var(--button-hover);
}
div.playerShowCard div.imageContainer div.metaData {
  position: absolute;
  bottom: 30px;
  z-index: 999;
  width: 20rem;
  inset-inline-start: 120px;
}
@media all and (max-width: 1200px) {
  div.playerShowCard div.imageContainer div.metaData {
    inset-inline-start: 42px;
  }
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData {
    inset-inline-start: 22px;
  }
}
div.playerShowCard div.imageContainer div.metaData span.categoryName {
  font-weight: 700;
  font-size: 14px;
  color: var(--white-color);
  text-transform: uppercase;
  display: block;
}
div.playerShowCard div.imageContainer div.metaData h1.showName {
  font-size: 45px;
  font-weight: 700;
  color: var(--white-color);
  text-transform: uppercase;
}
@media all and (max-width: 980px) {
  div.playerShowCard div.imageContainer div.metaData h1.showName {
    font-size: 35px;
  }
}
@media all and (max-width: 640px) {
  div.playerShowCard div.imageContainer div.metaData h1.showName {
    font-size: 25px;
  }
}
div.playerShowCard div.imageContainer div.metaData span.director {
  font-weight: 700;
  font-size: 13px;
  color: var(--white-color);
  text-transform: uppercase;
  margin-inline-end: 3px;
}
div.playerShowCard div.imageContainer div.metaData span.country {
  font-weight: 400;
  font-size: 13px;
  color: var(--white-color);
  text-transform: uppercase;
  margin-inline-end: 3px;
}
div.playerShowCard div.imageContainer div.metaData span.year {
  font-weight: 400;
  font-size: 13px;
  color: var(--white-color);
  text-transform: uppercase;
}
div.playerShowCard div.imageContainer div.metaData p.description {
  color: var(--white-color);
  font-size: 13px;
}
div.playerShowCard div.imageContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}

div.categoriesShowCard {
  position: relative;
  height: 650px;
  margin-top: 30px;
}
div.categoriesShowCard div.backgroundImageContainer {
  width: 100%;
  display: block;
  height: 100%;
}
div.categoriesShowCard div.backgroundImageContainer img.bgImage {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.categoriesShowCard div.backgroundImageContainer div.metaInfo {
  position: absolute;
  top: 30px;
  inset-inline-start: 130px;
  z-index: 9;
}
@media all and (max-width: 1200px) {
  div.categoriesShowCard div.backgroundImageContainer div.metaInfo {
    inset-inline-start: 77px;
  }
}
@media all and (max-width: 768px) {
  div.categoriesShowCard div.backgroundImageContainer div.metaInfo {
    inset-inline-start: 57px;
  }
}
@media all and (max-width: 640px) {
  div.categoriesShowCard div.backgroundImageContainer div.metaInfo {
    inset-inline-start: 30px;
  }
}
div.categoriesShowCard div.backgroundImageContainer div.metaInfo h1 {
  color: var(--white-color);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 40px;
  cursor: pointer;
}
div.categoriesShowCard div.backgroundImageContainer div.metaInfo h1::first-line {
  color: rgb(255, 234, 0);
}
div.categoriesShowCard div.backgroundImageContainer div.metaInfo p.description {
  font-size: 14px;
  color: var(--white-color);
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows {
  position: absolute;
  bottom: 30px;
  width: 90%;
  inset-inline-end: 0;
  z-index: 9;
}
@media all and (max-width: 640px) {
  div.categoriesShowCard div.backgroundImageContainer div.AllShows {
    width: 84% !important;
    inset-inline-end: 38px;
  }
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows div.categoryNameContainer {
  display: flex;
  justify-content: flex-end;
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows div.categoryNameContainer h1.seeAll {
  font-size: 12px;
  color: var(--white-color);
  cursor: pointer;
  margin-inline-end: 10px;
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows div.shows {
  width: 100%;
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows .swiper-button-prev {
  background: transparent !important;
  left: -130px !important;
}
div.categoriesShowCard div.backgroundImageContainer div.AllShows .swiper-button-prev.swiper-button-disabled {
  display: none;
}
@media all and (max-width: 1200px) {
  div.categoriesShowCard div.backgroundImageContainer div.AllShows {
    width: 98%;
  }
  div.categoriesShowCard div.backgroundImageContainer div.AllShows .swiper-button-next, div.categoriesShowCard div.backgroundImageContainer div.AllShows .swiper-button-prev {
    display: none;
  }
}
div.categoriesShowCard div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background: linear-gradient(78.83deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 61.46%), linear-gradient(360deg, rgb(16, 20, 31) 0%, rgba(16, 20, 31, 0) 48.44%, rgba(16, 20, 31, 0.4) 100%);
}

div.categoriesShowCard.ltr div.backgroundImageContainer div.AllShows {
  direction: ltr;
  text-align: left;
  inset-inline-end: unset;
  inset-inline-start: 0;
}
@media all and (max-width: 640px) {
  div.categoriesShowCard.ltr div.backgroundImageContainer div.AllShows {
    inset-inline-end: unset;
    inset-inline-start: 38px;
  }
}
div.categoriesShowCard.ltr div.backgroundImageContainer div.AllShows div.categoryNameContainer {
  direction: rtl;
  text-align: right;
}
div.categoriesShowCard.ltr div.backgroundImageContainer div.AllShows .swiper-button-next {
  background: none;
}
div.categoriesShowCard.ltr div.backgroundImageContainer div.AllShows .swiper-button-prev {
  width: 4%;
  background: rgba(0, 0, 0, 0.5) !important;
  left: 0 !important;
  right: -130px !important;
}
div.categoriesShowCard.ltr div.backgroundImageContainer div.AllShows .swiper-button-prev::after {
  left: unset;
}

div.trailerModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.trailerModal div.head {
  position: fixed;
  z-index: 9999;
  color: var(--white-color);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 20px;
}
div.trailerModal div.head div.left h4.heading {
  font-size: 13px;
}
div.trailerModal div.head div.left h1.title {
  font-size: 22px;
  text-transform: uppercase;
}
div.trailerModal div.head div.left span.director {
  font-weight: 700;
  font-size: 14px;
  margin-inline-end: 5px;
}
div.trailerModal div.head div.left span.year {
  font-weight: normal;
  font-size: 13px;
}
div.trailerModal div.head div.right div.closeIcon {
  cursor: pointer;
}
div.trailerModal div.head div.right div.closeIcon svg {
  stroke: var(--white-color) !important;
}
div.trailerModal div.trailerPlayer {
  z-index: 9999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  width: 50%;
  margin: 0 auto;
}
@media all and (max-width: 1400px) {
  div.trailerModal div.trailerPlayer {
    top: 50%;
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  div.trailerModal div.trailerPlayer {
    top: 50%;
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.trailerModal div.trailerPlayer {
    top: 45%;
    width: 70%;
  }
}
@media all and (max-width: 480px) {
  div.trailerModal div.trailerPlayer {
    width: 95%;
  }
}
div.trailerModal div.trailerPlayer video {
  width: 100%;
  display: block;
}

div.smallLoading div.loading-container {
  position: absolute;
  z-index: 99999999;
}
div.smallLoading .loading {
  position: absolute;
  transform: rotate(45deg);
}
div.smallLoading .loading div {
  width: 5px;
  height: 5px;
  position: absolute;
}
div.smallLoading .l1 div, div.smallLoading .l2 div, div.smallLoading .l3 div, div.smallLoading .l4 div {
  width: 100%;
  height: 100%;
  background-color: #3498db;
}
div.smallLoading .l1 {
  transform: translate(0, 0);
}
div.smallLoading .l2 {
  transform: translate(0, 5px);
}
div.smallLoading .l3 {
  transform: translate(5px, 0px);
}
div.smallLoading .l4 {
  transform: translate(5px, 5px);
}
@keyframes smallrot1 {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes smallrot2 {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}
@keyframes smalllrot3 {
  0% {
    transform: rotate(45deg);
  }
  35% {
    transform: rotate(45deg);
  }
  65% {
    transform: rotate(405deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
@keyframes smalll1-rise {
  0% {
    transform: translate(0px, 0px);
  }
  30% {
    transform: translate(-2px, -2px);
  }
  70% {
    transform: translate(-2px, -2px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}
@keyframes smalll2-rise {
  0% {
    transform: translate(0, 5px);
  }
  30% {
    transform: translate(-2px, 10px);
  }
  70% {
    transform: translate(-2px, 10px);
  }
  100% {
    transform: translate(0, 5px);
  }
}
@keyframes smalll3-rise {
  0% {
    transform: translate(5px, 0px);
  }
  30% {
    transform: translate(10px, -2px);
  }
  70% {
    transform: translate(10px, -2px);
  }
  100% {
    transform: translate(5px, 0px);
  }
}
@keyframes smalll4-rise {
  0% {
    transform: translate(5px, 5px);
  }
  30% {
    transform: translate(10px, 10px);
  }
  70% {
    transform: translate(10px, 10px);
  }
  100% {
    transform: translate(5px, 5px);
  }
}
@keyframes color {
  0% {
    background-color: #3498db;
  }
  50% {
    background-color: #2980b9;
  }
  100% {
    background-color: #3498db;
  }
}
div.smallLoading .l1 {
  animation: smalll1-rise 1s ease 0s infinite;
}
div.smallLoading .l2 {
  animation: smalll2-rise 1s ease 0s infinite;
}
div.smallLoading .l3 {
  animation: smalll3-rise 1s ease 0s infinite;
}
div.smallLoading .l4 {
  animation: smalll4-rise 1s ease 0s infinite;
}
div.smallLoading .l1 div, div.smallLoading .l4 div {
  animation: smallrot1 1s ease 0s infinite, color 1s linear 0s infinite;
}
div.smallLoading .l2 div, div.smallLoading .l3 div {
  animation: smallrot2 1s ease 0s infinite, color 1s linear 0s infinite;
}

div.videoPlayerContainer {
  position: relative;
  padding: 100px 0 0;
  background-color: var(--secondary-bg-color);
}
div.videoPlayerContainer div.arrowContainer {
  width: 5%;
  cursor: pointer;
}
div.videoPlayerContainer div.arrowContainer h3 {
  width: 100%;
  background: transparent;
  cursor: pointer;
}
div.videoPlayerContainer div.arrowContainer h3 svg {
  width: 30px;
  height: 100%;
  fill: rgb(10, 0, 0) !important;
}
div.videoPlayerContainer div.arrowContainer:hover {
  cursor: pointer;
  transform: scale(1.2);
}
div.videoPlayerContainer div.playerContainer {
  width: 90%;
  margin: 0 auto;
  background-color: var(--black-color);
  position: relative;
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer {
  position: relative;
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  text-align: center;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate {
    width: 100%;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1 {
  text-align: center;
  color: var(--white-color);
  font-weight: 400;
  margin-bottom: 10px;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1 {
    font-size: 25px;
  }
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1 {
    font-size: 20px;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate h1 {
    font-size: 14px;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
  width: 12rem;
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button:hover {
  background-color: transparent;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button {
    width: 8rem;
    padding: 8px 20px;
    font-size: 13px;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer div.donate div.buttonContainer button {
    width: 6rem;
    padding: 5px 20px;
    font-size: 12px;
  }
}
div.videoPlayerContainer div.playerContainer div.donationContainer div.bannerContainer img {
  display: block;
  width: 100%;
}
div.videoPlayerContainer div.multiAudio {
  padding: 10px 0;
  width: 90%;
  margin: 0 auto;
}
div.videoPlayerContainer div.multiAudio div.selectContainer {
  width: 40%;
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.multiAudio div.selectContainer {
    width: 100%;
  }
}
div.videoPlayerContainer video {
  height: 100% !important;
  display: block !important;
  width: 100% !important;
}
div.videoPlayerContainer div.upNextContainer {
  width: 90%;
  margin: 20px auto;
}
div.videoPlayerContainer div.upNextContainer h1.heading {
  text-align: start;
  color: var(--text-color);
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.upNextContainer h1.heading {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.upNextContainer h1.heading {
    font-size: 24px;
  }
}
div.videoPlayerContainer div.upNextContainer div.upNext {
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-top: 20px;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.upNextContainer div.upNext {
    width: 100%;
  }
}
div.videoPlayerContainer div.upNextContainer div.upNext div.item {
  width: 32.5%;
  margin-inline-end: 10px;
}
div.videoPlayerContainer div.upNextContainer div.upNext div.item:last-child {
  margin-inline-end: 0;
  margin-bottom: 0;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.item {
    width: 32%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.item {
    width: 48.5%;
    margin-bottom: 20px;
  }
  div.videoPlayerContainer div.upNextContainer div.upNext div.item:nth-child(2n) {
    margin-inline-end: 0;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.upNextContainer div.upNext div.item {
    width: 100%;
    margin-inline-end: 0;
  }
  div.videoPlayerContainer div.upNextContainer div.upNext div.item:nth-child(2n) {
    margin-inline-end: 0;
  }
}
div.videoPlayerContainer div.upNextContainer div.itemPortrait {
  width: 15%;
  margin-inline-end: 10px;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.upNextContainer div.itemPortrait:last-child {
  margin-inline-end: 0;
  margin-bottom: 0;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.upNextContainer div.itemPortrait {
    width: 20%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.upNextContainer div.itemPortrait {
    width: 23%;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.upNextContainer div.itemPortrait {
    width: 30%;
  }
}
@media all and (max-width: 480px) {
  div.videoPlayerContainer div.upNextContainer div.itemPortrait {
    width: 46%;
  }
}
@media all and (max-width: 360px) {
  div.videoPlayerContainer div.upNextContainer div.itemPortrait {
    width: 45%;
  }
}
div.videoPlayerContainer div.videoMetaData {
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
}
div.videoPlayerContainer div.videoMetaData div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.videoMetaData div.head {
    flex-direction: column;
    align-items: flex-start;
  }
}
div.videoPlayerContainer div.videoMetaData div.head h1.videoTitle {
  color: var(--text-color);
  font-size: 30px;
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer {
  display: flex;
}
@media all and (max-width: 980px) {
  div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer {
    justify-content: flex-start;
    margin-top: 10px;
  }
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer button {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer button:hover {
  background-color: transparent;
}
div.videoPlayerContainer div.videoMetaData div.head div.buttonContainer button.myList {
  margin-inline-end: 20px;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating div.categories span.category {
  font-size: 16px;
  color: var(--inner-text-color);
  cursor: pointer;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating div.categories span.category::after {
  content: "•";
  margin: 0 5px 0 10px;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating div.categories span.category:last-child::after {
  display: none;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating div.categories span.category:hover {
  text-decoration: underline;
}
div.videoPlayerContainer div.videoMetaData div.categoriesAndRating span.rating {
  margin-inline-start: 10px;
  padding: 2px 6px;
  background-color: var(--grey-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
}
div.videoPlayerContainer div.videoMetaData p.description {
  color: var(--inner-text-color);
  margin-bottom: 20px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem {
  margin-bottom: 20px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem h6.subHeading {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 10px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items {
  width: 30%;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items.oneRow {
  display: flex;
  width: 60%;
  flex-wrap: wrap;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items.oneRow span.item {
  margin-inline-end: 10px;
  margin-bottom: 0;
  display: block;
  color: var(--inner-text-color);
  cursor: pointer;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items.oneRow span.item:last-child span.comma {
  display: none;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items span.itemInfo {
  cursor: pointer;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items div.item {
  display: flex;
  justify-content: space-between;
  color: var(--inner-text-color);
  margin-bottom: 6px;
}
div.videoPlayerContainer div.videoMetaData div.extraInfoContainer div.metaItem div.items div.item:last-child {
  margin-bottom: 0;
}
div.videoPlayerContainer span.line {
  height: 1px;
  background-color: var(--border-color);
  width: 100%;
  display: block;
  margin-top: 70px;
}
div.videoPlayerContainer div.files {
  width: 90%;
  margin: 0 auto;
}
div.videoPlayerContainer div.commentsContainer {
  width: 70%;
  margin: 0 auto;
  padding: 60px 0;
}
@media all and (max-width: 1200px) {
  div.videoPlayerContainer div.commentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.videoPlayerContainer div.commentsContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer {
    width: 100%;
  }
}
div.videoPlayerContainer div.commentsContainer div.top div.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
}
div.videoPlayerContainer div.commentsContainer div.top div.head span.commentsCount {
  color: var(--text-color);
  font-size: 22px;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer div.top div.head span.commentsCount {
    font-size: 16px;
  }
}
div.videoPlayerContainer div.commentsContainer div.top div.head div.sortContainer select {
  background-color: transparent;
  border: 1px solid var(--border-color);
  padding: 10px 15px;
  color: var(--text-color);
  font-size: 16px;
  border-radius: 6px;
}
div.videoPlayerContainer div.commentsContainer div.top div.head div.sortContainer select option {
  color: var(--black-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer {
  display: flex;
  padding: 40px 0 20px;
  border-bottom: 1px solid var(--border-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.imageContainer {
  margin-inline-end: 20px;
  width: 45px;
  height: 45px;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.imageContainer img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.imageContainer span.nameAvatar {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer {
  width: 90%;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.actions {
  display: flex;
  justify-content: space-between;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.actions p {
  font-size: 14px;
  color: var(--text-color);
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.actions p span {
  color: var(--grey-color);
  cursor: pointer;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.actions p span:hover {
  text-decoration: underline;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.actions div.buttonContainer button.submit {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.videoPlayerContainer div.commentsContainer div.top div.textContainer div.inputContainer div.actions div.buttonContainer button.submit:hover {
  background-color: transparent;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers {
  padding: 40px 0 25px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-inline-end: 20px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name {
  font-size: 16px;
  color: var(--text-color);
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--inner-text-color);
  margin-bottom: 20px;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-inline-end: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-inline-end: 0;
}
div.videoPlayerContainer div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-inline-end: 5px;
}

div.tvAppIndex {
  padding: 130px 0 60px;
}
div.tvAppIndex div.tvActivationReverse div.topHeader {
  padding: 20px 10px;
  border-bottom: 1px solid var(--border-color);
}
div.tvAppIndex div.tvActivationReverse div.topHeader h1 {
  font-size: 22px;
  color: var(--text-color);
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer {
  padding: 50px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer h2 {
  color: var(--text-color);
  font-size: 18px;
  margin-bottom: 20px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer p {
  font-size: 16px;
  color: var(--text-color);
  margin-bottom: 15px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form label {
  color: var(--text-color);
  font-size: 15px;
  display: block;
  margin-bottom: 10px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form input {
  display: block;
  outline: none;
  border: 1px solid;
  padding: 15px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 30%;
}
@media all and (max-width: 640px) {
  div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form input {
    width: 100%;
  }
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form button {
  width: 8rem;
  padding: 6px 1px 9px;
  border: transparent;
  border-radius: 2px;
  background-color: var(--default-bg-color);
  color: var(--white-color);
  margin-inline-end: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 13px;
  margin-bottom: 10px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer form button:hover {
  background-color: var(--white-color);
  border: 1px solid var(--bg-color);
  color: var(--bg-color);
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer p.terms {
  font-size: 15px;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer p.terms span {
  color: rgb(63, 131, 242);
  cursor: pointer;
}
div.tvAppIndex div.tvActivationReverse div.bottomContainer div.inputContainer p.terms span a {
  color: var(--text-color);
}
div.tvAppIndex div.linkTvApp {
  padding: 130px 0;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer {
  width: 35%;
  margin: 0 auto;
  background-color: #22293b;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
  border-radius: 10px;
  padding: 50px 60px;
}
@media all and (max-width: 1200px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 50%;
  }
}
@media all and (max-width: 768px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 60%;
  }
}
@media all and (max-width: 640px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 75%;
  }
}
@media all and (max-width: 480px) {
  div.tvAppIndex div.linkTvApp div.authCodeContainer {
    width: 90%;
    padding: 40px 40px;
  }
}
div.tvAppIndex div.linkTvApp div.authCodeContainer h1.heading {
  color: var(--text-color);
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 8px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer p {
  color: var(--white-color);
  font-size: 14px;
  margin-bottom: 30px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer span.loading {
  display: block;
  color: var(--success-color);
  margin-bottom: 20px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.authCodeField {
  border: 1px solid var(--success-color);
  padding: 10px;
  font-size: 24px;
  color: var(--success-color);
  letter-spacing: 10px;
  font-weight: 700;
  margin-bottom: 15px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.authCodeField.expired {
  border: 1px solid var(--error-color);
  color: var(--error-color);
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired span {
  color: #fff;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired div.regenerateButton {
  margin-top: 10px;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer div.codeExpired div.regenerateButton button {
  background-image: linear-gradient(0deg, #2175d9, #2789ff);
  width: 12rem;
  border-radius: 4px;
  color: #fff;
  padding: 10px;
  font-size: 13px;
  line-height: 1.2;
  letter-spacing: 0.29px;
  font-weight: 500;
  cursor: pointer;
}
div.tvAppIndex div.linkTvApp div.authCodeContainer span.timer {
  color: #fff;
  font-size: 16px;
}

div.podcastModal .rhap_container {
  background-color: #303030 !important;
  border-radius: 5px;
}
div.podcastModal .rhap_time {
  color: #a29b9b !important;
}
div.podcastModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.podcastModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 999;
  cursor: pointer;
}
div.podcastModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.podcastModal div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1400px) {
  div.podcastModal div.contents {
    width: 30%;
  }
}
@media all and (max-width: 1200px) {
  div.podcastModal div.contents {
    width: 60%;
  }
}
@media all and (max-width: 768px) {
  div.podcastModal div.contents {
    width: 75%;
  }
}
@media all and (max-width: 480px) {
  div.podcastModal div.contents {
    width: 90%;
  }
}
div.podcastModal div.contents div.podcastContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
div.podcastModal div.contents div.podcastContainer div.left {
  width: 100%;
}
@media all and (max-width: 980px) {
  div.podcastModal div.contents div.podcastContainer div.left {
    width: 100%;
    margin-inline-end: 0;
  }
}
div.podcastModal div.contents div.podcastContainer div.left div.imageContainer {
  width: 100%;
}
div.podcastModal div.contents div.podcastContainer div.left div.imageContainer img {
  width: 100%;
  display: block;
  border-radius: 5px;
}
div.podcastModal div.contents div.podcastContainer div.right {
  width: 100%;
}
@media all and (max-width: 980px) {
  div.podcastModal div.contents div.podcastContainer div.right {
    width: 100%;
  }
}
div.podcastModal div.contents div.podcastContainer div.right h1.title {
  color: var(--text-color);
  font-size: 20px;
  margin-bottom: 10px;
}
@media all and (max-width: 640px) {
  div.podcastModal div.contents div.podcastContainer div.right div.audioContainer {
    width: 100%;
  }
}
div.podcastModal div.contents div.podcastContainer div.right div.audioContainer audio {
  width: 100%;
  display: block;
}

div.ratingsAndReview div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.ratingsAndReview div.container {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--modal-bg-color);
  width: 40%;
  margin: 0 auto;
  display: flex;
  padding: 20px 0;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.ratingsAndReview div.container {
    padding: 30px;
    width: 50%;
  }
}
@media all and (max-width: 980px) {
  div.ratingsAndReview div.container {
    width: 60%;
  }
}
@media all and (max-width: 768px) {
  div.ratingsAndReview div.container {
    width: 70%;
  }
}
@media all and (max-width: 640px) {
  div.ratingsAndReview div.container {
    width: 90%;
    padding: 20px 10px;
  }
}
div.ratingsAndReview div.container div.closeIconContainer {
  position: absolute;
  inset-inline-end: 20px;
  top: 20px;
  cursor: pointer;
}
div.ratingsAndReview div.container h1.heading {
  font-size: 25px;
  margin-bottom: 6px;
}
div.ratingsAndReview div.container p {
  font-size: 12px;
  margin-bottom: 20px;
}
div.ratingsAndReview div.container p span {
  font-weight: 600;
  margin-inline-end: 6px;
}
div.ratingsAndReview div.container div.ratings {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;
}
div.ratingsAndReview div.container div.ratings div.star {
  margin-inline-end: 10px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
div.ratingsAndReview div.container div.ratings div.star.noMargin {
  margin-inline-end: 0;
}
div.ratingsAndReview div.container div.ratings div.star:hover {
  transform: scale(1.3);
}
div.ratingsAndReview div.container div.ratings div.star.active svg {
  fill: #fbe725;
}
div.ratingsAndReview div.container div.ratings div.star svg {
  width: 30px !important;
  height: 30px !important;
  fill: var(--grey-color);
}
div.ratingsAndReview div.container div.ratings div.ratingValidation {
  padding: 3px 15px;
  background-color: #fbe725;
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
}
div.ratingsAndReview div.container div.ratings div.ratingValidation p {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}
div.ratingsAndReview div.container div.review {
  border-top: 1px solid var(--border-color);
  padding: 30px 20px;
}
div.ratingsAndReview div.container div.review textarea {
  width: 100%;
  border: none;
  outline: none;
  min-height: 100px;
  resize: none;
}
div.ratingsAndReview div.container div.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
@media all and (max-width: 640px) {
  div.ratingsAndReview div.container div.bottom {
    padding: 0 10px;
  }
}
div.ratingsAndReview div.container div.bottom div.left span.remainingWords {
  font-size: 12px;
  color: var(--grey-color);
}
div.ratingsAndReview div.container div.bottom div.right div.buttonContainer button {
  background-color: var(--button-hover);
  padding: 10px 12px;
  color: var(--white-color);
  font-size: 13px;
  cursor: pointer;
  width: 8rem;
}
div.ratingsAndReview div.container div.bottom div.right div.buttonContainer button:disabled,
div.ratingsAndReview div.container div.bottom div.right div.buttonContainer button[disabled] {
  background-color: var(--disabled-btn-color);
  cursor: not-allowed;
}

div.howItWorks div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.howItWorks div.container {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--modal-bg-color);
  width: 60%;
  margin: 0 auto;
  display: flex;
  padding: 20px;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.howItWorks div.container {
    padding: 30px;
    width: 80%;
  }
}
@media all and (max-width: 980px) {
  div.howItWorks div.container {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.howItWorks div.container {
    width: 80%;
  }
}
@media all and (max-width: 640px) {
  div.howItWorks div.container {
    width: 90%;
    padding: 20px 10px;
  }
}
div.howItWorks div.container div.closeIconContainer {
  position: absolute;
  inset-inline-end: 5px;
  top: 0;
  cursor: pointer;
}
div.howItWorks div.container div.closeIconContainer svg {
  width: 12px !important;
  height: 12px !important;
}

div.scheduleDetailsModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(32, 32, 38, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.scheduleDetailsModal div.contents {
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: var(--bg-color);
  width: 50%;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  transform: translate(-50%, -50%);
}
div.scheduleDetailsModal div.contents div.top {
  position: relative;
  width: 100%;
  height: 269px;
  background-size: cover;
  background-position: top;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-repeat: no-repeat;
}
div.scheduleDetailsModal div.contents div.top div.closeIcon {
  position: absolute;
  top: 10px;
  inset-inline-end: 10px;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  cursor: pointer;
}
div.scheduleDetailsModal div.contents div.top div.closeIcon svg {
  color: var(--white-color);
  width: 20px !important;
  height: 20px !important;
}
div.scheduleDetailsModal div.contents div.bottom {
  background-color: var(--secondary-bg-color);
  padding: 40px 30px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  height: 300px;
  overflow-y: scroll;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar {
  display: block;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar {
  width: 5px;
  border-radius: 30px;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar-thumb {
  background-color: var(--bg-color);
  border-radius: 30px;
}
div.scheduleDetailsModal div.contents div.bottom::-webkit-scrollbar-track {
  background-color: var(--grey-color);
}
div.scheduleDetailsModal div.contents div.bottom div.channelLogo {
  width: 80px;
  height: 80px;
  background-color: rgba(150, 153, 163, 0.16);
  border-radius: 6px;
  margin-bottom: 10px;
}
div.scheduleDetailsModal div.contents div.bottom div.channelLogo div.logoContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.scheduleDetailsModal div.contents div.bottom div.channelLogo div.logoContainer img {
  display: block;
  width: 40px;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData {
  color: var(--text-color);
}
div.scheduleDetailsModal div.contents div.bottom div.metaData h1.title {
  font-size: 30px;
  margin-bottom: 10px;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData span.startTime, div.scheduleDetailsModal div.contents div.bottom div.metaData span.endTime {
  color: var(--inner-text-color);
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px;
  display: inline-block;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData div.additionalInfo span.caption {
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 8px;
  padding: 2px 4px;
  font-weight: 700;
  border-radius: 5px;
  outline: none;
  border: none;
  margin-inline-end: 15px;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData div.additionalInfo span.rating {
  background-color: hsla(226, 7%, 61%, 0.32);
  border-radius: 3px;
  color: var(--white-color);
  font-size: 12px;
  padding: 1px 8px;
}
div.scheduleDetailsModal div.contents div.bottom div.metaData p.description {
  margin-top: 10px;
  font-size: 15px;
}

div.liveChannelsContainer {
  background-color: black;
  position: relative;
}
div.liveChannelsContainer div.topContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  min-height: 580px;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer {
    min-height: 508px;
  }
}
div.liveChannelsContainer div.topContainer div.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 100px 100px 0;
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.wrapper {
    flex-direction: column-reverse;
    padding: 100px 10px 0;
  }
}
div.liveChannelsContainer div.topContainer div.bgImage {
  padding: 200px 0;
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  max-height: 800px;
  position: absolute;
  z-index: 3;
  bottom: 0;
  filter: brightness(0.1);
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.bgImage {
    padding: 200px 0 150px;
  }
}
div.liveChannelsContainer div.topContainer div.gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 4;
  height: 500px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 94.15%);
}
div.liveChannelsContainer div.topContainer div.bannerContainer {
  position: relative;
  margin-bottom: 60px;
  width: 55%;
  margin-inline-end: 30px;
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.bannerContainer {
    width: 100%;
  }
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info {
  color: var(--text-color);
  position: relative;
  z-index: 9;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel {
  background-color: #f52d2d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  width: 3.5rem;
  border-radius: 3px;
  margin-bottom: 10px;
  color: white;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel span {
  font-weight: 600;
  font-size: 11px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.liveLabel svg {
  width: 16px !important;
  height: 16px !important;
  margin-inline-end: 6px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.channelLogo {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.channelLogo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info h1.title {
  font-size: 30px;
  margin-bottom: 15px;
  color: white;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo h6.timeLeft {
  color: var(--white-color);
  font-size: 15px;
  margin-inline-end: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo span.caption {
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 10px;
  padding: 2px 4px;
  font-weight: 700;
  border-radius: 3px;
  outline: none;
  border: none;
  margin-inline-end: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info div.additionalInfo span.rating {
  background-color: hsla(226, 7%, 61%, 0.32);
  border-radius: 4px;
  color: var(--white-color);
  color: var(--text-color);
  font-size: 12px;
  font-weight: 700;
  padding: 1px 8px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description {
  max-width: 100%;
  font-size: 15px;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readMore, div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readLess {
  color: var(--button-color);
  margin-inline-start: 6px;
  cursor: pointer;
}
div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readMore:hover, div.liveChannelsContainer div.topContainer div.bannerContainer div.info p.description span.readLess:hover {
  text-decoration: underline;
}
div.liveChannelsContainer div.topContainer div.videoContainer {
  inset-inline-end: 40px;
  z-index: 9;
  width: 70%;
  top: 90px;
  border-radius: 15px;
}
@media all and (max-width: 1600px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 70%;
  }
}
@media all and (max-width: 1400px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.videoContainer {
    margin-bottom: 20px;
    width: 100%;
  }
}
div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
  padding-top: 0 !important;
  height: 408px;
  border-radius: 15px;
}
@media all and (max-width: 1200px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 242px;
  }
}
@media all and (max-width: 980px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 300px;
  }
}
@media all and (max-width: 480px) {
  div.liveChannelsContainer div.topContainer div.videoContainer div.video-js {
    height: 200px;
  }
}
div.liveChannelsContainer div.topContainer div.videoContainer video {
  border-radius: 15px;
}
div.liveChannelsContainer div.wrapper {
  overflow: hidden;
}
div.liveChannelsContainer div.wrapper div.categories {
  margin-bottom: 30px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
div.liveChannelsContainer div.wrapper div.categories::-webkit-scrollbar {
  display: none;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons {
  width: 1200px;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button {
  background-color: var(--black-color);
  padding: 5px 10px;
  color: var(--white-color);
  margin-inline-end: 10px;
  font-weight: 600;
  font-size: 13px;
  border-radius: 30px;
  cursor: pointer;
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button.active {
  background-color: var(--white-color);
  color: var(--black-color);
}
div.liveChannelsContainer div.wrapper div.categories div.buttons button:last-child {
  margin-inline-end: 0;
}
div.liveChannelsContainer div.wrapper div.scheduleTime {
  width: 90%;
  margin: 0 0 6px auto;
  display: flex;
  align-items: center;
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleTime div.time {
  border-inline-start: 2px solid var(--border-color);
  padding-inline-start: 10px;
  width: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleTime div.time span {
  color: var(--inner-text-color);
  font-size: 10px;
  font-weight: 700;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer {
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.rightGradient {
  position: absolute;
  top: 0;
  bottom: 0;
  inset-inline-end: 0;
  width: 10rem;
  z-index: 9;
  background: linear-gradient(95deg, rgba(0, 0, 0, 0), #000 101.15%);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  inset-inline-end: 0;
  width: 10rem;
  z-index: 9;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.01), #000 62.15%);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel {
  display: flex;
  align-items: center;
  width: 3000px;
  height: 75px;
  margin-bottom: 4px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime {
  position: absolute;
  top: -26px;
  inset-inline-end: 0;
  background-color: #f52d2d;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 3px 3px;
  width: 5rem;
  border-radius: 3px;
  color: var(--white-color);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime span {
  font-weight: 600;
  font-size: 11px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime div.icon {
  line-height: 0;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel.showTimeline div.right div.scheduledItem.active div.progress div.currentTime div.icon svg {
  width: 16px !important;
  height: 16px !important;
  margin-inline-end: 6px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left {
  width: 5%;
  margin-inline-end: 4px;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard {
  width: 100%;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard div.imageContainer {
  width: 100%;
  height: 100%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.left div.currentPlayingCard div.imageContainer img {
  display: block;
  width: 100%;
  border-radius: 4px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right {
  display: flex;
  align-items: center;
  width: 95%;
  height: 100%;
  position: relative;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem {
  background-color: rgb(25, 25, 25);
  padding: 25px 20px;
  border-radius: 4px;
  margin-inline-end: 4px;
  height: 100%;
  align-items: center;
  position: relative;
  cursor: pointer;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active {
  border: 2px solid var(--text-hover);
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active div.progress {
  display: block;
  position: absolute;
  background: linear-gradient(95deg, rgba(25, 25, 25, 0), #363434 101.15%);
  width: 10%;
  top: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 9;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem.active div.progress div.currentTime {
  display: none;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem:hover div.metaData {
  display: flex;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.progress {
  display: none;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem h1 {
  font-size: 16px;
  color: var(--white-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 9;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData {
  display: none;
  justify-content: space-between;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData p.description {
  position: absolute;
  z-index: 99;
  color: var(--inner-text-color);
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData div.infoIcon {
  text-align: end;
  position: absolute;
  z-index: 9999;
  inset-inline-end: 10px;
  top: 10px;
}
div.liveChannelsContainer div.wrapper div.scheduleContainer div.channel div.right div.scheduledItem div.metaData div.infoIcon svg {
  color: var(--inner-text-color);
  width: 20px !important;
  height: 20px !important;
}

div.liveChannelsContainer.rtl {
  direction: ltr;
  text-align: left;
}
div.liveChannelsContainer.rtl div.topContainer {
  direction: rtl;
  text-align: right;
}

div.languageModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(32, 32, 38, 0.8);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.languageModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 999;
  cursor: pointer;
}
div.languageModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.languageModal div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white-color);
  width: 30%;
  margin: 0 auto;
  padding: 25px 25px 25px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.languageModal div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.languageModal div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.languageModal div.contents {
    width: 90%;
  }
}
div.languageModal div.contents h1 {
  color: var(--button-color);
  font-size: 22px;
  margin-bottom: 10px;
}
div.languageModal div.contents div.inputContainer {
  width: 100%;
  margin-bottom: 20px;
}
div.languageModal div.contents div.inputContainer select {
  width: 100%;
  padding: 12px 10px;
  outline: none;
  border: 1px solid rgb(231, 238, 239);
  color: var(--button-color);
}
div.languageModal div.contents div.inputContainer select option {
  padding: 12px;
}
div.languageModal div.contents div.buttonContainer button {
  width: 100%;
  display: block;
  padding: 10px;
  background-color: var(--button-color);
  color: var(--white-color);
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.languageModal div.contents div.buttonContainer button:hover {
  background-color: transparent;
  color: var(--button-color);
}

div.openAppContainer {
  padding: 15px;
  position: fixed;
  bottom: 10px;
  width: 100%;
  background-color: var(--button-color);
  z-index: 100000000000000000;
  border-radius: 10px;
  width: 98%;
  margin: 0 auto;
  transform: translateX(-50%);
  left: 50%;
}
div.openAppContainer h1 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 12px;
}
div.openAppContainer p {
  font-size: 12px;
  color: #fff;
  margin-bottom: 10px;
}
div.openAppContainer div.buttons {
  display: flex;
  justify-content: space-between;
}
div.openAppContainer div.buttons button.notNow {
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  padding: 6px 12px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 12px;
}
div.openAppContainer div.buttons button.openApp {
  border: 1px solid #fff;
  background: #fff;
  color: #000;
  padding: 6px 12px;
  border-radius: 30px;
  font-weight: 700;
  font-size: 12px;
}

div.affliatePage {
  padding: 30px 0 0;
  background-color: #fff;
}
div.affliatePage div.top {
  padding: 30px 0;
}
div.affliatePage div.top div.logoSection {
  width: 70%;
  margin: 0 auto;
}
@media all and (min-width: 980px) {
  div.affliatePage div.top div.logoSection {
    width: 30%;
  }
}
div.affliatePage div.top div.logoSection h1.image {
  width: 100%;
}
div.affliatePage div.top div.logoSection h1.image img {
  width: 100%;
  display: block;
}
div.affliatePage div.top h1 {
  font-size: 24px;
  text-align: center;
}
div.affliatePage div.bottom {
  background-color: #022230;
  padding: 136px 20px;
  border-top-left-radius: 91%;
  border-top-right-radius: 0;
}
div.affliatePage div.bottom div.buttons {
  margin-inline-start: 40px;
}
div.affliatePage div.bottom div.buttons div.buttonContainer {
  text-align: center;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button {
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 30px;
  width: 10rem;
  color: #fff;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button.useApp {
  background-color: #45c4af;
  margin-bottom: 20px;
  box-shadow: rgba(69, 196, 175, 0.35) 0px 5px 15px;
}
div.affliatePage div.bottom div.buttons div.buttonContainer button.web {
  box-shadow: rgba(0, 0, 0, 0.03) 0px 16px 24px;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
}

div.livePlayer {
  width: 100%;
}
div.livePlayer div.videoContainer {
  width: 100%;
  position: relative;
  padding: 50px 0 0px;
}
div.livePlayer div.videoContainer video {
  display: block;
  width: 100%;
}
div.livePlayer div.videoContainer div.bottom {
  width: 90%;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  height: 200px;
  overflow: hidden;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom {
    position: relative;
    bottom: unset;
    inset-inline-start: unset;
    transform: unset;
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) {
  div.livePlayer div.videoContainer div.bottom:hover div.details {
    transform: translateY(0);
  }
}
div.livePlayer div.videoContainer div.bottom div.details {
  display: flex;
  justify-content: space-between;
  height: 100%;
  transform: translateY(200px);
  transition: 0.3s ease-in-out;
  background-color: rgba(41, 36, 35, 0.6);
  padding: 20px;
  border-radius: 8px;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom div.details {
    flex-direction: column;
    transform: unset;
  }
}
div.livePlayer div.videoContainer div.bottom div.details h1.heading {
  font-size: 28px;
  color: var(--white-color);
  margin-bottom: 10px;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom div.details h1.heading {
    font-size: 22px;
  }
}
div.livePlayer div.videoContainer div.bottom div.details h1.title {
  font-size: 20px;
  color: var(--yellow-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.livePlayer div.videoContainer div.bottom div.details span.time {
  font-size: 14px;
  color: var(--text-color);
  display: inline-block;
  margin-bottom: 10px;
}
div.livePlayer div.videoContainer div.bottom div.details p.description {
  color: var(--white-color);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
div.livePlayer div.videoContainer div.bottom div.details div.left {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom div.details div.left {
    width: 100%;
    margin-bottom: 20px;
  }
}
div.livePlayer div.videoContainer div.bottom div.details div.right {
  width: 48%;
  height: 100%;
}
@media all and (max-width: 768px) {
  div.livePlayer div.videoContainer div.bottom div.details div.right {
    width: 100%;
  }
}

div.emailForm h2.emailSignInCloseButton {
  text-align: end !important;
  width: 100%;
  background: transparent;
}
div.emailForm h2.emailSignInCloseButton .closeButton {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}
div.emailForm div.MuiPaper-root {
  background-color: var(--sub-bg-color);
  padding: 30px;
  width: 33% !important;
}
@media all and (max-width: 1400px) {
  div.emailForm div.MuiPaper-root {
    width: 38% !important;
  }
}
@media all and (max-width: 1200px) {
  div.emailForm div.MuiPaper-root {
    width: 45% !important;
  }
}
@media all and (max-width: 980px) {
  div.emailForm div.MuiPaper-root {
    width: 55% !important;
  }
}
@media all and (max-width: 768px) {
  div.emailForm div.MuiPaper-root {
    width: 65% !important;
  }
}
@media all and (max-width: 640px) {
  div.emailForm div.MuiPaper-root {
    width: 85% !important;
  }
}
@media all and (max-width: 480px) {
  div.emailForm div.MuiPaper-root {
    width: 100% !important;
    padding: 10px 0;
    margin: 20px !important;
  }
}
div.emailForm div.MuiPaper-root h1 {
  width: 110px;
  margin: 0 auto 10px;
}
div.emailForm div.MuiPaper-root h1 img {
  display: block;
  width: 100%;
}
div.emailForm div.MuiPaper-root h2 {
  text-align: center;
  color: var(--white-color);
}
div.emailForm div.MuiPaper-root form label {
  display: block;
  color: var(--white-color);
  font-size: 14px;
  margin-bottom: 6px;
}
div.emailForm div.MuiPaper-root form input {
  display: block;
  padding: 12px 20px;
  outline: none;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 10px;
}
div.emailForm div.MuiPaper-root form div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.emailForm div.MuiPaper-root form div.buttonContainer {
    padding: 10px 1px;
  }
}
div.emailForm div.MuiPaper-root form div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.emailForm div.MuiPaper-root form div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.emailForm div.MuiPaper-root form div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}

div.verifyEmailModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 20px;
  width: 33%;
}
@media all and (max-width: 1400px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 38%;
  }
}
@media all and (max-width: 1200px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 65%;
  }
}
@media all and (max-width: 640px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 85%;
  }
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root {
    width: 95%;
    padding: 0;
    margin: 20px !important;
  }
}
div.verifyEmailModal div.MuiPaper-root div.top {
  text-align: center;
}
div.verifyEmailModal div.MuiPaper-root div.top svg {
  width: 70px !important;
  height: 70px !important;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.top svg {
    width: 50px !important;
    height: 50px !important;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom {
  color: var(--text-color);
  text-align: center;
}
div.verifyEmailModal div.MuiPaper-root div.bottom div.heading h1 {
  font-size: 22px;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom div.heading h1 {
    font-size: 18px;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom p {
  font-size: 15px;
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom p {
    font-size: 14px;
  }
}
div.verifyEmailModal div.MuiPaper-root div.bottom p span {
  color: rgb(100, 180, 241);
}
@media all and (max-width: 480px) {
  div.verifyEmailModal div.MuiPaper-root div.bottom p span {
    font-size: 14px;
  }
}

div.loginRequestModal {
  text-align: center;
}
div.loginRequestModal div.MuiPaper-root {
  background-color: var(--sub-bg-color);
  padding: 30px;
  width: 33%;
}
@media all and (max-width: 1400px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 38%;
  }
}
@media all and (max-width: 1200px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 45%;
  }
}
@media all and (max-width: 980px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 55%;
  }
}
@media all and (max-width: 768px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 65%;
  }
}
@media all and (max-width: 640px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 85%;
  }
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.MuiPaper-root {
    width: 95%;
    padding: 0;
    margin: 20px !important;
  }
}
div.loginRequestModal div.MuiPaper-root span.MuiSkeleton-root {
  background-color: rgba(255, 255, 255, 0.11) !important;
}
div.loginRequestModal div.MuiPaper-root span.MuiSkeleton-root::after {
  background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.24), transparent) !important;
}
div.loginRequestModal h4 {
  font-size: 17px;
  color: var(--white-color);
  margin-bottom: 8px;
}
div.loginRequestModal div.requestDetails {
  background-color: var(--secondary-bg-color);
  padding: 15px 70px 15px 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.requestDetails {
    padding: 15px 13px 15px 10px;
  }
}
div.loginRequestModal div.requestDetails div.oneRow {
  display: flex;
  justify-content: space-between;
}
div.loginRequestModal div.requestDetails div.oneRow span {
  color: var(--text-color);
  margin-bottom: 8px;
  font-size: 15px;
}
div.loginRequestModal div.requestDetails div.oneRow span.label {
  font-style: italic;
}
div.loginRequestModal p {
  color: var(--text-color);
  margin-bottom: 10px;
  font-size: 14px;
}
div.loginRequestModal div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.loginRequestModal div.buttonContainer {
    padding: 10px 1px;
  }
}
div.loginRequestModal div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.loginRequestModal div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.loginRequestModal div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}

div.appLoginModal div.MuiPaper-root {
  background-color: var(--bg-color);
  padding: 20px;
  width: 33% !important;
}
@media all and (max-width: 1400px) {
  div.appLoginModal div.MuiPaper-root {
    width: 38% !important;
  }
}
@media all and (max-width: 1200px) {
  div.appLoginModal div.MuiPaper-root {
    width: 45% !important;
  }
}
@media all and (max-width: 980px) {
  div.appLoginModal div.MuiPaper-root {
    width: 55% !important;
  }
}
@media all and (max-width: 768px) {
  div.appLoginModal div.MuiPaper-root {
    width: 65% !important;
  }
}
@media all and (max-width: 640px) {
  div.appLoginModal div.MuiPaper-root {
    width: 85% !important;
  }
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root {
    width: 95% !important;
    padding: 0;
    margin: 20px !important;
  }
}
div.appLoginModal div.MuiPaper-root p {
  color: var(--text-color);
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root p {
    font-size: 14px;
  }
}
div.appLoginModal div.MuiPaper-root div.buttonContainer {
  display: flex;
  padding: 15px 20px;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  justify-content: center;
  position: relative;
}
@media all and (max-width: 480px) {
  div.appLoginModal div.MuiPaper-root div.buttonContainer {
    padding: 10px 1px;
  }
}
div.appLoginModal div.MuiPaper-root div.buttonContainer:hover div.background {
  transform: scale(1.0476190476, 1.0357142857);
}
div.appLoginModal div.MuiPaper-root div.buttonContainer div.background {
  background: var(--button-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  transition: 0.1s ease-in-out;
}
div.appLoginModal div.MuiPaper-root div.buttonContainer button {
  background: transparent;
  font-size: 15px;
  color: var(--white-color);
  font-weight: 600;
  z-index: 9;
  cursor: pointer;
}
div.appLoginModal div.MuiPaper-root span {
  color: var(--white-color);
  text-align: center;
  font-size: 14px;
  display: block;
}

div.magazineCardContainer {
  width: 100%;
  cursor: pointer;
  position: relative;
}
div.magazineCardContainer.noPointer {
  cursor: default;
}
div.magazineCardContainer div.showMoreOverlay {
  display: none;
}
div.magazineCardContainer.cardHover:hover .square {
  animation: fadeIn 0.2s ease-in-out;
  background-color: var(--overlay);
}
div.magazineCardContainer.cardHover:hover .square div.metaData {
  display: block;
  visibility: visible !important;
  animation: fadeIn 0.1s ease-in-out;
}
div.magazineCardContainer .square {
  width: 100%;
  transition: transform 500ms ease-out, border-radius 200ms ease-out;
}
div.magazineCardContainer .square.hide div.metaData {
  display: none;
}
@media all and (max-width: 980px) {
  div.magazineCardContainer .square.hide div.metaData {
    display: block;
  }
}
div.magazineCardContainer .square div.metaData {
  display: none;
}
div.magazineCardContainer .square div.metaData.hovered {
  display: block;
  visibility: visible !important;
  animation: fadeIn 0.3s ease-in-out;
}
@media all and (max-width: 980px) {
  div.magazineCardContainer .square div.metaData {
    display: block;
  }
}
div.magazineCardContainer .square div.imageContainer {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
div.magazineCardContainer .square div.imageContainer img {
  width: 100%;
  display: block;
  transition: 0.2s ease-in-out;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
div.magazineCardContainer .square div.imageContainer div.extraActions {
  visibility: visible;
}
div.magazineCardContainer .square div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.magazineCardContainer .square div.imageContainer.hover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.magazineCardContainer .square.hover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.magazineCardContainer .square div.metaData {
  background: #181818;
  color: #fff;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 10px;
  box-sizing: border-box;
  visibility: hidden;
  transition: 0.9s ease-in-out;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
@media all and (max-width: 980px) {
  div.magazineCardContainer .square div.metaData {
    visibility: visible;
  }
}
div.magazineCardContainer .square div.metaData h1.title {
  font-size: 11px;
  color: var(--text-color);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.magazineCardContainer .square div.metaData h1.synopsis {
  font-size: 11px;
  color: var(--text-color);
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.magazineCardContainer .square div.metaData div.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
div.magazineCardContainer .square div.metaData div.buttons span.material-symbols-outlined {
  font-weight: 300 !important;
  font-size: 20px !important;
}
div.magazineCardContainer .square div.metaData div.buttons button {
  color: var(--white-color);
  background-color: var(--button-color);
  width: 50%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  font-size: 10px;
}
div.magazineCardContainer .square div.metaData div.buttons button:hover {
  background-color: var(--disabled-btn-color);
}
div.magazineCardContainer .square div.metaData div.buttons div.left {
  display: flex;
  align-items: center;
}

div.LayoutsContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
div.LayoutsContainer div.routesContainer {
  flex: 1 0 auto;
  background-color: var(--white-color);
}
div.LayoutsContainer div.resubscriptionInfo {
  display: flex;
  align-items: center;
  z-index: 999;
  width: 100%;
  height: auto;
  position: absolute;
  top: 50px;
  background: #fff;
}
div.LayoutsContainer div.resubscriptionInfo .resubscribeMessage {
  width: 85%;
  background: #fff;
  color: red;
  padding: 5px;
  text-align: center;
  font-weight: 600;
  margin: 5px;
}
div.LayoutsContainer div.resubscriptionInfo .resubscribeButton {
  width: 15%;
  background-color: #000;
  color: #fff;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  margin: 2px;
  transition: background-color 0.3s ease, filter 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
div.LayoutsContainer div.resubscriptionInfo .resubscribeButton:hover {
  filter: brightness(2);
}
@media all and (max-width: 768px) {
  div.LayoutsContainer div.resubscriptionInfo .resubscribeMessage {
    font-size: 10px;
    width: 100%;
  }
  div.LayoutsContainer div.resubscriptionInfo .resubscribeButton {
    width: 40%;
    font-size: small;
  }
}
@media all and (max-width: 640px) {
  div.LayoutsContainer div.resubscriptionInfo {
    flex-direction: column;
  }
  div.LayoutsContainer div.resubscriptionInfo .resubscribeButton {
    width: 85%;
  }
}

div.eventDetailsScreen {
  background-color: var(--secondary-bg-color);
}
div.eventDetailsScreen div.topBackgroundContainer {
  position: relative;
  background-repeat: no-repeat;
  width: 100%;
  background-size: 100%;
  background-size: cover;
  padding-bottom: 20px;
}
div.eventDetailsScreen div.topBackgroundContainer div.contents {
  display: flex;
  justify-content: space-between;
  padding: 0 50px 20px 50px;
}
div.eventDetailsScreen div.topBackgroundContainer div.bottomGradient {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 35%, rgba(0, 0, 0, 0.6) 70%, rgba(0, 0, 0, 0.8) 100%);
}
div.eventDetailsScreen div.topBackgroundContainer div.left {
  padding-top: 220px;
  width: 23%;
  z-index: 9;
  padding-inline-start: 5px;
}
@media all and (max-width: 2500px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left {
    padding-inline-start: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left {
    padding-inline-start: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left {
    padding-inline-start: 30px;
  }
}
@media all and (max-width: 1200px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left {
    width: 24%;
  }
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left {
    width: 100%;
    padding-top: 280px;
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.left h1.title {
  color: var(--white-color);
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 16px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo {
  color: var(--text-color);
  align-items: center;
  border-bottom: 1px solid var(--text-color);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon {
  margin-inline-end: 5px;
  line-height: 0;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon svg {
  width: 16px !important;
  height: 16px !important;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.icon svg path {
  fill: var(--white-color) !important;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration {
  display: flex;
  align-items: center;
  margin-inline-end: 10px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration span {
  font-size: 13px;
  font-weight: 500;
  color: var(--white-color);
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.duration span {
    font-size: 14px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.categoryname {
  display: flex;
  align-items: center;
  margin-inline-end: 10px;
  margin-top: 10px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.categoryname h2 {
  color: var(--text-color);
  font-size: 14px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.categoryname h2 span {
  font-size: 15px;
  font-weight: 500;
  color: var(--white-color);
  margin-inline-start: 10px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.left div.showMoreInfo div.categoryname h2 span {
    font-size: 14px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--text-color);
  padding-bottom: 10px;
  margin-bottom: 20px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.addRating {
  margin-inline-end: 10px;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.addRating:hover svg {
  fill: var(--grey-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.addRating svg {
  width: 27px !important;
  height: 27px !important;
  fill: var(--white-color);
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks {
  display: flex;
  align-items: center;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.facebookLink, div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.instagramLink {
  margin-inline-end: 10px;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.facebookLink:hover svg, div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.instagramLink:hover svg {
  color: var(--grey-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.facebookLink svg, div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.socialLinks div.instagramLink svg {
  width: 20px !important;
  height: 20px !important;
  color: var(--white-color);
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.userRatings {
  display: flex;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.userRatings div.star.active svg {
  fill: #fbe725;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.userRatings div.star svg {
  width: 23px !important;
  height: 23px !important;
  fill: var(--grey-color);
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.favourite {
  margin-inline-end: 10px;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.favourite:hover svg {
  fill: var(--grey-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.favourite svg {
  width: 23px !important;
  height: 23px !important;
  fill: var(--white-color);
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.watchListButton {
  cursor: pointer;
  margin-inline-end: 10px;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.watchListButton:hover svg {
  fill: var(--grey-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.actions div.watchListButton svg {
  width: 22px !important;
  height: 22px !important;
  transition: 0.3s ease-in-out;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.externalLinks {
  display: flex;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.externalLinks div.imageContainer {
  margin-inline-end: 10px;
  width: 35px;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.externalLinks div.imageContainer:last-child {
  margin-inline-end: 0;
}
div.eventDetailsScreen div.topBackgroundContainer div.left div.externalLinks div.imageContainer img {
  width: 100%;
  display: block;
}
div.eventDetailsScreen div.topBackgroundContainer div.categoryName {
  background-color: #68abff;
  padding: 10px 6px;
  width: 8rem;
  text-transform: uppercase;
  color: var(--white-color);
  font-weight: 500;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-start: auto;
  position: absolute;
  inset-inline-end: 0;
  top: 20px;
  z-index: 9;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.categoryName {
    display: none;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.top {
  position: absolute;
  inset-inline-end: 0;
  text-align: end;
  padding-top: 60px;
  z-index: 9;
}
@media all and (max-width: 2500px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top {
    inset-inline-end: 100px;
  }
}
@media all and (max-width: 1600px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top {
    inset-inline-end: 50px;
  }
}
@media all and (max-width: 1400px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top {
    inset-inline-end: 60px;
  }
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top {
    padding-top: 75px;
    inset-inline-end: 40px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-inline-end: 20px;
  margin-top: 10px;
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings div.icon {
  margin-inline-end: 5px;
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings div.icon svg {
  width: 18px !important;
  height: 18px !important;
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings h1.rating {
  font-size: 22px;
  color: var(--white-color);
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings h1.rating {
    font-size: 23px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings h1.rating span.outOf {
  color: var(--text-color);
  font-size: 14px;
  font-weight: 700;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top div.ratings h1.rating span.outOf {
    font-size: 15px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.trailer {
  padding-inline-end: 5px;
  padding-top: 90px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top div.trailer {
    display: none;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.trailer button.trailerBtn {
  background-color: transparent;
  border: 1px solid var(--white-color);
  width: 5rem;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  padding: 6px;
  color: var(--white-color);
  margin-inline-start: auto;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.trailer button.trailerBtn:hover {
  border-color: var(--button-hover);
  background-color: var(--button-hover);
}
div.eventDetailsScreen div.topBackgroundContainer div.top div.trailer button.trailerBtn div.icon {
  line-height: 0;
  margin-inline-start: 8px;
}
div.eventDetailsScreen div.topBackgroundContainer div.top span.totalRatings {
  color: var(--text-color);
  font-size: 13px;
  font-weight: 700;
  padding-inline-end: 3px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.top span.totalRatings {
    font-size: 14px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.topLeft {
  position: absolute;
  text-align: end;
  margin-top: 100px;
  z-index: 999;
  cursor: pointer;
}
@media all and (max-width: 2500px) {
  div.eventDetailsScreen div.topBackgroundContainer div.topLeft {
    margin-inline-start: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.eventDetailsScreen div.topBackgroundContainer div.topLeft {
    margin-inline-start: 15px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.topLeft svg {
  width: 30px;
  fill: #fff !important;
  stroke: #fff;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.right {
  width: 73%;
  padding-top: 270px;
  z-index: 8;
  display: flex;
}
@media all and (max-width: 2500px) {
  div.eventDetailsScreen div.topBackgroundContainer div.right {
    padding-inline-end: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.eventDetailsScreen div.topBackgroundContainer div.right {
    padding-inline-end: 5px;
  }
}
@media all and (max-width: 1400px) {
  div.eventDetailsScreen div.topBackgroundContainer div.right {
    padding-inline-end: 30px;
  }
}
@media all and (max-width: 1200px) {
  div.eventDetailsScreen div.topBackgroundContainer div.right {
    width: 70%;
  }
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.right {
    display: none;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.right div.description {
  color: var(--white-color);
  width: 50%;
}
div.eventDetailsScreen div.topBackgroundContainer div.right div.description p.data {
  font-size: 13px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.right div.description p.data {
    font-size: 14px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.playIconContainer {
  position: absolute;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid var(--white-color);
  transition: 0.3s ease-in-out;
  z-index: 9;
  left: 50%;
  top: 23%;
  transform: translateX(-50%);
  cursor: pointer;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.playIconContainer {
    top: 30%;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.playIconContainer svg {
  width: 30px !important;
  height: 30px !important;
  color: var(--white-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.playIconContainer:hover {
  background-color: var(--button-hover);
  border-color: var(--button-hover);
}
div.eventDetailsScreen div.topBackgroundContainer div.subscriptions {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  z-index: 9;
  padding-inline-start: 5px;
  padding-bottom: 30px;
}
@media all and (max-width: 2500px) {
  div.eventDetailsScreen div.topBackgroundContainer div.subscriptions {
    padding-inline-start: 80px;
  }
}
@media all and (max-width: 1600px) {
  div.eventDetailsScreen div.topBackgroundContainer div.subscriptions {
    padding-inline-start: 50px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.subscriptions div.items {
  z-index: 9;
  cursor: pointer;
  margin-inline-end: 10px;
  background-color: var(--yellow-color);
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
  color: var(--white-color);
  margin-bottom: 20px;
  border-radius: 30px;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.topBackgroundContainer div.subscriptions div.items {
    font-size: 12px;
  }
}
div.eventDetailsScreen div.topBackgroundContainer div.subscriptions div.items.free {
  background-color: var(--success-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer {
  position: absolute;
  inset-inline-end: 5px;
  z-index: 9;
  bottom: 10px;
  display: flex;
  align-items: center;
  padding-inline-end: 50px;
  cursor: pointer;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer span {
  color: var(--white-color);
  font-size: 14px;
  margin-inline-end: 15px;
  transition: 0.2s ease-in-out;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer span.active {
  transform: translateX(-10px);
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.icon {
  line-height: 0;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.icon svg {
  width: 20px !important;
  height: 20px !important;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.icon .closeIcon {
  stroke: var(--white-color) !important;
  display: block;
  width: 11px !important;
  height: 11px !important;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons {
  line-height: 0;
  animation: fadeIn 0.9s;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .facebook {
  margin-inline-end: 15px;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .facebook div.icon svg {
  color: var(--white-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .twitter {
  margin-inline-end: 15px;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .twitter div.icon svg {
  color: var(--white-color);
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .clipBoard {
  margin-inline-end: 15px;
  display: inline;
}
div.eventDetailsScreen div.topBackgroundContainer div.shareContainer div.socialIcons .clipBoard svg {
  fill: var(--white-color);
  width: 20px !important;
  height: 20px !important;
}
div.eventDetailsScreen div.responsiveContent {
  padding-bottom: 25px;
  display: none;
}
@media all and (max-width: 980px) {
  div.eventDetailsScreen div.responsiveContent {
    display: block;
  }
}
div.eventDetailsScreen div.responsiveContent div.description {
  padding: 25px 15px;
  color: var(--text-color);
}
div.eventDetailsScreen div.responsiveContent div.description p.data {
  font-size: 16px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
div.noscheduleContainer div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.noscheduleContainer div.closeIconContainer {
  position: fixed;
  top: 80px !important;
  inset-inline-end: 30px;
  z-index: 999;
  cursor: pointer;
}
div.noscheduleContainer div.closeIconContainer svg {
  width: 25px !important;
  height: 25px !important;
  stroke: white !important;
}
div.noscheduleContainer div.contents {
  z-index: 999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.noscheduleContainer div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.noscheduleContainer div.contents {
    width: 60%;
  }
}
@media all and (max-width: 480px) {
  div.noscheduleContainer div.contents {
    width: 90%;
  }
}
div.noscheduleContainer div.contents h1 {
  color: var(--inner-text-color);
  font-size: 22px;
}

div.autoplayComponent {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #000;
  height: 100%;
  z-index: 9;
}
div.autoplayComponent div.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
div.autoplayComponent div.content div.progressBar {
  width: 100px;
  height: 100px;
}
div.autoplayComponent div.content span.timer {
  display: inline-block;
  margin-top: 10px;
  font-weight: 600;
  color: var(--white-color);
}

div.socialLoginModal div.overlayModal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.socialLoginModal div.closeIconContainer {
  position: fixed;
  top: 20px;
  inset-inline-end: 30px;
  z-index: 9999999;
}
div.socialLoginModal div.closeIconContainer svg {
  width: 20px !important;
  height: 20px !important;
}
div.socialLoginModal div.contents {
  z-index: 9999999;
  text-align: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  width: 40%;
  margin: 0 auto;
  padding: 60px 60px 60px;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 1200px) {
  div.socialLoginModal div.contents {
    padding: 30px;
  }
}
@media all and (max-width: 768px) {
  div.socialLoginModal div.contents {
    width: 80%;
  }
}
@media all and (max-width: 480px) {
  div.socialLoginModal div.contents {
    width: 90%;
  }
}
div.socialLoginModal div.contents h1.error {
  font-size: 20px;
  color: #E72A31;
  margin-bottom: 10px;
}
div.socialLoginModal div.contents p {
  color: var(--white-color);
  font-size: 16px;
}
div.socialLoginModal div.contents h1 {
  color: var(--text-color);
  font-size: 18px;
}
@media all and (max-width: 768px) {
  div.socialLoginModal div.contents h1 {
    font-size: 16px;
  }
}
div.socialLoginModal div.contents div.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
div.socialLoginModal div.contents div.buttonContainer button {
  background-color: var(--button-color);
  padding: 10px 12px;
  color: var(--text-color);
  font-size: 13px;
  cursor: pointer;
  width: 10rem;
  border-radius: 5px;
}
div.socialLoginModal div.contents div.buttonContainer button:first-child {
  margin-inline-end: 6px;
}

div.jwmAboutUs {
  padding: 130px 0;
  background-color: var(--secondary-bg-color);
}
div.jwmAboutUs div.wrapper div.content-container {
  gap: 20px;
  display: flex;
  width: 60%;
  margin: 0px auto;
  padding: 100px 20px 0px;
}
@media (max-width: 980px) {
  div.jwmAboutUs div.wrapper div.content-container {
    flex-direction: column;
    width: 90%;
    padding: 50px 20px 0px;
    align-items: center;
    text-align: center;
  }
}
div.jwmAboutUs div.wrapper div.content-container div.logo {
  width: 25%;
  flex-shrink: 0;
}
div.jwmAboutUs div.wrapper div.content-container div.logo img {
  width: 100%;
  height: auto;
  display: block;
}
div.jwmAboutUs div.wrapper div.content-container div.about-content {
  font-size: 20px;
  font-weight: medium;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 980px) {
  div.jwmAboutUs div.wrapper div.content-container div.about-content {
    font-size: 16px;
  }
}
div.jwmAboutUs div.wrapper div.content-container div.about-content a {
  font-size: 18px;
  align-self: start;
  margin-bottom: 5px;
  color: #007BFF;
}
div.jwmAboutUs div.wrapper div.content-container div.about-content a:hover {
  color: rgb(80, 105, 132);
}
@media (max-width: 980px) {
  div.jwmAboutUs div.wrapper div.content-container div.about-content a {
    align-self: center;
    font-size: 16px;
  }
}
div.jwmAboutUs div.wrapper div.content-container div.about-content div.bottomContainer {
  align-self: start;
}
div.jwmAboutUs div.wrapper div.content-container div.about-content div.bottomContainer div.stores {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
@media (max-width: 980px) {
  div.jwmAboutUs div.wrapper div.content-container div.about-content div.bottomContainer div.stores {
    justify-content: center;
  }
}
div.jwmAboutUs div.wrapper div.content-container div.about-content div.bottomContainer div.stores img {
  width: 10%;
  border-radius: 20%;
  cursor: pointer;
}
@media (max-width: 480px) {
  div.jwmAboutUs div.wrapper div.content-container div.about-content div.bottomContainer div.stores img {
    width: 20%;
  }
}
div.jwmAboutUs div.wrapper div.content-container div.about-content div.bottomContainer div.stores svg {
  width: 15%;
  cursor: pointer;
}
@media (max-width: 480px) {
  div.jwmAboutUs div.wrapper div.content-container div.about-content div.bottomContainer div.stores svg {
    width: 35%;
  }
}

.notification-banner {
  position: fixed;
  top: 60px;
  inset-inline-end: 5px;
  width: 90%;
  max-width: 500px;
  z-index: 1000;
  animation: slide-down 0.3s ease-out forwards;
  border-inline-start: 5px solid #5bc0de;
}

@keyframes slide-down {
  0% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
.notification-content {
  background-color: white;
  border-radius: 1px;
  padding: 16px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.icon-container {
  flex-shrink: 0;
  margin-inline-end: 16px;
}

.info-icon {
  width: 32px;
  height: 32px;
  fill: currentColor;
  color: #5bc0de;
}

.notification-text {
  flex-grow: 1;
}

.notification-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
  color: var(--black-color);
}

.notification-message {
  font-size: 14px;
  color: var(--text-color);
}

.notification-actions {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: inherit;
  border-inline-start: 1px solid #e2e0e0;
}
@media (max-width: 480px) {
  .notification-actions {
    border-inline-start: none;
  }
}

.action-button {
  cursor: pointer;
  padding: 10px;
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  background-color: white;
}
.action-button.primary {
  font-weight: 700;
}
@media (max-width: 480px) {
  .action-button.primary {
    border-inline-end: 1px solid #e2e0e0;
  }
}
.action-button.secondary {
  border-top: 1px solid #e2e0e0;
}
@media (max-width: 480px) {
  .action-button.secondary {
    border-top: none;
  }
}

@media (max-width: 480px) {
  .notification-content {
    flex-direction: column;
    text-align: center;
  }
  .icon-container {
    margin-inline-end: 0;
    margin-bottom: 12px;
  }
  .notification-actions {
    margin-inline-start: 0;
    margin-top: 12px;
    flex-direction: row;
    justify-content: center;
  }
}
div.shorts {
  height: 100vh;
  overflow: hidden;
  background-color: var(--bg-color);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 38px;
}
@media (max-width: 480px) {
  div.shorts {
    margin-top: 0px;
  }
}
div.shorts__videos {
  height: 87vh;
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 400px;
  aspect-ratio: 9/16;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
div.shorts__videos::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1200px) {
  div.shorts__videos {
    width: 47%;
  }
}
@media (max-width: 1024px) {
  div.shorts__videos {
    width: 50%;
  }
}
@media (max-width: 768px) {
  div.shorts__videos {
    width: 80%;
  }
}
@media (max-width: 480px) {
  div.shorts__videos {
    width: 100%;
    height: 87vh;
  }
}
@media (max-width: 360px) {
  div.shorts__videos {
    width: 100%;
    height: 89vh;
  }
}
div.shorts div.loder-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.shorts div.loder-div .loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
div.shorts div.video-container {
  display: flex;
}
div.shorts div.video-container div.video-shorts {
  position: relative;
  width: 100%;
  height: 87.5vh;
  margin-top: 10px;
  margin-bottom: 10px;
  scroll-snap-align: start;
  overflow: hidden;
  background-color: #000;
  /* Mobile-specific styles */
}
div.shorts div.video-container div.video-shorts__player {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  aspect-ratio: 9/16;
}
div.shorts div.video-container div.video-shorts .video-js {
  pointer-events: auto; /* Ensure touch events are enabled */
}
div.shorts div.video-container div.video-shorts .video-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  pointer-events: auto; /* Ensure touch events are enabled */
  transition: opacity 0.3s ease-in-out;
}
div.shorts div.video-container div.video-shorts .hidden {
  opacity: 0;
  visibility: hidden; /* Ensure the element is not clickable */
  pointer-events: none;
}
div.shorts div.video-container div.video-shorts .show {
  opacity: 1;
  visibility: visible;
}
div.shorts div.video-container div.video-shorts .pause-icon, div.shorts div.video-container div.video-shorts .play-icon {
  width: 60px;
  height: 60px;
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
div.shorts div.video-container div.video-shorts .pause-icon::before, div.shorts div.video-container div.video-shorts .play-icon::before {
  content: "▶"; /* Unicode for play triangle */
  font-size: 30px;
  color: white;
}
div.shorts div.video-container div.video-shorts .pause-icon::before {
  content: "❚❚"; /* Unicode for pause bars */
}
@media (max-width: 768px) {
  div.shorts div.video-container div.video-shorts .pause-icon, div.shorts div.video-container div.video-shorts .play-icon {
    width: 60px;
    height: 60px;
  }
  div.shorts div.video-container div.video-shorts .pause-icon::before, div.shorts div.video-container div.video-shorts .play-icon::before {
    font-size: 25px;
  }
}
@media (max-width: 640px) {
  div.shorts div.video-container div.video-shorts .pause-icon, div.shorts div.video-container div.video-shorts .play-icon {
    width: 40px;
    height: 40px;
  }
  div.shorts div.video-container div.video-shorts .pause-icon::before, div.shorts div.video-container div.video-shorts .play-icon::before {
    font-size: 15px;
  }
}
@media (max-width: 480px) {
  div.shorts div.video-container div.video-shorts .pause-icon, div.shorts div.video-container div.video-shorts .play-icon {
    width: 30px;
    height: 30px;
  }
  div.shorts div.video-container div.video-shorts .pause-icon::before, div.shorts div.video-container div.video-shorts .play-icon::before {
    font-size: 10px;
  }
}
div.shorts div.video-container div.video-shorts .shortsVideoTop {
  position: absolute;
  min-width: -moz-fit-content;
  min-width: fit-content;
  top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
div.shorts div.video-container div.video-shorts .shortsVideoTopIcon {
  display: flex;
  width: 40%;
  justify-content: space-evenly;
}
div.shorts div.video-container div.video-shorts .shortsVideoTopIcon > .MuiSvgIcon-root {
  font-size: 32px;
  padding: 10px;
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcons {
  position: absolute;
  right: 8px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcon > .MuiSvgIcon-root {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  padding: 15px;
  font-size: 43px;
  width: 45px;
  height: 45px;
  cursor: pointer;
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcon > p {
  color: white;
  font-size: 14px;
  margin-top: 5px;
}
div.shorts div.video-container div.video-shorts .shortDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 15px;
  position: relative;
}
div.shorts div.video-container div.video-shorts .shortDetails p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div.shorts div.video-container div.video-shorts .show-more-button {
  color: #00f; /* Adjust color as needed */
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}
div.shorts div.video-container div.video-shorts .shortsBottom {
  display: flex;
  position: absolute;
  flex-direction: column;
  bottom: 10px;
  width: 84%;
  padding: 0 10px;
}
div.shorts div.video-container div.video-shorts .shortsDesc {
  display: flex;
  padding: 10px;
}
div.shorts div.video-container div.video-shorts .shortsDesc > p {
  color: white;
  font-size: 14px;
}
div.shorts div.video-container div.video-shorts .MuiSvgIcon-root {
  cursor: pointer;
  color: white;
}
div.shorts div.video-container div.video-shorts .shortDetails {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 58px;
  position: relative;
}
div.shorts div.video-container div.video-shorts .shortDetails p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
}
div.shorts div.video-container div.video-shorts .show-more-button {
  color: #00f; /* Adjust color as needed */
  cursor: pointer;
  font-size: 14px;
  margin-top: 5px;
}
div.shorts div.video-container div.video-shorts .shortDetails > button {
  padding: 7px;
  background-color: rgba(177, 177, 177, 0.609);
  border: none;
  outline: none;
  color: white;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
}
div.shorts div.video-container div.video-shorts .shortDetails > button:hover {
  background-color: rgb(255, 0, 0);
}
div.shorts div.video-container div.video-shorts .shortsVideoSideIcon > .MuiSvgIcon-root:hover {
  color: blue;
}
div.shorts div.video-container div.video-shorts .shortsDesc > .ticker {
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  max-width: 250px;
}
div.shorts div.video-container div.video-shorts .description {
  color: white;
}
div.shorts div.video-container div.share-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
div.shorts div.video-container div.share-modal-overlay .share-modal-content {
  background: #202020;
  border-radius: 12px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  top: 260px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  color: white;
}
div.shorts div.video-container div.share-modal-overlay .share-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}
div.shorts div.video-container div.share-modal-overlay .share-options {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
div.shorts div.video-container div.share-modal-overlay .share-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  margin: 0 10px;
  transition: opacity 0.3s;
  border: none;
  cursor: pointer;
}
div.shorts div.video-container div.share-modal-overlay .share-button:hover {
  opacity: 0.8;
}
div.shorts div.video-container div.share-modal-overlay .facebook {
  background: #3b5998;
}
div.shorts div.video-container div.share-modal-overlay .twitter {
  background: #1da1f2;
}
div.shorts div.video-container div.share-modal-overlay .share-url-container {
  display: flex;
  margin-top: 15px;
}
div.shorts div.video-container div.share-modal-overlay .share-url-input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #444;
  border-radius: 4px 0 0 4px;
  background: #333;
  color: white;
}
div.shorts div.video-container div.share-modal-overlay .copy-button {
  padding: 10px 15px;
  background: #1da1f2;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  transition: background 0.3s;
}
div.shorts div.video-container div.share-modal-overlay .copy-button:hover {
  background: #0c8de4;
}
div.shorts div.video-container div.comment-modal {
  position: fixed;
  top: 76px;
  width: 28%;
  right: 8%;
  height: 88vh;
  background-color: #212121;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1400px) {
  div.shorts div.video-container div.comment-modal {
    width: 28%;
    right: 9%;
  }
}
@media all and (max-width: 1200px) {
  div.shorts div.video-container div.comment-modal {
    width: 60%;
  }
}
@media all and (max-width: 768px) {
  div.shorts div.video-container div.comment-modal {
    width: 80%;
  }
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal {
    width: 100%;
    right: 0;
    top: 58px;
    height: 88vh;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions {
  display: flex;
  align-items: center;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button {
  position: relative;
  margin-right: 15px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button button {
  background: transparent;
  border: none;
  color: var(--white-color);
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button button .sort-icon {
  margin-left: 5px;
  font-size: 10px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button .sort-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #282828;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button .sort-dropdown div {
  padding: 10px 15px;
  color: var(--white-color);
  cursor: pointer;
  white-space: nowrap;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button .sort-dropdown div:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
@media all and (max-width: 1200px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header {
    width: 100%;
  }
}
@media all and (max-width: 768px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header {
    width: 100%;
  }
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header {
    width: 100%;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .close-modal {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list {
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.loder-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.loder-div .loader {
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers {
  padding: 10px 0 25px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name {
  font-size: 16px;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 1.5;
  max-width: 100%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container {
  margin-top: 10px;
  margin-bottom: 30px;
  width: 30%;
  height: auto;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.comment-image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer {
  width: 70%;
  margin: 0 auto;
  padding: 60px 0;
}
@media all and (max-width: 1200px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer {
    width: 80%;
  }
}
@media all and (max-width: 768px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer {
    width: 90%;
  }
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer {
    width: 100%;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.top div.head {
  display: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.top div.textContainer {
  display: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment {
  border-bottom: 1px solid var(--border-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment:last-child {
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers {
  padding: 40px 0 25px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer {
  padding: 40px 0 0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.replyMessagesContainer div.containers {
  padding: 0 0 0;
  display: flex;
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem {
  display: flex;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem:last-child {
  border: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  width: 45px;
  margin-right: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
  width: 100%;
  height: 45px;
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer {
    height: 41px;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left div.profileImageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.left span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
  width: 90%;
}
@media all and (max-width: 640px) {
  div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right {
    width: 96%;
  }
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right h1.name {
  font-size: 16px;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right span.time {
  font-size: 13px;
  color: var(--inner-text-color);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right p.comment {
  font-size: 16px;
  color: var(--white-color);
  margin-bottom: 20px;
  margin-bottom: 20px;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
  line-height: 1.5;
  max-width: 100%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions {
  display: flex;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 11px;
  color: var(--inner-text-color);
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:hover {
  opacity: 0.6;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item:last-child {
  margin-right: 0;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content div.comment-modal-list div.commentsContainer div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item span svg {
  width: 15px !important;
  height: 15px !important;
  color: rgb(190, 190, 187);
  fill: rgb(190, 190, 187);
  margin-right: 5px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-item {
  display: flex;
  margin-bottom: 16px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e0e0e0;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-body {
  flex: 1;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-author {
  font-weight: 500;
  margin-bottom: 4px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-text {
  margin-bottom: 4px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-actions {
  display: flex;
  align-items: center;
  color: #606060;
  font-size: 14px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-like {
  margin-right: 8px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-replies {
  color: #065fd4;
  cursor: pointer;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer {
  padding: 16px;
  border-top: 1px solid var(--border-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper {
  display: flex;
  align-items: flex-start;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper span.nameAvatar {
  width: 10%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container {
  flex-grow: 1;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid #e1e8ed;
  resize: vertical !important;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
  resize: vertical;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage {
  width: 55%;
  height: 30%;
  margin-bottom: 20px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage img {
  max-width: 55%;
  border-radius: 5px;
  height: auto;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage button.removeButton {
  position: absolute;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--button-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage button.removeButton:hover {
  background-color: #f2f2f2;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.selectedImage button.removeButton svg {
  width: 15px;
  height: 15px;
  fill: var(--button-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer {
  display: flex;
  align-items: center;
  margin-right: 10px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer label.customFileUpload {
  background-color: #1da1f2;
  color: white;
  margin-right: 10px;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer label.customFileUpload:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer input[type=file] {
  display: none;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer button.giphyButton {
  background-color: #1da1f2;
  color: white;
  font-weight: 600;
  padding: 7px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #1da1f2;
  transition: 0.4s ease-in-out;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions .buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions button.submit {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container div.upload-featue div.actions button.submit:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .add-comment {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 14px;
  background-color: var(--input-bg-color);
  color: var(--text-color);
  margin-bottom: 8px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .add-comment:focus {
  outline: none;
  border-color: var(--focus-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions label.customFileUpload {
  width: 50%;
  text-align: center;
  border-radius: 50%;
  transition: background-color 0.2s;
  border: 1px solid #1da1f2;
  padding: 10px;
  margin: 10px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .action-button {
  background: none;
  cursor: pointer;
  color: var(--icon-color);
  padding: 10px;
  border-radius: 50%;
  transition: background-color 0.2s;
  border: 1px solid #1da1f2;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .action-button:hover {
  background-color: var(--hover-bg-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .submit-button {
  background: none;
  color: var(--button-text-color);
  border: none;
  padding: 8px 16px;
  border-radius: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .comment-actions .submit-button:not(:disabled):hover {
  background-color: var(--button-hover-color);
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .image-preview {
  margin-top: 12px;
  position: relative;
  display: inline-block;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .image-preview img {
  max-width: 100%;
  max-height: 200px;
  border-radius: 4px;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .comment-modal-footer .image-preview .remove-preview {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
}
div.shorts div.video-container div.comment-modal div.comment-modal-content .add-comment {
  width: 100%;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
div.shorts div.video-container div.customModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
div.shorts div.video-container div.customModal .modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto;
}
div.shorts div.video-container div.customModal .modalContent .closeButton {
  position: absolute;
  top: -6px;
  right: 2px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.video-js {
  display: block;
  vertical-align: top;
  box-sizing: border-box;
  color: #fff;
  background-color: #000;
  position: relative;
  padding: 0;
  font-size: 10px;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
  word-break: initial;
  height: 100% !important;
  width: 100% !important;
}

div.shorts.rtl .video-container .video-shorts .shortsVideoSideIcons {
  right: unset;
  left: 8px;
}
div.shorts.rtl .video-container div.share-modal-overlay {
  left: unset;
  right: 0;
}
div.shorts.rtl .video-container div.share-modal-overlay .share-modal-close {
  right: unset;
  left: 10px;
}
div.shorts.rtl .video-container div.comment-modal {
  right: unset;
  left: 8%;
}
@media (max-width: 1400px) {
  div.shorts.rtl .video-container div.comment-modal {
    right: unset;
    left: 9%;
  }
}
@media all and (max-width: 640px) {
  div.shorts.rtl .video-container div.comment-modal {
    right: unset;
    left: 0;
  }
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button {
  margin-right: 0;
  margin-left: 15px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-header .header-actions .sort-button button .sort-icon {
  margin-left: 0;
  margin-right: 5px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.left {
  margin-right: 0;
  margin-left: 20px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item {
  margin-right: 0;
  margin-left: 20px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-list div.bottom div.comments div.eachComment div.containers div.commentItem div.right div.actions span.item svg {
  margin-right: 0;
  margin-left: 5px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-like {
  margin-right: 0;
  margin-left: 8px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .user-avatar {
  margin-right: 0;
  margin-left: 12px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .upload-featue div.actions .buttonContainer {
  margin-right: 0;
  margin-left: 10px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-footer .comment-input-wrapper .comment-input-container .upload-featue div.actions .buttonContainer label.customFileUpload {
  margin-right: 0;
  margin-left: 10px;
}
div.shorts.rtl .video-container div.comment-modal div.comment-modal-content .comment-modal-footer .image-preview .remove-preview {
  right: 0;
  left: 4px;
}
div.shorts.rtl .video-container div.customModal {
  left: unset;
  right: 0;
}
div.shorts.rtl .video-container div.customModal .modalContent .closeButton {
  right: 0;
  left: 2px;
}

div.shortsCardContainer {
  width: 100%;
  position: relative;
}
div.shortsCardContainer.showContents {
  cursor: pointer;
}
div.shortsCardContainer.showContents div.premiumStatus div.premium {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  background-color: rgba(8, 25, 43, 0.65);
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
@media all and (max-width: 1200px) {
  div.shortsCardContainer.showContents div.premiumStatus div.premium {
    width: 26px;
  }
}
div.shortsCardContainer.showContents div.premiumStatus div.premium svg {
  width: 18px !important;
  height: 18px !important;
}
@media all and (max-width: 1200px) {
  div.shortsCardContainer.showContents div.premiumStatus div.premium svg {
    width: 12px !important;
    heigth: 12px !important;
  }
}
div.shortsCardContainer.showContents div.premiumStatus div.free {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 50px;
}
@media all and (max-width: 1200px) {
  div.shortsCardContainer.showContents div.premiumStatus div.free {
    width: 30px;
  }
}
div.shortsCardContainer.showContents div.premiumStatus div.free img {
  display: block;
  width: 100%;
}
div.shortsCardContainer.showContents div.premiumStatus div.lock {
  position: absolute;
  top: 6px;
  left: 6px;
  z-index: 8;
  width: 20px;
}
@media all and (max-width: 1200px) {
  div.shortsCardContainer.showContents div.premiumStatus div.lock {
    width: 15px;
  }
}
div.shortsCardContainer.showContents div.premiumStatus div.lock img {
  display: block;
  width: 100%;
}
div.shortsCardContainer.showContents:hover div.imageContainer img {
  transform: scale(1.1);
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions {
  visibility: visible;
  opacity: 1;
  animation: fadeIn 0.2s ease-in-out forwards;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay {
  background-color: var(--overlay);
  z-index: 8;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info {
  position: absolute;
  bottom: 2.2rem;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  background: transparent;
  padding: 10px 20px;
  width: 100%;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share-wrapper {
  position: absolute;
  top: auto;
  right: 0;
  left: 10%;
  bottom: 80%;
  width: 2.375rem;
  height: auto;
  display: none;
  padding: 10px 260x;
  background-color: #191919;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share-wrapper div.share-boxs div.overflow-hidden {
  display: grid;
  justify-content: center;
  margin-top: 20%;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share .share-wrapper {
  display: none; /* Hide until hover */
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
  display: inline-block;
  bottom: 100%;
  left: 8%;
  width: 15%;
}
@media (min-width: 320px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 30%;
  }
}
@media (min-width: 360px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 26%;
    left: 11%;
  }
}
@media (min-width: 375px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 26%;
  }
}
@media (min-width: 425px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 23%;
  }
}
@media (min-width: 480px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 31%;
  }
}
@media (min-width: 640px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 8%;
    width: 23%;
  }
}
@media (min-width: 768px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 25%;
    left: 9%;
  }
}
@media (min-width: 980px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 20%;
    left: 9%;
  }
}
@media (min-width: 1024px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 18%;
  }
}
@media (min-width: 1200px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 19%;
  }
}
@media (min-width: 1400px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 15%;
    left: 8%;
  }
}
@media (min-width: 2560px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    width: 9%;
    left: 6%;
  }
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists {
  display: flex;
  width: 100%;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
  width: 22%;
  height: 40px;
  margin-bottom: 0.4375rem;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid var(--white);
  color: white !important;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}
@media (max-width: 2600px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    height: 45px;
    width: 15%;
  }
}
@media (max-width: 1980px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 20%;
  }
}
@media (max-width: 1600px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 25%;
  }
}
@media (max-width: 1400px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 28%;
  }
}
@media (max-width: 1200px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    height: 40px;
  }
}
@media (max-width: 980px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 30%;
    height: 35px;
  }
}
@media (max-width: 768px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    height: 30px;
  }
}
@media (max-width: 480px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li {
    width: 45%;
  }
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li span.shareIcon {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li span.shareIcon svg {
  fill: white !important;
  width: 70%;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li:hover {
  background-color: var(--button-color);
  border: none;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li .share > span {
  width: 100%;
  height: 100%;
  display: inline-block;
  font-size: 1rem;
  line-height: 1.625rem;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li i {
  color: var(--bs-white);
  font-size: 0.945em;
  line-height: 1.625rem;
  cursor: pointer;
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .music-play-lists li i:hover {
  color: var(--button-color);
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  background-color: var(--button-color);
  width: 22%;
  height: 40px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}
@media (max-width: 2600px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    height: 45px;
    width: 15%;
  }
}
@media (max-width: 1980px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 20%;
  }
}
@media (max-width: 1600px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 25%;
  }
}
@media (max-width: 1400px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 28%;
  }
}
@media (max-width: 1200px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    height: 40px;
  }
}
@media (max-width: 980px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 30%;
    height: 35px;
  }
}
@media (max-width: 768px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    height: 30px;
  }
}
@media (max-width: 480px) {
  div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button {
    width: 45%;
  }
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button:focus {
  background-color: transparent;
  width: 20%;
  border-radius: 50%;
  border: 2px solid var(--white);
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info:focus {
  background-color: transparent;
  width: 20%;
  border-radius: 50%;
  border: 2px solid var(--white);
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button:hover {
  color: gray;
  border-radius: 50%;
  background-color: var(--button-hover);
}
div.shortsCardContainer.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info div.iq-button:hover:focus {
  background-color: gray;
}
div.shortsCardContainer.showContents:hover div.metaData h1.title {
  color: var(--text-hover);
}
div.shortsCardContainer div.imageContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
}
div.shortsCardContainer div.imageContainer img {
  width: 100%;
  height: 25rem;
  display: block;
  border-radius: 3px;
  transition: transform 0.2s ease-in-out;
}
@media (max-width: 980px) {
  div.shortsCardContainer div.imageContainer img {
    height: 20rem;
  }
}
@media (max-width: 768px) {
  div.shortsCardContainer div.imageContainer img {
    height: 15rem;
  }
}
@media (max-width: 480px) {
  div.shortsCardContainer div.imageContainer img {
    height: 12rem;
  }
}
div.shortsCardContainer div.imageContainer img.landscape {
  min-height: 130px;
  background: black;
}
div.shortsCardContainer div.imageContainer img.portrait {
  min-height: 420px;
  background: black;
}
div.shortsCardContainer div.imageContainer div.extraActions {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
div.shortsCardContainer div.imageContainer div.extraActions div.showCardOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--overlay);
  z-index: 8;
}
div.shortsCardContainer div.metaData {
  margin-top: 10px;
  color: var(--inner-text-color);
}
div.shortsCardContainer div.metaData h1.title {
  color: var(--text-color);
  font-size: 16px;
  transition: color 0.3s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media (max-width: 320px) {
  div.shortsCardContainer div.metaData h1.title {
    font-size: 12px;
  }
}
@media (max-width: 640px) {
  div.shortsCardContainer div.metaData h1.title {
    font-size: 12px;
  }
}
div.shortsCardContainer div.metaData div.datas {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 980px) {
  div.shortsCardContainer div.metaData div.datas {
    display: none;
  }
}
div.shortsCardContainer div.metaData div.datas div.left span {
  font-size: 12px;
  margin-right: 6px;
}
div.shortsCardContainer div.metaData div.datas div.left span.duration.ltr {
  direction: ltr !important;
  unicode-bidi: embed !important;
}
div.shortsCardContainer div.metaData div.datas div.left span.duration.showDot::before {
  content: "·";
  margin-right: 6px;
}
div.shortsCardContainer div.metaData div.datas div.left span:last-child {
  margin-right: 0;
}

div.shortsCardContainer.rtl.showContents div.premiumStatus div.premium {
  left: 0;
  right: 6px;
}
div.shortsCardContainer.rtl.showContents div.premiumStatus div.free {
  left: 0;
  right: 6px;
}
div.shortsCardContainer.rtl.showContents div.premiumStatus div.lock {
  left: 0;
  right: 6px;
}
div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share-wrapper {
  left: 0;
  right: 10%;
}
div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
  left: 0;
  right: 8%;
}
@media (min-width: 360px) {
  div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 0;
    right: 11%;
  }
}
@media (min-width: 640px) {
  div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 0;
    right: 8%;
  }
}
@media (min-width: 768px) {
  div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 0;
    right: 9%;
  }
}
@media (min-width: 980px) {
  div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 0;
    right: 9%;
  }
}
@media (min-width: 1400px) {
  div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 0;
    right: 8%;
  }
}
@media (min-width: 2560px) {
  div.shortsCardContainer.rtl.showContents:hover div.imageContainer div.extraActions div.showCardOverlay div.block-social-info .share:hover .share-wrapper {
    left: 0;
    right: 6%;
  }
}
div.shortsCardContainer.rtl .metaData .datas div.left span {
  margin-right: 0;
  margin-left: 6px;
}
div.shortsCardContainer.rtl .metaData .datas div.left span.duration.showDot::before {
  margin-right: 0;
  margin-left: 6px;
}
div.shortsCardContainer.rtl .metaData .datas div.left span:last-child {
  margin-left: 0;
}

div.replyField {
  display: flex;
  width: 100%;
  padding-top: 30px;
  animation: fadeIn 0.4s ease-in-out;
}
div.replyField.child {
  width: 92%;
  margin: 0 0 0 auto;
}
div.replyField div.left {
  margin-inline-end: 20px;
}
div.replyField div.left div.imageContainer {
  width: 45px;
  height: 45px;
}
div.replyField div.left div.imageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.replyField div.left div.imageContainer span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.replyField div.right {
  width: 100%;
}
div.replyField div.right div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
div.replyField div.right div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.replyField div.right div.inputContainer div.actions {
  display: flex;
  justify-content: flex-end;
}
div.replyField div.right div.inputContainer div.actions p {
  font-size: 14px;
  color: var(--text-color);
}
div.replyField div.right div.inputContainer div.actions p span {
  color: var(--grey-color);
  cursor: pointer;
}
div.replyField div.right div.inputContainer div.actions p span:hover {
  text-decoration: underline;
}
div.replyField div.right div.inputContainer div.actions div.buttonContainer button {
  background-color: var(--button-color);
  color: var(--white-color);
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid var(--button-color);
  transition: 0.4s ease-in-out;
}
div.replyField div.right div.inputContainer div.actions div.buttonContainer button:hover {
  background-color: transparent;
}
div.replyField div.right div.inputContainer div.actions div.buttonContainer button.cancel {
  background-color: var(--grey-color);
  border: 1px solid var(--grey-color);
  margin-inline-end: 20px;
}
div.replyField div.right div.inputContainer div.actions div.buttonContainer button.cancel:hover {
  background-color: transparent;
}

div.replyFieldShorts {
  display: flex;
  width: 100%;
  padding-top: 30px;
  animation: fadeIn 0.4s ease-in-out;
}
div.replyFieldShorts.child {
  width: 92%;
  margin: 0 0 0 auto;
}
div.replyFieldShorts div.left {
  margin-right: 20px;
}
div.replyFieldShorts div.left div.imageContainer {
  width: 45px;
  height: 45px;
}
div.replyFieldShorts div.left div.imageContainer img {
  width: 100%;
  display: block;
  height: 100%;
  border-radius: 50%;
}
div.replyFieldShorts div.left div.imageContainer span.nameAvatar {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-color);
  background-color: green;
  border-radius: 50%;
}
div.replyFieldShorts div.right {
  width: 100%;
}
div.replyFieldShorts div.right div.inputContainer textarea {
  display: block;
  width: 100%;
  height: 45px;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 15px !important;
  border: 1px solid #e1e8ed;
  resize: vertical;
}
div.replyFieldShorts div.right div.inputContainer textarea:focus-visible {
  border: 3px solid var(--button-color) !important;
  outline: none;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply {
  width: 55%;
  height: 30%;
  position: relative;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply img {
  max-width: 100%;
  border-radius: 5px;
  height: auto;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton {
  position: absolute;
  background: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 20px;
  color: var(--button-color);
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton:hover {
  background-color: #f2f2f2;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.selectedImageReply button.removeButton svg {
  width: 15px;
  height: 15px;
  fill: var(--button-color);
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer {
  display: flex;
  align-items: center;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer label.customFileUploadReply {
  background: none;
  padding: 5px;
  border: 1px solid #1da1f2;
  color: white;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer label.customFileUploadReply:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.cancel {
  background: none;
  border: none !important;
  margin-right: 10px;
  color: var(--white-color);
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.cancel:hover {
  background-color: transparent;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer input[type=file] {
  display: none;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.giphyButton {
  background: none;
  color: white;
  border: 1px solid #1da1f2;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  padding: 5px;
  margin-right: 10px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions div.buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions button.submit {
  background: none;
  font-weight: 600;
  border: none !important;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.upload-featue div.actions button.submit:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyFieldShorts div.right div.inputContainer div.actions {
  display: flex;
  justify-content: flex-end;
}
div.replyFieldShorts div.right div.inputContainer div.actions p {
  font-size: 14px;
  color: var(--text-color);
}
div.replyFieldShorts div.right div.inputContainer div.actions p span {
  color: var(--grey-color);
  cursor: pointer;
}
div.replyFieldShorts div.right div.inputContainer div.actions p span:hover {
  text-decoration: underline;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer {
  display: flex;
  align-items: center;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer label.customFileUploadReply {
  background: none;
  border: 1px solid #1da1f2;
  color: white;
  margin-right: 10px;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer label.customFileUploadReply:hover {
  background-color: transparent;
  color: var(--white-color);
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer button.cancel {
  background: none;
  border: none !important;
  margin-right: 10px;
  color: var(--white-color);
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer button.cancel:hover {
  background-color: transparent;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer input[type=file] {
  display: none;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer button.giphyButton {
  background: none;
  color: white;
  border: 1px solid #1da1f2;
  font-weight: 600;
  border-radius: 6px;
  font-size: 14px;
  margin-right: 10px !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.4s ease-in-out;
}
div.replyFieldShorts div.right div.inputContainer div.actions div.buttonContainer button.giphyButton:hover {
  background-color: transparent;
  color: var(--white-color);
}

div.replyFieldShorts.rtl div.left {
  margin-right: 0;
  margin-left: 20px;
}
div.replyFieldShorts.rtl .right .inputContainer .upload-featue div.actions div.buttonContainer label.customFileUploadReply {
  margin-right: 0;
  margin-left: 10px;
}
div.replyFieldShorts.rtl .right .inputContainer .upload-featue div.actions div.buttonContainer button.cancel {
  margin-right: 0;
  margin-left: 10px;
}
div.replyFieldShorts.rtl .right .inputContainer .upload-featue div.actions div.buttonContainer button.giphyButton {
  margin-right: 0;
  margin-left: 10px !important;
}

:root {
  --grey-color:#9699a3;
  --text-hover:#000;
  --bg-color:#ffffff;
  --secondary-bg-color:#eaeaea;
  --modal-bg-color: #141414;
  --overlay:rgba(16,20,31,.64);
  --white-color:#fff;
  --border-color:#eaeaea;
  --text-color:rgb(102, 102, 102);
  --success-color:#4BB543;
  --error-color:#E72A31;
  --yellow-color:#f8d406;
  --black-color:#000;
  --button-hover:rgb(0, 20, 137);
  --button-color:rgb(0, 20, 137);
  --heading-color:#323232;
  --modal-bg-color:#ffffff;
  --sub-bg-color:#26262d;
  --sub-heading-color:rgb(253, 201, 59);
  --default-bg-color:#10141f;
  --default-border-color:rgba(150,153,163,.16);
  --default-text-hover-color:rgb(193, 191, 191);
  --disabled-btn-color:rgb(112, 125, 201);
  --gradient-left-color:#01c599;
  --gradient-right-color:#0388cc;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
}
@media all and (max-width: 980px) {
  .wrapper {
    width: 100%;
  }
}

.swiper-button-next.swiper-button-disabled {
  display: none !important;
}

.swiper-button-prev.swiper-button-disabled {
  opacity: 1 !important;
}

.swiper-button-next {
  top: 22px !important;
}
.swiper-button-next::after {
  font-size: 25px !important;
  color: var(--white-color);
}

.swiper-button-prev {
  top: 22px !important;
}
.swiper-button-prev::after {
  font-size: 25px !important;
  color: var(--white-color);
  position: absolute;
  left: 118px;
}

span.error {
  font-size: 14px;
  color: #E72A31;
  display: block;
  margin-top: 10px;
  font-weight: normal !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}/*# sourceMappingURL=app.css.map */