div.privacyPolicy{
    padding: 130px 50px;
    background-color: var(--secondary-bg-color);
    direction: ltr !important;
    text-align: left !important;
    h1.noData{
        text-align: center;
        color: var(--heading-color);
    }
}
